import { FormattedMessage, useIntl } from 'react-intl';
import Spinner from 'src/components/_common/Spinner/Spinner';
import UsersContext from 'src/hooks/useUsers';
import { useHistory } from 'react-router-dom';
import ErrorMessage from 'src/components/_common/ErrorMessage/ErrorMessage';
import { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Label, Input } from '@windmill/react-ui';
import Select from 'src/components/_common/Select/Select';
import RolesContext from 'src/hooks/useRoles';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import List from 'src/components/_common/List/List';
import ListItem from 'src/components/_common/List/ListItem';
import { User } from 'src/models/user';

const Users = () => {
  const { formatMessage } = useIntl();
  const { users, usersLoaded, usersError, getUsers, loadingMoreUsers } = UsersContext.useContainer();
  const { roles, rolesLoaded } = RolesContext.useContainer();
  const [selectedRole, setSelectedRole] = useState<any | undefined>();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const history = useHistory();

  const pageSize = 20;

  const searchForUser = (add?: boolean) => getUsers(selectedRole?.value, search, pageSize, users?.length, add, true);

  const navigateToUser = (user: User) => history.push(`/admin/users/${user.id}`);

  const fetchMoreData = () => {
    if (!loadingMoreUsers) searchForUser(true);
  };

  useEffect(() => {
    getUsers(undefined, undefined, undefined, undefined, undefined, true);
  }, []);

  useEffect(() => {
    if (search !== undefined) searchForUser(false);
  }, [search]);

  useEffect(() => {
    if (selectedRole) searchForUser(false);
  }, [selectedRole]);

  if (!usersLoaded || users === undefined) return <Spinner />;

  return (
    <>
      <PageHeader title={formatMessage({ id: 'users' })} subTitle={formatMessage({ id: 'change-user-roles' })} />
      <div className="flex w-full ">
        <Label className="flex flex-col">
          <Select
            label={formatMessage({ id: 'roles' })}
            options={roles?.map(role => ({ label: role.name.toString(), value: role.id.toString() }))}
            onChange={setSelectedRole}
            value={selectedRole}
            isClearable
            isLoading={!rolesLoaded}
            className="w-full"
          />
        </Label>
        <Label className="flex w-auto flex-wrap pl-8">
          <span><FormattedMessage id="search" /></span>
          <Input
            data-testid="users-table-search"
            css=""
            value={search}
            className="w-full"
            onChange={e => setSearch(e.target.value)}
          />
        </Label>
      </div>
      <InfiniteScroll
        dataLength={users.length}
        next={fetchMoreData}
        hasMore={users?.length >= pageSize && users?.length % pageSize === 0}
        endMessage={(
          <p style={{ textAlign: 'center' }}>
            <b><FormattedMessage id="no-more-users" /></b>
          </p>
        )}
        loader={<Spinner />}
        style={{ overflow: 'visible' }}
      >
        <List title="" className="mt-4">
          {users.map(user => (
            <ListItem key={user.id} onClick={() => navigateToUser(user)}>
              {user.isActive ? user.displayName : (
                <span className="text-gray-400">{user.displayName} ({formatMessage({ id: 'deactivated' })})</span>
              )}
            </ListItem>
          ))}
        </List>
      </InfiniteScroll>
      {usersError && <ErrorMessage {...usersError} />}
    </>
  );
};

export default Users;
