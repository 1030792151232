import { ReactNode } from 'react';
import { Badge } from '@windmill/react-ui';
import useUser from 'src/hooks/user/useUser';
import classnames from 'classnames';

export enum CommentType {
  Supervisor,
  Teacher,
  Student
}

interface CommentProps{
  type: CommentType,
  userId: number,
  commentDate: string,
  children: ReactNode | ReactNode[],
}

export default function Comment({ type, userId, commentDate, children }: CommentProps) {
  const userQuery = useUser(userId);

  if (userQuery.isLoading) return null;
  if (userQuery.isError) return null;

  const user = userQuery.data;
  const date = commentDate && new Date(commentDate).toLocaleDateString().replace(/\//g, '.');

  function getBadgeLabel() {
    switch (type) {
      case CommentType.Student:
        return `${user?.displayName} (S)`;
      case CommentType.Supervisor:
        return `${user?.displayName} (PV)`;
      case CommentType.Teacher:
        return `${user?.displayName} (PL)`;
      default:
        throw new Error(`CommentType ${type} is not valid.`);
    }
  }

  function getBadgeClassName() {
    switch (type) {
      case CommentType.Student:
        return 'bg-student text-studentText text-xs font-bold mr-2 h-full';
      case CommentType.Supervisor:
        return 'bg-supervisor text-supervisorText text-xs font-bold mr-2 h-full';
      case CommentType.Teacher:
        return 'text-teacherText text-xs font-bold mr-2 bg-teacher h-full';
      default:
        throw new Error(`CommentType ${type} is not valid.`);
    }
  }

  return (
    <div className="border-t py-4 border-lightGray items-center">
      <Badge className={getBadgeClassName()}>
        {getBadgeLabel()}
      </Badge>
      <span>{children}</span>
      <p className={classnames('text-customGray', 'mt-3', 'text-xs')}>Sist endret: {date}</p>
    </div>
  );
}
