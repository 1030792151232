import { Card, CardBody } from '@windmill/react-ui';
import { FormattedMessage } from 'react-intl';
import SigningForm from 'src/models/SigningForm';
import { UseMutationResult } from '@tanstack/react-query';
import { User } from 'src/models/user';
import { isTeacher, loggedInUserCanSign, showSignButton, noOneSigned } from './utils';
import Button from '../_common/Button/Button';
import SignedByStatus from '../StudentPage/SigningFormSection/SignedByStatus';

interface EndSemesterSignButtonProps {
  form: SigningForm;
  loggedInUser: User;
  signMutation: UseMutationResult<any, any, any, any>;
  endSemesterSignButtonDisabled: boolean;
}

export default function EndSemesterTeacherWhoHasSignedCard({ form, loggedInUser, signMutation, endSemesterSignButtonDisabled }: EndSemesterSignButtonProps) {
  return (
    <>
      {isTeacher(loggedInUser) && (
        <div>
          <Card className="flex w-auto justify-between mb-4">
            <CardBody className="flex-col w-auto text-left self-center mb-2">
              {noOneSigned(form) && (
                <div className="mt-2">
                  <FormattedMessage id="signingFormNoOneSignedYet" />
                </div>
              )}
              <SignedByStatus signingForm={form} />
            </CardBody>
          </Card>
          <hr className="mt-4 mb-4" />
        </div>
      )}
    </>
  );
}
