import { useQuery } from '@tanstack/react-query';
import { fetchClassById } from 'src/api/classAPI';
import ApiError from 'src/api/errorHandling/ApiError';
import { getNewestSupervisorInvitationForLoggedInStudent } from 'src/api/invitationAPI';
import { fetchSigningFormByClassAndStudent } from 'src/api/signingFormAPI';
import { fetchMyUser } from 'src/api/userAPI';
import { Class } from 'src/models/classes';
import SigningForm from 'src/models/SigningForm';
import SupervisorInvitation from 'src/models/supervisorInvitation';
import { User } from 'src/models/user';

interface FormListPageState {
  user: User;
  class: Class;
  signingForm?: SigningForm;
}

export function useFormListPageData() {
  return useQuery<FormListPageState, ApiError>({
    queryKey: ['useFormListPageData'],
    queryFn: async () => {
      const user = await fetchMyUser();

      const classId = user.studentReferences?.classId;
      if (!classId) {
        const error: ApiError = {
          description: 'No class found for this student',
          statusCode: -1,
        };
        throw error;
      }

      const classData = await fetchClassById(classId);
      let signingForm: SigningForm | undefined;

      try {
        signingForm = await fetchSigningFormByClassAndStudent(classId, user.id);
      } catch (error) {
        // Swallow 404 errors, signingform might not exist
        const apiError = error as ApiError;
        if (apiError.statusCode !== 404) throw apiError;
      }

      return {
        user,
        class: classData,
        signingForm,
      };
    },
  });
}

export function useSupervisorInvitationForLoggedInStudent() {
  return useQuery<SupervisorInvitation | null, ApiError>({
    queryKey: ['useSupervisorInvitationForLoggedInStudent'],
    queryFn: getNewestSupervisorInvitationForLoggedInStudent,
  });
}
