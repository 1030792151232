import { Badge as WindmillBadge } from '@windmill/react-ui';
import React, { ReactNode } from 'react';
import { GiPlainCircle } from 'react-icons/gi';

export interface BadgeProps {
  type?: 'supervisor' | 'teacher' | 'neutral' | 'student';
  children: React.ReactChild | React.ReactChild[] | ReactNode;
  onClick?: () => void;
}

const Badge: React.FC<BadgeProps> = ({ children, type = 'neutral', onClick }) => {
  function renderCircle() {
    if (type === 'supervisor') return <GiPlainCircle className="mr-1 my-auto text-supervisor" />;
    if (type === 'teacher') return <GiPlainCircle className="mr-1 my-auto text-teacher" />;
    if (type === 'student') return <GiPlainCircle className="mr-1 my-auto text-student" />;
    return null;
  }

  function renderBadge() {
    return (
      <WindmillBadge className="text-xs font-bold py-1 mt-2 ml-2 bg-darkergray first:ml-0" type="neutral" onClick={onClick}>
        {renderCircle()}
        {children}
      </WindmillBadge>
    );
  }

  if (onClick) {
    return (
      <button onClick={onClick}>
        {renderBadge()}
      </button>
    );
  }
  return renderBadge();
};

export default Badge;
