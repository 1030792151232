import React from 'react';
import { ContextProps } from '../GlobalServices';
import { SessionStorageContext } from '../../context/SessionStorageContext';

const SessionStorageService: React.FC<ContextProps> = ({ children }: ContextProps) => (
  <SessionStorageContext.Provider value={sessionStorage}>
    {children}
  </SessionStorageContext.Provider>
);

const sessionStorage = {
  store: <T, >(key: string, value: T) => {
    const parsedString = JSON.stringify(value);
    global.sessionStorage.setItem(key, parsedString);
  },

  retrieve: <T, >(key: string): T | undefined => {
    const result = window.sessionStorage.getItem(key);
    if (!result) { return undefined; }
    try {
      return JSON.parse(result);
    } catch {
      return result as any;
    }
  },

  remove: (key: string) => {
    global.sessionStorage.removeItem(key);
  },

  clear: () => {
    global.sessionStorage.clear();
  },

  hasKey: (key: string) => !!global.sessionStorage.getItem(key),
};

export default SessionStorageService;
