import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Spinner from 'src/components/_common/Spinner/Spinner';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import WarningSection from './WarningSection';
import FormSection from './FormSection';
import TopDescriptionSection from './TopDescriptionSection';
import { useSigningFormPageQuery } from './hooks';

const SigningFormPage = () => {
  const { formatMessage } = useIntl();
  const { classId, studentUserId } = useParams<{ classId: string, studentUserId: string }>();

  const query = useSigningFormPageQuery(+classId, +studentUserId);

  if (query.isLoading) return <Spinner />;
  if (query.isError) { return <ApiErrorAlert error={query.error} message={formatMessage({ id: 'signingFormPageErrorMessage' })} />; }

  const { loggedInUser, class: _class, signingForm, student, supervisor, teacher } = query.data;
  const { period, subject } = _class;

  return (
    <>
      <PageHeader title={formatMessage({ id: 'signing-form-header' })} />

      <TopDescriptionSection
        student={student}
        period={period}
        subject={subject}
        supervisor={supervisor}
        teacher={teacher}
      />

      <WarningSection class={_class} student={student} className="mb-8" supervisor={supervisor} teacher={teacher} />

      <FormSection
        existingSigningForm={signingForm}
        loggedInUser={loggedInUser}
        class={_class}
        student={student}
      />
    </>
  );
};

export default SigningFormPage;
