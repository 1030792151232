import { useIntl } from 'react-intl';
import FeideIcon from './feideIcon.png';

function redirectToFeide() {
  const clientId = process.env.REACT_APP_FEIDE_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_FEIDE_REDIRECT_URI;

  window.location.href = `https://auth.dataporten.no/oauth/authorization?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}`;
}

export default function FeideLoginButton() {
  const { formatMessage } = useIntl();
  return (
    <button onClick={redirectToFeide} className="bg-primary w-full flex justify-center p-4 text-white rounded-full">
      <img src={FeideIcon} alt="Feide ikon" className="h-6 pr-2" />
      {formatMessage({ id: 'feide-login' })}
    </button>
  );
}
