import React from 'react';
import { FormProviderService } from 'src/services/FormProvider/FormProviderService';
import './Main.scss';
import Toast from 'src/components/_common/Toast/Toast';
import { IntlProvider } from 'react-intl';
import { useLanguage } from 'src/hooks/useLanguage';
import { LanguageContext } from 'src/context/LanguageContext';
import PrivacyPolicyModal from 'src/pages/Main/MainPage/PrivacyPolicyModal/PrivacyPolicyModal';
import getLanguage from '../../../utils/getLanguage';
import MainRouter from '../MainRouter';
import DocumentTitle from './DocumentTitle';

const MainPage: React.FC = () => {
  const lang = useLanguage();
  const language = getLanguage(lang.lang);
  return (
    <>
      <LanguageContext.Provider value={lang}>
        <IntlProvider messages={language} key={lang.lang} locale={lang.lang}>
          <FormProviderService>
            <DocumentTitle />
            <MainRouter data-testid="main-router" />
            <Toast />
            <PrivacyPolicyModal />
          </FormProviderService>
        </IntlProvider>
      </LanguageContext.Provider>
    </>
  );
};
export default MainPage;
