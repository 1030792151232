import { useMutation } from '@tanstack/react-query';
import { sendOneTimePassword, verifyOneTimePassword, VerifyOneTimePasswordParameters } from 'src/api/authAPI';
import ApiError from 'src/api/errorHandling/ApiError';

export function useSendOneTimePassword(onSuccess: () => void) {
  return useMutation<void, ApiError, string>({
    mutationFn: sendOneTimePassword,
    onSuccess,
  });
}

export function useVerifyOneTimePassword(onSuccess: (token: string) => void) {
  return useMutation<string, ApiError, VerifyOneTimePasswordParameters>({
    mutationFn: verifyOneTimePassword,
    onSuccess,
  });
}
