import { AssignmentFormWithAssignmentId } from 'src/components/ClassFormsTable/AddClassFormModal/AddClassFormModal';
import SigningForm from 'src/models/SigningForm';
import { Assignment, Class } from 'src/models/classes';

export function getMainFormsFromClass(classData: Class, signingForm?: SigningForm) {
  const midSemesterPassed = midSemesterHasPassed(signingForm);

  const { assignments } = classData;
  if (!assignments) return [];

  return assignments
    .filter(assignment => filterActiveAssignments(assignment, midSemesterPassed))
    .flatMap(mapAssignmentToForms)
    .filter(form => form.isMainForm);
}

export function getRegularFormsFromClass(classData: Class) {
  const { assignments } = classData;
  if (!assignments) return [];

  return assignments
    .flatMap(mapAssignmentToForms)
    .filter(form => !form.isMainForm);
}

export function getCurrentDeadlineForClass(classData: Class, signingForm?: SigningForm) {
  if (midSemesterHasPassed(signingForm)) {
    if (!classData.endSemesterDate) return undefined;
    return new Date(classData.endSemesterDate);
  }
  return new Date(classData.midSemesterDate!);
}

function midSemesterHasPassed(signingForm?: SigningForm) {
  if (!signingForm || !signingForm.midSemesterCompletedDate) return false;
  return new Date() > new Date(signingForm.midSemesterCompletedDate);
}

function filterActiveAssignments(assignment: Assignment, midSemesterPassed: boolean) {
  if (midSemesterPassed) return !assignment.isMidSemester;
  return assignment.isMidSemester;
}

function mapAssignmentToForms(assignment: Assignment) {
  return assignment.forms.map(form => ({ ...form, assignmentId: assignment.id } as AssignmentFormWithAssignmentId));
}
