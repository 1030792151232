import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { AssignmentFormWithAssignmentId } from 'src/components/ClassFormsTable/AddClassFormModal/AddClassFormModal';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import Card from 'src/components/_common/Card/Card';
import CardList from 'src/components/_common/CardList/CardList';
import Spinner from 'src/components/_common/Spinner/Spinner';
import useLoggedInUser from 'src/hooks/user/useLoggedInUser';
import { Class } from 'src/models/classes';
import SigningForm from 'src/models/SigningForm';
import InviteSupervisorModal from './InviteSupervisorModal';
import { getCurrentDeadlineForClass, getMainFormsFromClass, getRegularFormsFromClass } from './utils';

interface MainFormsListProps {
  classData: Class,
  signingForm?: SigningForm,
  supervisorExists: boolean,
}

export default function AssignmentFormsLists({ classData, signingForm, supervisorExists }: MainFormsListProps) {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const [inviteSupervisorModalOpen, setInviteSupervisorModalOpen] = useState(false);
  const [clickedAssignmentForm, setClickedAssignmentForm] = useState<AssignmentFormWithAssignmentId | null>(null);
  const { data: loggedInUser, isError: loggedInUserIsError, error: loggedInUserError, isLoading: loggedInUserIsLoading } = useLoggedInUser();

  function handleFormClick(form: AssignmentFormWithAssignmentId) {
    setClickedAssignmentForm(form);
    if (supervisorExists) navigateToForm(form);
    else setInviteSupervisorModalOpen(true);
  }

  function navigateToForm(form: AssignmentFormWithAssignmentId) {
    const url = `/student/${loggedInUser?.id}/form/${form.id}/class/${classData.id}/assignment/${form.assignmentId}`;
    const pPath = history.location.pathname;
    history.push({ pathname: url, state: { pPath } });
  }

  const mainForms = getMainFormsFromClass(classData, signingForm);
  const regularForms = getRegularFormsFromClass(classData);
  const deadline = getCurrentDeadlineForClass(classData, signingForm);
  const deadlineEndSemester = classData.endSemesterDate ? new Date(classData.endSemesterDate) : undefined;
  const deadlineString = deadline ? `${deadline.getDate()}.${deadline.getMonth() + 1}.${deadline.getFullYear()}` : '?';
  const deadlineStringEndSemester = deadlineEndSemester ? `${deadlineEndSemester.getDate()}.${deadlineEndSemester.getMonth() + 1}.${deadlineEndSemester.getFullYear()}` : '?';

  if (loggedInUserIsLoading) return <Spinner />;
  if (loggedInUserIsError) return <ApiErrorAlert error={loggedInUserError!} message="Klarte ikke hente innlogget bruker." />;

  return (
    <>
      <CardList header={formatMessage({ id: 'respons-grading' })}>
        {mainForms.map(form => (
          <Card
            key={form.id}
            onClick={() => handleFormClick(form)}
            header={form.name}
            body={formatMessage({ id: 'deadline' }, { date: deadlineString })}
          />
        ))}
      </CardList>
      <CardList header={formatMessage({ id: 'specific-competencies' })}>
        {regularForms.map(form => (
          <Card
            key={form.id}
            onClick={() => handleFormClick(form)}
            header={form.name}
            primary={false}
            body={formatMessage({ id: 'deadline' }, { date: deadlineStringEndSemester })}
          />
        ))}
      </CardList>
      <InviteSupervisorModal
        isOpen={inviteSupervisorModalOpen}
        close={() => setInviteSupervisorModalOpen(false)}
        subject={classData.subject!}
        navigateToAssignmentForm={() => navigateToForm(clickedAssignmentForm!)}
      />
    </>
  );
}
