import { useMutation } from '@tanstack/react-query';
import { HiDownload } from 'react-icons/hi';
import ApiError from 'src/api/errorHandling/ApiError';
import { getStudentCsvReportFile } from 'src/api/periodAPI';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import Button from 'src/components/_common/Button/Button';
import { Period } from 'src/models/classes';

interface DownloadCsvButtonProps {
  semester: Period;
}

export default function DownloadCsvButton({ semester }: DownloadCsvButtonProps) {
  const mutation = useMutation<void, ApiError>({
    mutationFn: async () => {
      const file = await getStudentCsvReportFile(semester.id);
      const fileName = `Student Rapport ${semester.name}.csv`;

      // Create URL to file
      const objectUrl = window.URL.createObjectURL(file);

      // Create link to URL (trick to set the filename)
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.setAttribute('style', 'display: none');
      link.setAttribute('href', objectUrl);
      link.setAttribute('download', fileName);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(objectUrl);
      link.remove();
    },
  });

  return (
    <>
      <Button
        onClick={() => mutation.mutate()}
        isSaving={mutation.isLoading}
        disabled={mutation.isLoading}
        icon={HiDownload}
      >Last ned rapport
      </Button>
      {mutation.isError && <ApiErrorAlert error={mutation.error} message="Klarte ikke laste ned rapport" />}
    </>
  );
}
