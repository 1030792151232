import { useQuery } from '@tanstack/react-query';
import ApiError from 'src/api/errorHandling/ApiError';
import { fetchStudentPassWarning } from 'src/api/studentPassWarningAPI';
import StudentPassWarning from 'src/models/StudentPassWarning';

export default function useStudentPassWarning(classId: number, studentUserId: number) {
  return useQuery<StudentPassWarning | null, ApiError>(['useStudentPassWarning', classId, studentUserId], async () => {
    try {
      return await fetchStudentPassWarning({ classId, studentUserId });
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.statusCode === 404) return null;
      throw apiError;
    }
  });
}
