import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchClassById } from 'src/api/classAPI';
import ApiError from 'src/api/errorHandling/ApiError';
import { confirmStudentPassWarning, fetchStudentPassWarning } from 'src/api/studentPassWarningAPI';
import { fetchMyUser, fetchUserById } from 'src/api/userAPI';
import { Class } from 'src/models/classes';
import StudentPassWarning from 'src/models/StudentPassWarning';
import { User } from 'src/models/user';

interface StudentPassWarningQueryState{
  class: Class;
  warning?: StudentPassWarning;
  supervisor?: User;
  teacher?: User;
  student: User;
}

export function useStudentPassWarningQuery() {
  return useQuery<StudentPassWarningQueryState | undefined, ApiError>({
    queryKey: ['useStudentPassWarningQuery'],
    queryFn: async () => {
      const loggedInUser = await fetchMyUser();
      if (!loggedInUser.studentReferences) return undefined;
      const { classId, advisorUserId, teacherUserId } = loggedInUser.studentReferences;

      const [classData, warning, supervisor, teacher] = await Promise.all([
        fetchClassById(classId),
        fetchWarning(classId, loggedInUser.id),
        advisorUserId ? fetchUserById(advisorUserId) : undefined,
        teacherUserId ? fetchUserById(teacherUserId) : undefined,
      ]);

      return { class: classData, warning, supervisor, teacher, student: loggedInUser };
    },
  });
}

async function fetchWarning(classId: number, studentUserId: number) {
  try {
    return await fetchStudentPassWarning({ classId, studentUserId });
  } catch (error) {
    const apiError = error as ApiError;
    if (apiError.statusCode !== 404) throw error;
  }
  return undefined;
}

export function useConfirmStudentPassWarningMutation(onSuccess: () => void) {
  const queryClient = useQueryClient();

  return useMutation<void, ApiError>({
    mutationFn: async () => {
      const loggedInUser = await fetchMyUser();
      if (!loggedInUser.studentReferences) {
        const error: ApiError = {
          statusCode: -1,
          description: 'Student references not found',
        };
        throw error;
      }
      const { classId } = loggedInUser.studentReferences;

      confirmStudentPassWarning({ studentUserId: loggedInUser.id, classId });
    },
    onSuccess: () => {
      queryClient.invalidateQueries();
      onSuccess();
    },
  });
}
