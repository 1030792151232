import LabeledInput from 'src/components/_common/LabeledInput/LabeledInput';
import { useIntl } from 'react-intl';
import Button from 'src/components/_common/Button/Button';
import { useHistory } from 'react-router-dom';
import { useState, FormEvent } from 'react';
import CardWithTitle from 'src/components/_common/CardWithTitle/CardWithTitle';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import { fetchMyUser } from 'src/api/userAPI';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import PageSubTitle from 'src/components/_common/PageHeader/PageSubTitle';
import { useVerifyOneTimePassword } from './queries';

const EnterPasswordPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory<{email: string}>();
  const verifyMutation = useVerifyOneTimePassword(onSuccess);
  const { handleSetUser } = LoggedInUserContext.useContainer();

  const [code, setCode] = useState<string>('');

  const email = history.location.state?.email;
  if (!email) history.push('/login');

  async function onSuccess(token: string) {
    sessionStorage.setItem('token', JSON.stringify(token));

    const user = await fetchMyUser();
    handleSetUser(user);

    window.location.href = '/';
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!email) return;
    if (verifyMutation.isLoading) return;
    if (!code || !e.currentTarget.checkValidity()) return;

    verifyMutation.mutate({ email, code });
  }

  function navigateBackToEnterEmailPage() {
    history.push('/login');
  }

  return (
    <>
      <PageHeader title={formatMessage({ id: 'verification-code' })}>
        <PageSubTitle>{formatMessage({ id: 'verification-code-info' })}</PageSubTitle>
        <PageSubTitle>{formatMessage({ id: 'verification-code-info-junk-mail' })}</PageSubTitle>
      </PageHeader>
      <CardWithTitle title={formatMessage({ id: 'login' })} onBack={navigateBackToEnterEmailPage}>
        <p className="text-center mb-8 text-darkergray">{formatMessage({ id: 'verifyOneTimePasswordPageInfo' })}</p>
        <form onSubmit={onSubmit} className="text-center">
          <LabeledInput
            type="text"
            name="token"
            id="token"
            inputMode="numeric"
            pattern="[0-9]*"
            autoComplete="one-time-code"
            required
            maxLength={6}
            minLength={6}
            labelText={formatMessage({ id: 'verification-code-input-info' })}
            onValueChangeHandler={setCode}
            autoFocus
          />
          <Button type="submit" isSaving={verifyMutation.isLoading} className="mt-8 w-full" data-testid="submit-button">Send</Button>
        </form>
        {verifyMutation.isError && <ApiErrorAlert error={verifyMutation.error} message={formatMessage({ id: 'verifyOneTimePasswordPageErrorMessage' })} />}
      </CardWithTitle>
    </>
  );
};

export default EnterPasswordPage;
