import { useQuery } from '@tanstack/react-query';
import { FiLogOut } from 'react-icons/fi';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ApiError from 'src/api/errorHandling/ApiError';
import { fetchMyUser } from 'src/api/userAPI';
import { useTokenProvider } from 'src/context/TokenProviderContext';
import { User } from 'src/models/user';
import ApiErrorAlert from '../ApiErrorAlert/ApiErrorAlert';

export default function LogoutButton() {
  const token = useTokenProvider();
  const { go, push } = useHistory();

  const loggedInUserQuery = useQuery<User | null, ApiError>({
    queryKey: ['fetchMyUser', 'sidebar'],
    queryFn: async () => {
      try {
        return await fetchMyUser();
      } catch (error) {
        const apiError = error as ApiError;
        if (apiError.statusCode === 401) return null;

        throw error;
      }
    },
  });

  function handleLogout() {
    token.cancelToken();
    push('/');
    go(0);
  }

  if (loggedInUserQuery.isError) {
    return (
      <ApiErrorAlert error={loggedInUserQuery.error} message="Klarte ikke hente innlogget bruker" />
    );
  }

  if (!loggedInUserQuery.data) return null;

  return (
    <button className="flex items-center pb-12" onClick={handleLogout}>
      <FormattedMessage id="logout" />
      <FiLogOut title="logout" className="ml-2" />
    </button>
  );
}
