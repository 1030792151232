import { FormattedMessage } from 'react-intl';
import Button from 'src/components/_common/Button/Button';

interface FormAdminExpectationInfoProps {
    isMainForm: boolean,
    midTermEvaluation: boolean,
    setMidTermEvaluation: (value: boolean) => void,
    formName: string,
    periodName: string
}

const FormAdminExpectationInfo = ({ isMainForm, midTermEvaluation, setMidTermEvaluation, formName, periodName }: FormAdminExpectationInfoProps) => (
  <>
    {isMainForm
      && (
      <div className="text-center text-customGray border-b border-lightGray mb-8">
        <Button
          className={`${midTermEvaluation && 'text-primary font-bold'}`}
          layout="link"
          onClick={() => setMidTermEvaluation(true)}
        >
          <FormattedMessage id="mid-semester" />
        </Button>
        <Button
          className={`${!midTermEvaluation && 'text-primary font-bold'}`}
          layout="link"
          onClick={() => setMidTermEvaluation(false)}
        >
          <FormattedMessage id="end-semester" />
        </Button>
      </div>
      )}
    <div className="bg-lighterGray text-darkergray p-2 rounded-lg font-semibold mb-8">
      <FormattedMessage id="expectation-info" values={{ formName, periodName }} />
    </div>
  </>
);

export default FormAdminExpectationInfo;
