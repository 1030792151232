import { FormattedMessage } from 'react-intl';
import { Period, Subject } from 'src/models/classes';
import { User } from 'src/models/user';

interface TopDescriptionSectionProps {
  period?: Period;
  subject?: Subject;
  student: User;
  supervisor?: User;
  teacher?: User;
}

export default function TopDescriptionSection({ period, subject, student, supervisor, teacher }: TopDescriptionSectionProps) {
  return (
    <>
      <p className="mb-1">
        <strong><FormattedMessage id="semester" />:&nbsp;</strong>
        {period?.name}
      </p>
      <p className="mb-1">
        <strong><FormattedMessage id="class" />:&nbsp;</strong>
        {subject?.name}
      </p>
      <p className="mb-1">
        <strong><FormattedMessage id="Student" />:&nbsp;</strong>
        {student.displayName}
      </p>
      <p className="mb-1">
        <strong><FormattedMessage id="Advisor" />:&nbsp;</strong>
        {supervisor?.displayName}
      </p>
      <p className="mb-1">
        <strong><FormattedMessage id="Teacher" />:&nbsp;</strong>
        {teacher?.displayName}
      </p>
    </>
  );
}
