import { useQuery } from '@tanstack/react-query';
import ApiError from 'src/api/errorHandling/ApiError';
import { fetchUserById } from 'src/api/userAPI';
import { User } from 'src/models/user';

export default function useUser(id: number) {
  return useQuery<User, ApiError>({
    queryKey: ['fetchUserById', 'useUser', id],
    queryFn: () => fetchUserById(id),
  });
}
