import { useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useAuthenticator } from 'src/context/AuthenticatorContext';
import { HttpResponse } from 'src/context/HttpClientContext';
import ErrorMessage from 'src/components/_common/ErrorMessage/ErrorMessage';
import Spinner from 'src/components/_common/Spinner/Spinner';
import { useAbortableEffect } from 'src/hooks/useAbortableEffect';

const FeideSuccessPage = () => {
  const location = useLocation();
  const auth = useAuthenticator();

  const { search } = location;

  const urlParams = new URLSearchParams(search);

  const { code } = Object.fromEntries(urlParams);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<HttpResponse<any>>();

  const getFeideAuthToken = async () => {
    try {
      await auth.feideAuth(code);
    } catch (e: any) {
      const err: HttpResponse<any> = e;
      setError(err);
      setIsLoading(false);
    }
  };

  useAbortableEffect(async () => {
    await getFeideAuthToken();
  },
  []);

  if (isLoading) {
    return (
      <div className="h-full my-auto">
        <Spinner />
      </div>
    );
  }
  if (error) {
    return <ErrorMessage {...error} />;
  }
  return (
    <Redirect to="/" />
  );
};

export default FeideSuccessPage;
