import { ReactNode } from 'react';
import { EditableFormQuestion } from 'src/models/editableForm';
import { GrAddCircle } from 'react-icons/gr';
import { Button } from '@windmill/react-ui';
import ReorderableList from 'src/components/_common/ReorderableList/ReorderableList';
import FormContext from 'src/hooks/useFormContext';
import { useIntl } from 'react-intl';
import AdminFormQuestionOptions from './AdminFormQuestionOptions/AdminFormQuestionOptions';

interface AdminFormQuestionListProps {
  groupOrder: number;
  questions: EditableFormQuestion[];
}

const AdminFormQuestionList = ({ questions, groupOrder }: AdminFormQuestionListProps) => {
  const { addQuestion, editQuestion, setQuestionHelpText, removeQuestion } = FormContext.useContainer();
  const { formatMessage } = useIntl();

  const handleSetQuestionHelpText = (helpText: string, questionOrder: number) => setQuestionHelpText(groupOrder, helpText, questionOrder);
  const handleRemoveQuestion = (questionOrder: number) => removeQuestion(groupOrder, questionOrder);

  function getHeaderText(order: number): string {
    return questions?.find(x => x?.order === order)!?.text!;
  }

  function getContent(order: number): ReactNode {
    const question = questions?.find(x => x?.order === order);
    if (typeof question === 'undefined') {
      return null;
    }
    return (<AdminFormQuestionOptions handleSetQuestionHelpText={handleSetQuestionHelpText} question={question} key={question.id} />);
  }
  function getHeaderSubtitle(order: number): ReactNode {
    const groupIndex = questions?.findIndex(x => x?.order === order)!;
    return (<div>{`${groupIndex + 1}.`}</div>);
  }

  return (
    <>
      <div className="flex flex-col gap-5">
        <ReorderableList
          onDelete={handleRemoveQuestion}
          inputStyle="font-h3"
          border
          openButtonLocation="bottom"
          items={questions}
          headerText={getHeaderText}
          itemChild={getContent}
          headerSubTitle={getHeaderSubtitle}
          setHeaderText={(questionOrder, questionText) => editQuestion(groupOrder, questionText, questionOrder)}
        />
      </div>
      <div className="w-full my-5 h-8 text-center">
        <Button aria-label={formatMessage({ id: 'createQuestion' })} layout="link" size="larger" onClick={() => addQuestion(groupOrder)} icon={GrAddCircle} className="mx-auto" />
      </div>
    </>
  );
};

export default AdminFormQuestionList;
