import { Class } from 'src/models/classes';
import { sortSubjectsByCodeComparer } from './subjectUtils';

// eslint-disable-next-line import/prefer-default-export
export function sortClassesBySubjectCodeComparer(a: Class, b: Class) {
  const subjectA = a.subject;
  const subjectB = b.subject;

  if (subjectA === undefined || subjectB === undefined) {
    throw new Error(`Unable to sort classes by subject code. One or more of the classes does not contain subject. \nClass A: ${a}\nClass B: ${b}`);
  }

  return sortSubjectsByCodeComparer(subjectA, subjectB);
}

export function sortClassesBySemesterStartDateThenSubjectCodeComparer(a: Class, b: Class) {
  const startDateValue = sortClassesBySemesterStartDateComparer(a, b);
  if (startDateValue !== 0) return startDateValue;

  return sortClassesBySubjectCodeComparer(a, b);
}

function sortClassesBySemesterStartDateComparer(a: Class, b: Class) {
  const semesterStartDateStringA = a.period?.startDate;
  const semesterStartDateStringB = b.period?.startDate;

  if (!semesterStartDateStringA || !semesterStartDateStringB) {
    throw new Error(`Unable to sort classes by semester start date. One or more of the classes does not contain a period. \nClass A: ${a}\nClass B: ${b}`);
  }

  const semesterStartDateA = new Date(semesterStartDateStringA);
  const semesterStartDateB = new Date(semesterStartDateStringB);

  if (semesterStartDateA > semesterStartDateB) return -1;
  if (semesterStartDateA < semesterStartDateB) return 1;
  return 0;
}
