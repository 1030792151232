import { AssignmentForm } from 'src/models/classes';
import { Form } from 'src/models/form';

// eslint-disable-next-line import/prefer-default-export
export function sortFormsByNameComparer(a: Form | AssignmentForm, b: Form | AssignmentForm) {
  const nameA = a.name;
  const nameB = b.name;

  return nameA.localeCompare(nameB, undefined, { numeric: true });
}
