import Contextualizer from 'src/context/Contextualizer/Contextualizer';
import ProvidedServices from 'src/context/Contextualizer/ProvidedServices';

export interface ISessionStorage {
  store<T>(key: string, value: T): void;
  retrieve<T>(key: string): T | undefined;
  remove(key: string): void;
  hasKey(key: string): boolean;
  clear(): void;
}

export const SessionStorageContext = Contextualizer.createContext<ISessionStorage>(ProvidedServices.SessionStorage);
export const useSessionStorage = () => Contextualizer.use<ISessionStorage>(ProvidedServices.SessionStorage);
