import React from 'react';
import { Alert } from '@windmill/react-ui';

interface ErrorAlertProps {
  message: string | React.ReactNode;
  title: string;
  type: 'warning' | 'info' | 'danger' | 'success' | 'neutral';
}

const ResponsAlert: React.FC<ErrorAlertProps> = ({ message, title, type }) => {
  function renderMessageElement() {
    if (typeof message === 'string') {
      return <p>{message}</p>;
    }
    return message;
  }

  return (
    <Alert type={type} className="my-4 p-4 shadow-tableShadow">
      {title && <h3 className="mt-0">{title}</h3>}
      {renderMessageElement()}
    </Alert>
  );
};

export default ResponsAlert;
