import { useParams, useHistory } from 'react-router-dom';
import Spinner from 'src/components/_common/Spinner/Spinner';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import ClassFormsTable from 'src/components/ClassFormsTable/ClassFormsTable';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Badge from 'src/components/_common/Badge/Badge';
import List from 'src/components/_common/List/List';
import ListItem from 'src/components/_common/List/ListItem';
import { SimpleUser } from 'src/models/classes';
import ClassesContext from '../../../../hooks/useClasses';

const SupervisorClass = () => {
  const { getCurrentClass, currentClass, currentClassLoaded, getMyStudents, myStudents, myStudentsLoaded } = ClassesContext.useContainer();
  const { classId } = useParams<{classId: string}>();
  const history = useHistory();
  const { formatMessage } = useIntl();

  useEffect(() => {
    getCurrentClass(+classId);
    getMyStudents(+classId);
  }, []);

  const navigateToStudent = (student: SimpleUser) => history.push(`/supervisor/class/${classId}/student/${student.id}`);

  if (!(currentClassLoaded || myStudentsLoaded)) return <Spinner />;

  return (
    <>
      <PageHeader title={currentClass?.subject?.subjectCode} subTitle={currentClass?.subject?.name}>
        <Badge>{currentClass?.period?.name}</Badge>
      </PageHeader>
      <ClassFormsTable baseRoute="/supervisor" currentClass={currentClass} subjectCode={currentClass?.subject?.subjectCode} />
      <List
        className="pt-8"
        title={formatMessage({ id: 'your-students' })}
        emptyListMessage={formatMessage({ id: 'table-none-added' }, { name: formatMessage({ id: 'students' }).toLowerCase() })}
        listIsEmpty={!myStudents || myStudents.length === 0}
      >
        {myStudents?.map(student => (
          <ListItem key={student.id} onClick={() => navigateToStudent(student)}>
            {student.displayName}
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default SupervisorClass;
