import { Class, UsersForClass } from 'src/models/classes';
import api from './api';
import defaultErrorConverter from './errorHandling/defaultErrorConverter';

export async function fetchMyMatchingFeideClasses() {
  try {
    const res = await api.get('api/classes/me/matching-feide');
    return res.data.data as Class[];
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchMyClassesMatchingAffiliations() {
  try {
    const res = await api.get('api/classes/me/matching-affiliations');
    return res.data.data as Class[];
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function joinClass(classId: number) {
  try {
    await api.post(`api/classes/${classId}/students/me`);
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchClassById(classId: number) {
  try {
    const res = await api.get(`api/classes/${classId}`);
    return res.data.data as Class;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchAllUsersInClass(classId: number) {
  try {
    const res = await api.get(`api/classes/${classId}/users`);
    return res.data.data as UsersForClass;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function setSupervisorForStudentInClass(classId: number, studentUserId: number, supervisorUserId: number) {
  try {
    const data = { data: { supervisorUserId } };
    await api.post(`api/classes/${classId}/students/${studentUserId}/advisor/${supervisorUserId}`, data);
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}
