/* eslint-disable import/prefer-default-export */
import api from './api';
import defaultErrorConverter from './errorHandling/defaultErrorConverter';

export async function getStudentCsvReportFile(periodId: number) {
  try {
    const result = await api.get(`/api/periods/${periodId}/students-csv`, {
      responseType: 'blob',
    });
    return await result.data as File;
  } catch (error) {
    const apiError = defaultErrorConverter(error);
    throw apiError;
  }
}
