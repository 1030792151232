import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ClassesContext from 'src/hooks/useClasses';
import { BsDot } from 'react-icons/bs';
import Select from 'src/components/_common/Select/Select';
import Spinner from 'src/components/_common/Spinner/Spinner';
import UsersContext from 'src/hooks/useUsers';
import Button from 'src/components/_common/Button/Button';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Badge } from '@windmill/react-ui';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';

const SupervisorPage = () => {
  const { classId, userId } = useParams<{classId: string, userId: string}>();
  const { getUser, user: advisor, userLoaded } = UsersContext.useContainer();
  const [selectedStudent, setSelectedStudent] = useState<any>();

  const {
    currentClass,
    currentClassLoaded,
    getCurrentClass,
    usersForClass,
    getUsersForClass,
    usersForClassLoaded,
    setAdvisorForStudent,
    getClassStudents,
    classStudentsLoaded,
    classStudents,
    isSaving,
  } = ClassesContext.useContainer();

  useEffect(() => {
    if (advisor?.id !== +userId || !userLoaded) getUser(+userId);
    if (currentClass?.id !== +classId || !currentClassLoaded) getCurrentClass(+classId);
    if (!usersForClassLoaded) getUsersForClass(+classId);
  }, [userId]);

  useEffect(() => {
    if (usersForClassLoaded) getClassStudents(+classId);
  }, [usersForClassLoaded]);

  const handleSetSupervisorForStudent = () => {
    if (selectedStudent && advisor) setAdvisorForStudent(+classId, selectedStudent.value, advisor?.id).then(() => getClassStudents(+classId));
  };

  const studentNotAssignedToSupervisor = classStudents?.filter(classStudent => classStudent?.studentReferences?.advisorUserId !== advisor?.id);
  const studentAssignedToSupervisor = classStudents?.filter(classStudent => classStudent?.studentReferences?.advisorUserId === advisor?.id);

  if (!classStudentsLoaded || !advisor || !usersForClassLoaded) return <Spinner />;

  return (
    <>
      <PageHeader title={advisor?.displayName}>
        <div className="flex justify-center items-center text-darkerGray">
          {currentClass?.period?.name}
          <BsDot />
          {currentClass?.subject?.subjectCode}
        </div>
      </PageHeader>
      <h2>
        <FormattedMessage id="students" />
      </h2>
      <div className="flex flex-nowrap items-end">
        <Select
          label=""
          wrapperClassName="block w-full"
          options={studentNotAssignedToSupervisor?.map(student => ({ label: student.displayName, value: student.id }))}
          onChange={setSelectedStudent}
        />
        <Button
          text="Legg til"
          style={{ height: '46px' }}
          className="whitespace-nowrap ml-4"
          onClick={handleSetSupervisorForStudent}
          isSaving={isSaving}
        />
      </div>
      <div className="mt-8">
        {studentAssignedToSupervisor?.map((student, index) => (
          <div key={student.id} className={classnames({ 'border-b': index !== studentAssignedToSupervisor?.length - 1 }, 'flex border-lightGray justify-between p-4')}>
            <div>
              {student.displayName}
            </div>
            <div className="flex items-center">
              <Badge className="text-teacherText text-xs font-bold mr-2 bg-teacher h-full pt-0.5">
                PL
              </Badge>
              {usersForClass?.teachers.find(teacher => teacher.id === student.studentReferences?.teacherUserId)?.displayName}
            </div>
          </div>
        ))}
        {studentAssignedToSupervisor?.length === 0
        && (
        <div className="text-customGray font-normal text-base text-center">
          <FormattedMessage id="no-students-assigned-supervisor" values={{ supervisor: advisor.displayName }} />
        </div>
        )}
      </div>
    </>
  );
};

export default SupervisorPage;
