import { Table } from '@windmill/react-ui';
import { useState } from 'react';
import { HiOutlineX } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Select from 'src/components/_common/Select/Select';
import DesktopTable from './DesktopTable';
import MobileTable from './MobileTable';
import styles from './Styles.module.scss';

export default function IndependenceScalePage() {
  const { formatMessage } = useIntl();

  function closeTab() {
    window.close();
  }

  const items = [
    { header: formatMessage({ id: 'independenceScaleIndependent' }),
      values: [
        formatMessage({ id: 'independenceScaleIndependentValue1' }),
        formatMessage({ id: 'independenceScaleIndependentValue2' }),
        formatMessage({ id: 'independenceScaleIndependentValue3' }),
        formatMessage({ id: 'independenceScaleIndependentValue4' }),
        formatMessage({ id: 'independenceScaleIndependentValue5' }),
        formatMessage({ id: 'independenceScaleIndependentValue6' }),
        formatMessage({ id: 'independenceScaleIndependentValue7' }),
        formatMessage({ id: 'independenceScaleIndependentValue8' }),
        formatMessage({ id: 'independenceScaleIndependentValue9' }),
        formatMessage({ id: 'independenceScaleIndependentValue10' }),
      ] },
    { header: formatMessage({ id: 'independenceScalePartiallyIndependent' }),
      values: [
        formatMessage({ id: 'independenceScalePartiallyIndependentValue1' }),
        formatMessage({ id: 'independenceScalePartiallyIndependentValue2' }),
        formatMessage({ id: 'independenceScalePartiallyIndependentValue3' }),
        formatMessage({ id: 'independenceScalePartiallyIndependentValue4' }),
        formatMessage({ id: 'independenceScalePartiallyIndependentValue5' }),
        formatMessage({ id: 'independenceScalePartiallyIndependentValue6' }),
        formatMessage({ id: 'independenceScalePartiallyIndependentValue7' }),
        formatMessage({ id: 'independenceScalePartiallyIndependentValue8' }),
        formatMessage({ id: 'independenceScalePartiallyIndependentValue9' }),
        formatMessage({ id: 'independenceScalePartiallyIndependentValue10' }),
      ] },
    { header: formatMessage({ id: 'independenceScaleSupervised' }),
      values: [
        formatMessage({ id: 'independenceScaleSupervisedValue1' }),
        formatMessage({ id: 'independenceScaleSupervisedValue2' }),
        formatMessage({ id: 'independenceScaleSupervisedValue3' }),
        formatMessage({ id: 'independenceScaleSupervisedValue4' }),
        formatMessage({ id: 'independenceScaleSupervisedValue5' }),
        formatMessage({ id: 'independenceScaleSupervisedValue6' }),
        formatMessage({ id: 'independenceScaleSupervisedValue7' }),
        formatMessage({ id: 'independenceScaleSupervisedValue8' }),
        formatMessage({ id: 'independenceScaleSupervisedValue9' }),
        formatMessage({ id: 'independenceScaleSupervisedValue10' }),
      ] },
    { header: formatMessage({ id: 'independenceScaleAssisted' }),
      values: [
        formatMessage({ id: 'independenceScaleAssistedValue1' }),
        formatMessage({ id: 'independenceScaleAssistedValue2' }),
        formatMessage({ id: 'independenceScaleAssistedValue3' }),
        formatMessage({ id: 'independenceScaleAssistedValue4' }),
        formatMessage({ id: 'independenceScaleAssistedValue5' }),
        formatMessage({ id: 'independenceScaleAssistedValue6' }),
        formatMessage({ id: 'independenceScaleAssistedValue7' }),
        formatMessage({ id: 'independenceScaleAssistedValue8' }),
        formatMessage({ id: 'independenceScaleAssistedValue9' }),
        formatMessage({ id: 'independenceScaleAssistedValue10' }),
      ] },
    { header: formatMessage({ id: 'independenceScaleDependent' }),
      values: [
        formatMessage({ id: 'independenceScaleDependentValue1' }),
        formatMessage({ id: 'independenceScaleDependentValue2' }),
        formatMessage({ id: 'independenceScaleDependentValue3' }),
        formatMessage({ id: 'independenceScaleDependentValue4' }),
        formatMessage({ id: 'independenceScaleDependentValue5' }),
        formatMessage({ id: 'independenceScaleDependentValue6' }),
        formatMessage({ id: 'independenceScaleDependentValue7' }),
        formatMessage({ id: 'independenceScaleDependentValue8' }),
        formatMessage({ id: 'independenceScaleDependentValue9' }),
        formatMessage({ id: 'independenceScaleDependentValue10' }),
      ] },
  ];

  return (
    <>
      <PageHeader title={formatMessage({ id: 'independenceScale' })} includeSidebarWithToggle={false} />
      <div className="absolute top-0 right-0">
        <button className="p-4" onClick={closeTab}>
          <HiOutlineX className="text-3xl" />
        </button>
      </div>
      {/* Desktop view */}
      <div className={styles['desktop-container']}>
        <DesktopTable items={items} />
      </div>
      {/* Mobile view */}
      <div className={styles['mobile-container']}>
        <MobileTable items={items} />
      </div>
    </>
  );
}
