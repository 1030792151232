import { useIntl } from 'react-intl';
import { SimpleUser } from 'src/models/classes';
import { User } from 'src/models/user';
import ApiErrorAlert from '../_common/ApiErrorAlert/ApiErrorAlert';
import Select from '../_common/Select/Select';
import { useSetSupervisorForStudentMutation, useSupervisorSelectQuery } from './queries';

interface SupervisorSelectProps{
  student: User;
  classId: number;
}

export default function SupervisorSelect({ student, classId }: SupervisorSelectProps) {
  const { formatMessage } = useIntl();
  const query = useSupervisorSelectQuery(classId, student.studentReferences?.advisorUserId);
  const mutation = useSetSupervisorForStudentMutation();

  function mapSupervisorToSelectOption(supervisor: SimpleUser) {
    return { label: supervisor.displayName, value: supervisor.id };
  }

  function handleChange(newSupervisorId: number) {
    mutation.mutate({
      classId,
      studentUserId: student.id,
      supervisorUserId: newSupervisorId,
    });
  }

  if (query.isLoading) {
    return <Select isLoading label={formatMessage({ id: 'supervisor' })} />;
  }

  if (query.isError) return <ApiErrorAlert error={query.error} message="" />;

  return (
    <Select
      label={formatMessage({ id: 'supervisor' })}
      options={query.data.supervisorsInClass.map(mapSupervisorToSelectOption)}
      value={query.data.supervisor ? mapSupervisorToSelectOption(query.data.supervisor as SimpleUser) : undefined}
      onChange={value => handleChange((value as any).value)}
      isLoading={mutation.isLoading}
      isDisabled={mutation.isLoading}
      data-testid="select"
      name="select-supervisor"
    />
  );
}
