import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Select from 'src/components/_common/Select/Select';
import ClassesContext from 'src/hooks/useClasses';
import { Class } from 'src/models/classes';
import { User } from 'src/models/user';
import { sortClassesBySemesterStartDateThenSubjectCodeComparer } from 'src/utils/classUtils';

interface ClassSelectProps {
  selectedClasses: Class[];
  setSelectedClasses: (classes: Class[]) => void;
  isStudent: boolean;
  isTeacher: boolean;
  isSupervisor: boolean;
  user: User;
}

interface SelectOption {
  value: string;
  label: string;
}

function isIterable(obj: any) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === 'function';
}

export default function ClassSelect({ selectedClasses, setSelectedClasses, isStudent, isTeacher, isSupervisor, user }: ClassSelectProps) {
  const { classes: allClasses, classesLoaded, getClasses } = ClassesContext.useContainer();
  const { formatMessage } = useIntl();

  useEffect(() => {
    getClasses();
  }, []);

  useEffect(() => {
    if (user && isTeacher && allClasses && user.teacherClasses) {
      const classIdsForTeacher = user.teacherClasses;
      const classesForTeacher = allClasses.filter(c => classIdsForTeacher.includes(c.id));
      setSelectedClasses(classesForTeacher);
    }
  }, [user, isTeacher, allClasses]);

  useEffect(() => {
    if (user && isStudent && allClasses && user.studentReferences) {
      const classIdForStudent = user.studentReferences.classId;
      const classesForStudent = allClasses.find(c => c.id === classIdForStudent);
      if (classesForStudent) {
        setSelectedClasses([classesForStudent]);
      }
    }
  }, [user, isStudent, allClasses]);

  useEffect(() => {
    if (user && isSupervisor && allClasses && user.advisorClasses) {
      const classIdsForSupervisor = user.advisorClasses;
      const classesForSupervisor = allClasses.filter(c => classIdsForSupervisor.includes(c.id));
      setSelectedClasses(classesForSupervisor);
    }
  }, [user, isSupervisor, allClasses]);

  const convertClassToSelectOption = (_class: Class) => ({ value: _class.id.toString(), label: `${_class?.subject?.subjectCode} (${_class?.period?.name})` });

  const getValue = (_classes: Class[], _isMulti: boolean): SelectOption | SelectOption[] | undefined => {
    if (_isMulti) return _classes.map(convertClassToSelectOption);
    if (_classes.length === 0) return undefined;
    return convertClassToSelectOption(_classes[0]);
  };

  const handleChange = (newClassOptions: SelectOption[]) => {
    if (allClasses === undefined) return;
    const newClasses = newClassOptions
      .map(option => allClasses.find(c => c.id.toString() === option.value))
      .filter(option => option !== undefined) as Class[];

    setSelectedClasses(newClasses);
  };

  if (!isTeacher && !isStudent && !isSupervisor) return null;
  if (allClasses === undefined) return null;

  const isMulti = isTeacher || isSupervisor;
  const isRequired = isStudent && !isTeacher && !isSupervisor;

  return (
    <Select
      name="class"
      label={formatMessage({ id: 'class' })}
      options={[...allClasses].sort(sortClassesBySemesterStartDateThenSubjectCodeComparer).map(convertClassToSelectOption)}
      value={getValue(selectedClasses, isMulti)}
      isLoading={!classesLoaded}
      isMulti={isMulti ? true : undefined}
      className="w-full"
      onChange={values => {
        if (isIterable(values)) {
          handleChange([...values]);
        } else {
          const value: SelectOption = values as any;
          handleChange([value]);
        }
      }}
      required={isRequired ? true : undefined}

    />
  );
}
