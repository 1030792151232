import Membership from 'src/models/Membership';
import api from './api';
import defaultErrorConverter from './errorHandling/defaultErrorConverter';

export default async function fetchMemberships() {
  try {
    const res = await api.get('api/memberships');
    return res.data.data as Membership[];
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}
