import Spinner from 'src/components/_common/Spinner/Spinner';
import { FormattedMessage, useIntl } from 'react-intl';
import Card from 'src/components/_common/Card/Card';
import CardList from 'src/components/_common/CardList/CardList';
import { HiPrinter } from 'react-icons/hi';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Button from 'src/components/_common/Button/Button';
import ClassesContext from '../../../hooks/useClasses';
import LoggedInUserContext from '../../../hooks/useLoggedInUser';
import ShowStudentPdf from '../StudentForms/ShowStudentPdf';
import JoinClassPage from '../JoinClass/JoinClassPage';
import ShowSigningFormPdf from '../StudentForms/ShowSigningFormPdf';

const StudentClasses = () => {
  const { currentClass, getCurrentClass, currentClassLoaded, classesError, getMine, mine, mineLoaded } = ClassesContext.useContainer();
  const { user } = LoggedInUserContext.useContainer();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [hasClass, setHasClass] = useState<boolean>();

  useEffect(() => {
    if (user?.studentReferences?.classId) {
      setHasClass(true);
      if (!currentClassLoaded) {
        getCurrentClass(user?.studentReferences?.classId);
      }
    }
    if (!mineLoaded) {
      getMine();
    }
  }, [user]);
  if (classesError) return <p>{classesError}</p>;
  if (!user?.id) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader
        title={formatMessage(
          { id: 'hello-user' },
          { name: user?.displayName },
        )}
      />

      {!hasClass && <JoinClassPage />}
      {currentClass && (
      <CardList header={formatMessage({ id: 'your-subject' })}>
        <Card
          key={currentClass.id}
          header={`${currentClass.subject?.subjectCode} (${currentClass.period?.name})`}
          body={currentClass.subject?.name}
          onClick={() => history.push('/student/forms')}
        />
      </CardList>
      )}
      { mine && (
        mine.map(_class => (
          <>
            <div className="flex w-full justify-center mt-8">
              <Button onClick={() => ShowStudentPdf(_class.id, user.id)} iconLeft={HiPrinter}>
                {`${_class?.subject?.subjectCode} (${_class?.period?.name})`}
              </Button>
              <Button className="ml-8" onClick={() => ShowSigningFormPdf(_class.id, user.id)} iconLeft={HiPrinter}>
                <FormattedMessage id="signing-form-header" />
              </Button>
            </div>
          </>
        ))
      )}
    </>
  );
};

export default StudentClasses;
