import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchAllUsersInClass, fetchClassById, setSupervisorForStudentInClass } from 'src/api/classAPI';
import ApiError from 'src/api/errorHandling/ApiError';
import { fetchSigningFormByClassAndStudent } from 'src/api/signingFormAPI';
import { fetchStudentPassWarning } from 'src/api/studentPassWarningAPI';
import { fetchUserById } from 'src/api/userAPI';
import { Class, SimpleUser } from 'src/models/classes';
import SigningForm from 'src/models/SigningForm';
import StudentPassWarning from 'src/models/StudentPassWarning';
import { User } from 'src/models/user';

interface StudentPageQuery {
  class: Class;
  student: User;
  supervisor?: User;
  teacher?: User;
  warning?: StudentPassWarning;
  signingForm?: SigningForm;
}

export function useStudentPageQuery(classId: number, studentUserId: number) {
  return useQuery<StudentPageQuery, ApiError>({
    queryKey: ['useStudentPageQuery', classId, studentUserId],
    queryFn: async () => {
      const [classData, student, warning, signingForm] = await Promise.all([
        fetchClassById(classId),
        fetchUserById(studentUserId),
        fetchWarning(classId, studentUserId),
        fetchSigningForm(classId, studentUserId),
      ]);

      const supervisorId = student.studentReferences?.advisorUserId;
      const teacherId = student.studentReferences?.teacherUserId;

      const [supervisor, teacher] = await Promise.all([
        supervisorId ? fetchUserById(supervisorId) : undefined,
        teacherId ? fetchUserById(teacherId) : undefined,
      ]);

      return { class: classData,
        student,
        supervisor,
        teacher,
        warning,
        signingForm };
    },
  });
}

async function fetchWarning(classId: number, studentUserId: number) {
  try {
    return await fetchStudentPassWarning({ classId, studentUserId });
  } catch (error) {
    const apiError = error as ApiError;
    if (apiError.statusCode !== 404) throw error;
  }
  return undefined;
}

async function fetchSigningForm(classId: number, studentUserId: number) {
  try {
    return await fetchSigningFormByClassAndStudent(classId, studentUserId);
  } catch (error) {
    const apiError = error as ApiError;
    if (apiError.statusCode !== 404) throw error;
  }
  return undefined;
}

interface SupervisorSelectQuery {
  supervisor?: User;
  supervisorsInClass: SimpleUser[];
}

export function useSupervisorSelectQuery(classId: number, currentSupervisorId?: number) {
  return useQuery<SupervisorSelectQuery, ApiError>({
    queryKey: ['useSupervisorSelectQuery', classId, currentSupervisorId],
    queryFn: async () => {
      const [supervisorData, supervisorsInClassData] = await Promise.all([
        currentSupervisorId ? fetchUserById(currentSupervisorId) : undefined,
        fetchAllUsersInClass(classId),
      ]);

      return { supervisor: supervisorData, supervisorsInClass: supervisorsInClassData.advisors };
    },
  });
}

export function useSetSupervisorForStudentMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, { classId: number; studentUserId: number; supervisorUserId: number }>({
    mutationFn: ({ classId, studentUserId, supervisorUserId }) => setSupervisorForStudentInClass(classId, studentUserId, supervisorUserId),
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });
}
