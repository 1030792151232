/* eslint-disable max-classes-per-file */
import { QuestionType } from './form';

export class EditableForm {
  id: number | undefined;
  name: string | undefined;
  isMainForm: boolean = false;
  groups: EditableFormQuestionGroup[] = [];
}

export class EditableFormQuestionGroup {
  id?: number;
  formId?: number;
  name?: string;
  questions: EditableFormQuestion[] = [];
  type?: QuestionType;
  options?: any;
  order: number;

  constructor(order: number, name?: string) {
    this.order = order;
    this.name = name || 'Ny gruppe';
    this.type = QuestionType.Grading;
  }
}

export class EditableFormQuestion {
  id?: number;
  formQuestionGroupId?: number;
  text?: string;
  order: number;
  helpText?: string;

  constructor(order: number, text?: string) {
    this.order = order;
    this.text = text || 'Nytt spørsmål';
  }
}

export default EditableForm;
