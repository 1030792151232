import { FC, useEffect, useState } from 'react';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import { FormQuestion, QuestionType } from 'src/models/form';
import { RoleTypes } from 'src/models/roles';
import FormAnswers, { FormAnswerComment } from '../FormAnswers';
import FormQuestionDisagreements from './FormQuestionDisagreements/FormQuestionDisagreements';
import FormQuestionDropdown from './FormQuestionDropdown/FormQuestionDropdown';
import FormQuestionNotRelevant from './FormQuestionNotRelevant/FormQuestionNotRelevant';
import FormQuestionTypePicker from './FormQuestionTypePicker/FormQuestionTypePicker';
import FormQuestionHelp from './FormQuestionHelp/FormQuestionHelp';

export interface FormQuestionProps {
  question: FormQuestion,
  type: QuestionType,
  answers: FormAnswers | undefined,
  options?: any,
  midTermExpectations: FormAnswers | undefined,
  endTermExpectations: FormAnswers | undefined,
  studentGrades: FormAnswers | undefined,
  supervisorGrades: FormAnswers | undefined,
  teacherMidTermGrades: FormAnswers | undefined,
  teacherGrades: FormAnswers | undefined,
  isMidSemester: boolean,
  questionSchemeOrder: (groupOrder: number, questionOrder: number) => number,
  groupOrder: number,
  studentMidTermGrades: FormAnswers | undefined,
  supervisorMidTermGrades: FormAnswers | undefined,
  answersChangedEvent?: () => void,
  canAnswer: boolean,
  others: FormAnswers[] | undefined,
  othersMidTerm: FormAnswers[] | undefined,
}

const defaultLabels = ['Selvstendig', 'Delvis selvstendig', 'Veiledet', 'Assistert', 'Uselvstendig'];

export const FormQuestionComponent: FC<FormQuestionProps> = ({
  question,
  type,
  answers,
  options,
  midTermExpectations,
  endTermExpectations,
  teacherMidTermGrades,
  teacherGrades,
  studentGrades,
  supervisorGrades,
  isMidSemester,
  questionSchemeOrder,
  groupOrder,
  studentMidTermGrades,
  supervisorMidTermGrades,
  answersChangedEvent,
  canAnswer,
  others,
  othersMidTerm,
}: FormQuestionProps) => {
  const { activeRole } = LoggedInUserContext.useContainer();

  const findActiveColor = (role: RoleTypes, expected?: boolean) => {
    if (expected) return 'secondary';
    if (role === RoleTypes.Student) return 'student';
    if (role === RoleTypes.Admin) return 'secondary';
    if (role === RoleTypes.Advisor) return 'supervisor';
    if (role === RoleTypes.Teacher) return 'teacher';
    return '';
  };
  const activeColor = activeRole && findActiveColor(activeRole);

  const [answer, setAnswer] = useState<number | string | string[] | undefined>();
  const [disagreementComment, setDisagreementComment] = useState<string | undefined>();
  const [endTermExpectation, setEndTermExpectation] = useState<number | undefined>();
  const [midTermExpectation, setMidTermExpectation] = useState<number | undefined>();
  const [studentGrade, setStudentGrade] = useState<number | undefined>();
  const [supervisorGrade, setSupervisorGrade] = useState<number | undefined>();
  const [supervisorComment, setSupervisorComment] = useState<FormAnswerComment | undefined>();
  const [studentComment, setStudentComment] = useState<FormAnswerComment | undefined>();
  const [teacherComment, setTeacherComment] = useState<FormAnswerComment | undefined>();
  const [teacherGrade, setTeacherGrade] = useState<number | undefined>();
  const [teacherMidTermGrade, setTeacherMidTermGrade] = useState<number | undefined>();
  const [othersComments, setOthersComments] = useState<Array<FormAnswerComment | undefined> | undefined>();

  useEffect(() => {
    const newAnswer = answers?.getAnswer(question.id);
    if (newAnswer !== undefined) {
      handleSetAnswer(newAnswer);
    }
  }, [question, answers]);

  useEffect(() => {
    const newComment = answers?.getComment(question.id);
    setDisagreementComment(newComment?.comment);
  }, [question, answers]);

  useEffect(() => {
    const newSupervisorComment = supervisorGrades?.getComment(question.id);
    const grade = supervisorGrades?.getAnswer(question.id);
    if (grade !== undefined) {
      setSupervisorGrade(grade);
    }
    setSupervisorComment(newSupervisorComment);
  }, [question, supervisorGrades]);

  useEffect(() => {
    const exp = endTermExpectations?.getAnswer(question.id);
    if (exp !== undefined) {
      setEndTermExpectation(exp);
    }
  }, [question, endTermExpectations]);

  useEffect(() => {
    const exp = midTermExpectations?.getAnswer(question.id);
    if (exp !== undefined) {
      setMidTermExpectation(exp);
    }
  }, [question, midTermExpectations]);

  useEffect(() => {
    const grade = teacherMidTermGrades?.getAnswer(question.id);
    if (grade !== undefined) {
      setTeacherMidTermGrade(grade);
    }
  }, [question, teacherMidTermGrades]);

  useEffect(() => {
    const grade = studentGrades?.getAnswer(question.id);
    const comment = studentGrades?.getComment(question.id);
    if (grade !== undefined) {
      setStudentGrade(grade);
    }
    setStudentComment(comment);
  }, [question, studentGrades]);

  useEffect(() => {
    const grade = teacherGrades?.getAnswer(question.id);
    const comment = teacherGrades?.getComment(question.id);
    if (grade !== undefined) {
      setTeacherGrade(grade);
    }

    setTeacherComment(comment);
  }, [question, teacherGrades]);

  useEffect(() => {
    const comments = others?.map(c => c.getComment(question.id));
    if (comments !== undefined) {
      setOthersComments(comments);
    }
  }, [question, others]);

  const handleSetAnswer = (newAnswer: any) => {
    setAnswer(newAnswer);
  };

  useEffect(() => {
    const ans = type === QuestionType.TextInput ? disagreementComment : answer;
    answers?.answer(question.id, ans, undefined);
  }, [answer, disagreementComment]);

  useEffect(() => {
    answers?.comment(question.id, disagreementComment);
  }, [disagreementComment]);

  const labels = options?.labels || defaultLabels;

  const setNotRelevant = () => handleSetAnswer(labels.length);

  const notRelevantSelected = answer === labels.length;
  const notRelevantExpected = isMidSemester ? midTermExpectation === labels.length : endTermExpectation === labels.length;

  const disagreementBadgeText = () => {
    if (activeRole === RoleTypes.Advisor) return 'PV';
    if (activeRole === RoleTypes.Teacher) return 'PL';
    if (activeRole === RoleTypes.Student) return 'S';
    return '';
  };

  const parseQuestionText = (q: any) => {
    const text = q.text as string;
    const separateLines = text.split(/\r?\n|\r|\n/g);
    // eslint-disable-next-line react/no-array-index-key
    return separateLines.map((t, index) => <p key={index}>{t}</p>);
  };

  const getPreviousMidTermTeacherComment = () => {
    if (isMidSemester || !teacherMidTermGrades) return undefined;

    return teacherMidTermGrades.getComment(question.id);
  };

  const getPreviousMidTermSupervisorComment = () => {
    if (isMidSemester || !supervisorMidTermGrades) return undefined;

    return supervisorMidTermGrades.getComment(question.id);
  };

  const getPreviousMidTermStudentComment = () => {
    if (isMidSemester || !studentMidTermGrades) return undefined;

    return studentMidTermGrades.getComment(question.id);
  };

  const getOthersPreviousMidTermComments = () => {
    if (isMidSemester || !othersMidTerm) return undefined;

    return othersMidTerm.map(c => c.getComment(question.id));
  };

  return (
    <fieldset>
      <div className="mt-8 bg-white p-4 rounded-lg shadow-tableShadow">
        <div className="w-full text-darkgray flex justify-between">
          <p className="question mb-4 text-left">{`${questionSchemeOrder(groupOrder, question.order)}.`}</p>
          <FormQuestionNotRelevant
            activeColor={activeColor}
            notRelevantExpected={notRelevantExpected}
            notRelevantSelected={notRelevantSelected}
            supervisorGrade={supervisorGrade}
            studentGrade={studentGrade}
            notRelevantValue={labels.length}
            setNotRelevant={() => {
              setNotRelevant();
              if (answersChangedEvent) answersChangedEvent();
            }}
            activeRole={activeRole}
            answer={answer}
            teacherGrade={teacherGrade}
            showMidTermNotRelevant={teacherMidTermGrade === labels.length && !isMidSemester}
          />
          <div className="flex">
            <FormQuestionHelp question={question} />
            <FormQuestionDropdown
              setNotRelevant={() => {
                setNotRelevant();
                if (answersChangedEvent) answersChangedEvent();
              }}
              hideNotRelevant={notRelevantExpected || notRelevantSelected}
              questionTypeIsGrading={type === QuestionType.Grading}
            />
          </div>
        </div>
        <div className="question flex justify-between" data-testid="question">
          <div className="flex-wrap">
            <legend>
              {parseQuestionText(question)}
            </legend>
          </div>
          {type === QuestionType.TextInput && disagreementComment && <div className="text-darkergray text-sm">{`${disagreementComment?.length || 0}/${2000}`}</div>}
        </div>
        <FormQuestionTypePicker
          labels={labels}
          type={type}
          activeColor={activeColor}
          supervisorGrade={supervisorGrade}
          studentGrade={studentGrade}
          teacherGrade={teacherGrade}
          currentSemesterExpectation={isMidSemester ? midTermExpectation : endTermExpectation}
          otherSemesterExpectation={isMidSemester ? endTermExpectation : midTermExpectation}
          answer={answer}
          setAnswer={(newAnswer: any) => {
            handleSetAnswer(newAnswer);
            if (answersChangedEvent) answersChangedEvent();
          }}
          teacherMidTermGrade={teacherMidTermGrade}
          activeRole={activeRole}
          isMidSemester={isMidSemester}
          canAnswer={canAnswer}
        />
        <FormQuestionDisagreements
          showNewDisagreement
          changeDisagreementComment={value => {
            if (value && value.length > 2000) return;
            setDisagreementComment(value);
          }}
          supervisorComment={supervisorComment}
          studentComment={studentComment}
          teacherComment={teacherComment}
          activeColor={activeColor}
          disagreementComment={disagreementComment}
          disagreementBadgeText={disagreementBadgeText()}
          noTypePicker={type === QuestionType.TextInput}
          isMidSemester={isMidSemester}
          teacherMidTermComment={getPreviousMidTermTeacherComment()}
          studentMidTermComment={getPreviousMidTermStudentComment()}
          supervisorMidTermComment={getPreviousMidTermSupervisorComment()}
          othersComments={othersComments}
          othersMidTermComments={getOthersPreviousMidTermComments()}
          onBlur={() => {
            if (answersChangedEvent) answersChangedEvent();
          }}
        />
      </div>
    </fieldset>
  );
};

export default FormQuestionComponent;
