import { BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs';
import { FormattedMessage } from 'react-intl';
import UserBadge from 'src/components/_common/Badge/UserBadge';
import SigningForm from 'src/models/SigningForm';

const SignedByStatus = ({ signingForm }: {signingForm: SigningForm}) => {
  let signedCount = 0;
  if (signingForm.signedByAdvisorUserId) signedCount += 1;
  if (signingForm.signedByStudentUserId) signedCount += 1;
  if (signingForm.signedByTeacherUserId) signedCount += 1;

  const allSigned = signedCount === 3;
  const teacherSigned = signingForm.signedByTeacherUserId;
  const advisorSigned = signingForm.signedByAdvisorUserId;
  const studentSigned = signingForm.signedByStudentUserId;

  function anyoneSigned() {
    return teacherSigned != null || advisorSigned != null || studentSigned != null;
  }

  return (
    <>
      {anyoneSigned() && (
      <div className="flex justify-between items-center">
        <div className="mt-2">
          <FormattedMessage id="signedByStatusLabel" />
        </div>
        <div className="text-primary mr-2">{teacherSigned ? <UserBadge id={teacherSigned} type="teacher" /> : null}</div>
        <div className="text-primary mr-2">{advisorSigned ? <UserBadge id={advisorSigned} type="supervisor" /> : null}</div>
        <div className="text-primary mr-2">{studentSigned ? <UserBadge id={studentSigned} type="student" /> : null}</div>
      </div>
      )}
    </>
  );
};

export default SignedByStatus;
