import { Modal, ModalHeader, ModalBody, ModalFooter } from '@windmill/react-ui';
import { FormEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'src/components/_common/Button/Button';
import SubjectsContext from 'src/hooks/useSubjects';
import LabeledInput from '../../../../components/_common/LabeledInput/LabeledInput';

interface SubjectModalProps {
  isOpen: boolean;
  close: () => void;
  onSubjectCreated?: () => void;
  onSubjectUpdated?: () => void;
}

const SubjectModal = ({ isOpen, close, onSubjectCreated, onSubjectUpdated }: SubjectModalProps) => {
  const { setSubject, subject, createNewSubject, updateSubject, isSaving } = SubjectsContext.useContainer();

  const { formatMessage } = useIntl();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (subject?.id) {
      await updateSubject();
      onSubjectUpdated?.();
    } else if (subject?.name) {
      await createNewSubject();
      onSubjectCreated?.();
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={close} style={{ margin: 'auto' }}>
      <form onSubmit={e => handleSubmit(e)}>
        <ModalHeader>
          {subject
            ? <FormattedMessage id="edit-subject" />
            : <FormattedMessage id="new-subject" />}
        </ModalHeader>
        <ModalBody>
          <LabeledInput
            required
            labelText={formatMessage({ id: 'subject-code' })}
            defaultValue={subject?.subjectCode}
            onValueChangeHandler={e => setSubject({ ...subject, subjectCode: e })}
          />
          <br />
          <LabeledInput
            required
            labelText={formatMessage({ id: 'name' })}
            defaultValue={subject?.name}
            onValueChangeHandler={e => setSubject({ ...subject, name: e })}
          />
        </ModalBody>
        <ModalFooter>
          <Button className="w-full sm:w-auto" layout="outline" onClick={close}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button type="submit" className="w-full sm:w-auto" isSaving={isSaving} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SubjectModal;
