import { useState } from 'react';
import Select from 'src/components/_common/Select/Select';

interface MobileTableProps {
  items: { header: string; values: string[] }[];
}

export default function MobileTable({ items }: MobileTableProps) {
  const [selectedLeft, setSelectedLeft] = useState(items[0].header);
  const [selectedRight, setSelectedRight] = useState(items[1].header);

  const selectedItemLeft = items.find(item => item.header === selectedLeft);
  const selectedItemRight = items.find(item => item.header === selectedRight);

  return (
    <>
      <div className="flex space-x-2 items-center">
        <div className="w-1/2" data-testid="select-left">
          <Select
            label=""
            options={items.map(item => ({ value: item.header, label: item.header }))}
            value={{ value: selectedLeft, label: selectedLeft }}
            onChange={item => {
              if (!item) {
                setSelectedLeft('');
                return;
              }
              const { value } = item as any;
              setSelectedLeft(value ?? '');
            }}
          />
        </div>
        <div className="w-1/2" data-testid="select-right">
          <Select
            label=""
            options={items.map(item => ({ value: item.header, label: item.header }))}
            value={{ value: selectedRight, label: selectedRight }}
            onChange={item => {
              if (!item) {
                setSelectedRight('');
                return;
              }
              const { value } = item as any;
              setSelectedRight(value ?? '');
            }}
          />
        </div>
      </div>
      <table className="border border-lightGray w-full table-fixed">
        <colgroup>
          <col style={{ width: '50%' }} />
          <col style={{ width: '50%' }} />
        </colgroup>
        <tbody>
          {items[0].values.map((itemValue, index) => (
            <tr className={`${index % 2 === 0 ? 'bg-white' : 'bg-background'}`} key={itemValue}>
              <td className="p-4 text-sm border-lighterGray" style={{ verticalAlign: 'top', borderRightWidth: 2 }}>
                {selectedItemLeft && selectedItemLeft.values[index]}
              </td>
              <td className="p-4 text-sm" style={{ verticalAlign: 'top' }}>
                {selectedItemRight && selectedItemRight.values[index]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
