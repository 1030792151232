import { RoleTypes } from 'src/models/roles';
import SigningForm from 'src/models/SigningForm';
import { User } from 'src/models/user';

export function endsemesterFormsActive(signingForm: SigningForm) {
  return signingForm.midSemesterCompletedDate && signingForm.midSemesterCompletedDate <= new Date();
}

export function midsemesterFormIsDisabled(signingForm: SigningForm, loggedInUser: User) {
  return signingForm.isLocked || !isTeacher(loggedInUser) || signingForm.midSemesterCompletedDate != null || signedByStudent(signingForm) || signedBySupervisor(signingForm);
}

export function endsemesterFormIsDisabled(signingForm: SigningForm, loggedInUser: User) {
  return signingForm.isLocked || !isTeacher(loggedInUser) || signingForm.midSemesterCompletedDate == null;
}

export function signedByLoggedInUser(signingForm: SigningForm, loggedInUser: User) {
  if (isTeacher(loggedInUser) && signedByLoggedInTeacher(signingForm, loggedInUser)) return true;
  if (isSupervisor(loggedInUser) && signedByLoggedInSupervisor(signingForm, loggedInUser)) return true;
  if (isStudent(loggedInUser) && signedByLoggedInStudent(signingForm, loggedInUser)) return true;
  return false;
}

export function signedByAll(signingForm: SigningForm) {
  return signedByTeacher(signingForm) && signedBySupervisor(signingForm) && signedByStudent(signingForm);
}

export function loggedInUserCanSign(signingForm: SigningForm, loggedInUser: User) {
  if (signedByLoggedInUser(signingForm, loggedInUser)) return false;
  if (signingForm.isLocked) return false;
  if (!isTeacher(loggedInUser) && !isStudent(loggedInUser) && !isSupervisor(loggedInUser)) return false;

  return true;
}

export function showSignButton(signingForm: SigningForm, loggedInUser: User) {
  if (signingForm.isLocked) return false;
  if (signedByLoggedInUser(signingForm, loggedInUser)) return false;
  return true;
}

export function teacherWaitingForOtherSignatures(signingForm: SigningForm, loggedInUser: User) {
  if (!isTeacher(loggedInUser)) return false;
  if (!signedBySupervisor(signingForm)) return true;
  if (!signedByStudent(signingForm)) return true;
  return false;
}

export function noOneSigned(signingForm: SigningForm) {
  return !(signedByStudent(signingForm) || signedBySupervisor(signingForm) || signedByTeacher(signingForm));
}

export function showSaveButton(signingForm: SigningForm, loggedInUser: User) {
  if (signingForm.isLocked) return false;
  if (!isTeacher(loggedInUser)) return false;
  return true;
}

export function signingIsUnavailable(signingForm: SigningForm, loggedInUser: User): boolean | (() => boolean) {
  return (isTeacher(loggedInUser) && signingForm.midSemesterCompletedDate === null) || ((signingForm.midSemesterCompletedDate === null || signingForm.endSemesterCompletedDate === null) && !isTeacher(loggedInUser));
}

export function getDefaultSigningForm(student: User): SigningForm {
  return {
    id: -1,
    studentReferencesId: student.studentReferences?.id ?? -1,
    attendancePassed: null,
    workRequirementsPassed: null,
    specificCompetenceAreasPassed: null,
    generalCompetenceAreasPassed: null,
    teacherComment: null,
    overallPassed: null,
    isLocked: false,
    endSemesterCompletedDate: null,
    midSemesterCompletedDate: null,
    midSemesterSpecificCompetenceAreasBegun: null,
    midSemesterStudentHasGivenFeedback: null,
    signedByAdvisorDate: null,
    signedByAdvisorUserId: null,
    signedByStudentDate: null,
    signedByStudentUserId: null,
    signedByTeacherDate: null,
    signedByTeacherUserId: null,
  };
}

export const isTeacher = (user: User) => user.roles.some(r => r.name === RoleTypes.Teacher);
const isSupervisor = (user: User) => user.roles.some(r => r.name === RoleTypes.Advisor);
const isStudent = (user: User) => user.roles.some(r => r.name === RoleTypes.Student);

const signedByTeacher = (signingForm: SigningForm) => !!signingForm.signedByTeacherUserId;
const signedBySupervisor = (signingForm: SigningForm) => !!signingForm.signedByAdvisorUserId;
const signedByStudent = (signingForm: SigningForm) => !!signingForm.signedByStudentUserId;
const signedByLoggedInTeacher = (signingForm: SigningForm, loggedInUser: User) => signingForm.signedByTeacherUserId === loggedInUser.id && !!signingForm.signedByTeacherUserId;
const signedByLoggedInSupervisor = (signingForm: SigningForm, loggedInUser: User) => signingForm.signedByAdvisorUserId === loggedInUser.id && !!signingForm.signedByAdvisorUserId;
const signedByLoggedInStudent = (signingForm: SigningForm, loggedInUser: User) => signingForm.signedByStudentUserId === loggedInUser.id && !!signingForm.signedByStudentUserId;
