import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { Windmill } from '@windmill/react-ui';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import App from './App';
import vidTheme from './style/VidTheme';

ReactDOM.render(
  <Windmill theme={vidTheme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Windmill>,
  // eslint-disable-next-line comma-dangle
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
