import { Modal, ModalBody, ModalHeader } from '@windmill/react-ui';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import Select from 'src/components/_common/Select/Select';
import { LanguageContext } from 'src/context/LanguageContext';

interface SettingsModalProps {
  isOpen: boolean;
  close: () => void;
}

const SettingsModal = ({ isOpen, close }: SettingsModalProps) => {
  const langContext = useContext(LanguageContext);
  const { formatMessage } = useIntl();

  const changeLanguage = (e: any) => {
    langContext?.changeLanguage(e.value);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions
    <div onMouseDown={e => e.stopPropagation()}>
      <Modal isOpen={isOpen} onClose={close} style={{ margin: 'auto' }}>
        <ModalHeader>{formatMessage({ id: 'settings' })}</ModalHeader>
        <ModalBody>
          {langContext && (
            <Select
              label={formatMessage({ id: 'languages' })}
              onChange={changeLanguage}
              value={{
                value: langContext.lang,
                label: formatMessage({ id: langContext.lang }),
              }}
              options={[
                {
                  value: 'en',
                  label: formatMessage({ id: 'en' }),
                },
                {
                  value: 'no',
                  label: formatMessage({ id: 'no' }),
                },
              ]}
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SettingsModal;
