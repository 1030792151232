import { useIntl, FormattedMessage } from 'react-intl';
import Card from 'src/components/_common/Card/Card';
import CardList from 'src/components/_common/CardList/CardList';
import Spinner from 'src/components/_common/Spinner/Spinner';
import ClassesContext from 'src/hooks/useClasses';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { sortClassesBySubjectCodeComparer } from 'src/utils/classUtils';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import OtherSubjectsSection from './OtherSubjectsSection';

const TeacherClasses = () => {
  const { classes, classesLoaded, getClasses } = ClassesContext.useContainer();
  const { user } = LoggedInUserContext.useContainer();
  const { formatMessage } = useIntl();
  const history = useHistory();

  useEffect(() => {
    getClasses();
  }, []);

  if (!classesLoaded || !user?.id || classes === undefined) return <Spinner />;

  const teachersClasses = classes.filter(c => user.teacherClasses?.includes(c.id)).sort(sortClassesBySubjectCodeComparer);
  const otherClasses = classes.filter(c => !teachersClasses.includes(c)).sort(sortClassesBySubjectCodeComparer);

  return (
    <>
      <PageHeader title={formatMessage(
        { id: 'hello-user' },
        { name: user?.displayName },
      )}
      />
      <CardList header={formatMessage({ id: 'your-subjects' })}>
        {teachersClasses?.map(_class => (
          <Card
            key={_class?.id}
            header={`${_class?.subject?.subjectCode} (${_class?.period?.name})`}
            body={_class?.subject?.name}
            onClick={() => history.push(`/teacher/class/${_class?.id}`)}
          />
        ))}
        {(teachersClasses?.length === 0 || !teachersClasses) && (
          <span>
            <FormattedMessage id="no-classes-add-description" />
          </span>
        )}
      </CardList>
      <CardList header={formatMessage({ id: 'other-subjects' })}>
        <OtherSubjectsSection classes={otherClasses} />
      </CardList>
    </>
  );
};

export default TeacherClasses;
