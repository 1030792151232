import { HiPencil } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import Badge from 'src/components/_common/Badge/Badge';
import useUser from 'src/hooks/user/useUser';

interface SupervisorBadgeProps {
  id: number,
  onEditClick: () => void
}

export default function SupervisorBadge({ id, onEditClick }: SupervisorBadgeProps) {
  const { formatMessage } = useIntl();
  const supervisorQuery = useUser(id);

  if (supervisorQuery.isLoading) return null;
  if (supervisorQuery.isError) {
    const message = formatMessage({ id: 'formListPageInviteSupervisorBadgeErrorMessage' });
    return <ApiErrorAlert error={supervisorQuery.error} message={message} />;
  }

  const supervisor = supervisorQuery.data;

  return (
    <Badge type="supervisor" onClick={onEditClick}>
      {supervisor.displayName}
      <HiPencil className="ml-1 text-base" />
    </Badge>
  );
}
