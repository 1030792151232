import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Card from 'src/components/_common/Card/Card';
import SigningForm from 'src/models/SigningForm';
import SignedByStatus from './SignedByStatus';

interface SigningFormSectionProps {
  signingForm?: SigningForm;
  showCreateButton?: boolean;
}

export default function SigningFormSection({ signingForm, showCreateButton }: SigningFormSectionProps) {
  const { formatMessage } = useIntl();
  const history = useHistory();

  function navigateToSigningForm() {
    history.push(`${history.location.pathname}/signingform`);
  }

  if (signingForm) {
    return (
      <Card
        className="mt-8"
        primary={false}
        onClick={navigateToSigningForm}
        header={formatMessage({ id: 'signing-form-header' })}
        body={<SignedByStatus signingForm={signingForm} />}
      />
    );
  }

  if (showCreateButton) {
    return (
      <div className="justify-center flex mt-8">
        <button
          aria-label={formatMessage({ id: 'create-signing-form' })}
          onClick={navigateToSigningForm}
          className="text-black bg-white py-2 px-4 rounded-full shadow-tableShadow"
          data-testid="create-signing-form-button"
        >
          <FormattedMessage id="create-signing-form" />
        </button>
      </div>
    );
  }

  return null;
}
