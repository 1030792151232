import Norwegian from '../languages/languages.nb-no.json';
import English from '../languages/languages.gb-en.json';
import VidNorwegian from '../languages/languages.vid.nb-no.json';
import VidEnglish from '../languages/languages.vid.gb-en.json';
import UsnNorwegian from '../languages/languages.usn.nb-no.json';
import UsnEnglish from '../languages/languages.usn.gb-en.json';

// Add new languages here as required
export default (locale: string) => {
  const specific = getSpecific(locale);
  const lang = locale === 'en' ? English : Norwegian;

  return { ...lang, ...specific };
};

function getSpecific(locale: string) {
  let orgNameShort = process.env.REACT_APP_ORG_NAME_SHORT as string;
  const isEnglish = locale === 'en';
  if (!orgNameShort) orgNameShort = 'VID';
  switch (orgNameShort.toLowerCase()) {
    case 'usn':
      return isEnglish ? UsnEnglish : UsnNorwegian;
    case 'vid':
      return isEnglish ? VidEnglish : VidNorwegian;
    default:
      throw new Error('MISSING LANGUAGE FILES.');
  }
}
