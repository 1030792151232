import { useParams, useHistory } from 'react-router-dom';
import Spinner from 'src/components/_common/Spinner/Spinner';
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import ClassFormsTable from 'src/components/ClassFormsTable/ClassFormsTable';
import ClassDates from 'src/components/ClassDates/ClassDates';
import { sortSimpleUserByDisplayNameComparer } from 'src/utils/userUtils';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Badge from 'src/components/_common/Badge/Badge';
import List from 'src/components/_common/List/List';
import ListItem from 'src/components/_common/List/ListItem';
import ClassesContext from '../../../../hooks/useClasses';

const AdminClass = () => {
  const { getCurrentClass, currentClass, currentClassLoaded, usersForClass, getUsersForClass } = ClassesContext.useContainer();
  const { classId } = useParams<{ classId: string }>();
  const history = useHistory();
  const { formatMessage } = useIntl();

  useEffect(() => {
    getCurrentClass(+classId);
    getUsersForClass(+classId);
  }, []);

  if (!currentClassLoaded) return <Spinner />;

  const teachers = usersForClass?.teachers.sort(sortSimpleUserByDisplayNameComparer) ?? [];
  const students = usersForClass?.students.sort(sortSimpleUserByDisplayNameComparer) ?? [];
  const supervisors = usersForClass?.advisors.sort(sortSimpleUserByDisplayNameComparer) ?? [];

  return (
    <>
      <PageHeader title={currentClass?.subject?.subjectCode} subTitle={currentClass?.subject?.name}>
        <Badge>{currentClass?.period?.name}</Badge>
      </PageHeader>
      <ClassDates />
      <ClassFormsTable baseRoute="/admin" currentClass={currentClass} subjectCode={currentClass?.subject?.subjectCode} canAddAssignment />

      <List
        title={formatMessage({ id: 'teachers' })}
        className="pt-8"
        emptyListMessage={formatMessage({ id: 'table-none-added' }, { name: formatMessage({ id: 'teachers' }).toLowerCase() })}
        listIsEmpty={teachers.length === 0}
      >
        {teachers.map(teacher => (
          <ListItem key={teacher.id} onClick={() => history.push(`/admin/users/${teacher.id}`)}>
            {teacher.displayName}
          </ListItem>
        ))}
      </List>

      <List
        title={formatMessage({ id: 'students' })}
        className="pt-8"
        emptyListMessage={formatMessage({ id: 'table-none-added' }, { name: formatMessage({ id: 'students' }).toLowerCase() })}
        listIsEmpty={students.length === 0}
      >
        {students.map(student => (
          <ListItem key={student.id} onClick={() => history.push(`/admin/users/${student.id}`)}>
            {student.displayName}
          </ListItem>
        ))}
      </List>

      <List
        title={formatMessage({ id: 'supervisors' })}
        className="pt-8"
        emptyListMessage={formatMessage({ id: 'table-none-added' }, { name: formatMessage({ id: 'supervisors' }).toLowerCase() })}
        listIsEmpty={supervisors.length === 0}
      >
        {supervisors.map(supervisor => (
          <ListItem key={supervisor.id} onClick={() => history.push(`/admin/class/${classId}/advisor/${supervisor.id}`)}>
            {supervisor.displayName}
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default AdminClass;
