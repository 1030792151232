import FeideGroupMembership from 'src/models/FeideGroupMembership';
import FeideUserInfo from 'src/models/FeideUserInfo';
import api from './api';
import defaultErrorConverter from './errorHandling/defaultErrorConverter';

export interface VippsSignInParameters {
  code: string;
  redirectUri: string;
}

export async function vippsSignIn(params: VippsSignInParameters) {
  try {
    const requestData = {
      code: params.code,
      redirectUri: params.redirectUri,
    };
    const res = await api.post('api/auth/vipps/signed-in', requestData);
    const token = res.data.data.token as string;
    return token;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function sendOneTimePassword(email: string) {
  try {
    await api.post('api/auth/external/sign-in', { email });
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function simpleSignIn(email: string) {
  try {
    await api.post('api/auth/simple/sign-in', { email });
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export interface VerifyOneTimePasswordParameters {
  email: string;
  code: string;
}

export async function verifyOneTimePassword({ email, code }: VerifyOneTimePasswordParameters) {
  try {
    const res = await api.post('api/auth/external/signed-in', { email, password: code });
    const token = res.data.data.token as string;
    return token;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchFeideUserInfo() {
  try {
    const res = await api.get('api/auth/feide-info');
    return res.data.data as FeideUserInfo;
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function fetchFeideGroupMemberships() {
  try {
    const res = await api.get('api/auth/feide-group-memberships');
    return res.data.data as FeideGroupMembership[];
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}
