export default {

  // Button
  button: {
    primary: {
      base: 'text-white bg-primary border border-transparent font-semibold text-base rounded-full',
      active: 'active:bg-primary hover:bg-primary focus:bg-primary',
      disabled: 'opacity-50 cursor-not-allowed',
    },
    outline: {
      base: 'text-gray-600 border-gray-300 border dark:text-gray-400 focus:outline-none',
      active:
      'active:bg-transparent hover:border-0 focus:border-0  focus:ring-0',
      disabled: 'opacity-50 cursor-not-allowed bg-gray-300',
    },
    link: {
      base: 'text-gray-600 dark:text-gray-400 focus:outline-none border border-transparent',
      active:
      'active:bg-transparent active:bg-transparent hover:border-0 focus:border-0  focus:ring-0',
      disabled: 'opacity-50 cursor-not-allowed',
    },
    danger: {
      base: 'text-white bg-danger hover:bg-danger border border-transparent',
      active: 'text-white active:bg-danger hover:bg-danger focus:bg-danger',
    },
  },
  input: {
    base: 'block w-full text-sm focus:outline-none text-black leading-5 rounded-md border-lightGray',
    active: 'text-black form-radio p-3 ',
    radio: 'text-primary form-radio p-3 border-lightGray border-2',
    checkbox:
    'form-checkbox rounded mr-2 text-primary border-lightGray border-2',

  },
  tableBody: {
    base:
      'bg-white divide-y divide-lightGray text-darkergray max-w-full',
  },
  tableContainer: {
    base: 'max-w-full overflow-hidden rounded-lg ring-0 ring-background ring-opacity-0 shadow-tableShadow windmill-table',
  },
  tableRow: {
    base: '',
  },
  tableCell: {
    base: 'pl-4 py-3',
  },
  label: {
    base: 'text-gray',
  },
  modal: {
    base:
      'w-full px-6 py-4 bg-white rounded-t-lg dark:bg-gray-800 sm:rounded-lg sm:m-4 sm:max-w-xl',
  },
  backdrop: {
    base:
      'fixed inset-0 z-40 flex items-end bg-black overflow-y-scroll bg-opacity-50 sm:items-center sm:justify-center',
  },
  badge: {
    neutral: 'text-white',
  },
  textarea: {
    active: '',
  },
};
