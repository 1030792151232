import { Alert } from '@windmill/react-ui';
import ToastsContext from 'src/hooks/useToasts';

const Toast = () => {
  const { showToast, errorToast, setShowToast, deleteToast, toastMessage } = ToastsContext.useContainer();

  function getToastMessage() {
    if (toastMessage) return toastMessage;

    if (errorToast) {
      return `${errorToast?.statusCode} ${errorToast?.errors?.map(err => err.description)}`;
    }
    if (deleteToast) return 'Slettet!';
    return 'Lagret!';
  }

  return (
    showToast
      ? (
        <Alert type={errorToast ? 'danger' : 'success'} className="sticky bottom-8 w-1/2 m-auto z-50" onClose={() => setShowToast(false)} data-testid="toast-alert">
          {getToastMessage()}
        </Alert>
      )
      : <></>
  );
};

export default Toast;
