import { Button } from '@windmill/react-ui';
import { ReactNode } from 'react';
import { GrAddCircle } from 'react-icons/gr';
import { FormattedMessage, useIntl } from 'react-intl';
import ReorderableList from 'src/components/_common/ReorderableList/ReorderableList';
import Spinner from 'src/components/_common/Spinner/Spinner';
import FormContext from 'src/hooks/useFormContext';
import AdminFormGroup from './AdminFormGroup/AdminFormGroup';

const AdminFormGroupList = () => {
  const { form, addGroup, formLoaded, setGroupName, removeGroup, changeQuestionType } = FormContext.useContainer();
  const { formatMessage } = useIntl();

  const { groups } = form;

  function getHeaderText(order: number): string {
    return groups.find(x => x?.order === order)!?.name!;
  }

  function getContent(order: number): ReactNode {
    const group = groups.find(x => x.order === order);
    if (typeof group === 'undefined') {
      return null;
    }
    return (<AdminFormGroup key={group.id} group={group} changeQuestionType={changeQuestionType} />);
  }

  function getHeaderSubtitle(order: number): ReactNode {
    const groupIndex = groups.findIndex(x => x.order === order)!;
    return (<div><FormattedMessage id="category" values={{ category: groupIndex + 1 }} /></div>);
  }
  if (!formLoaded) return <Spinner />;

  return (
    <>
      <div className="flex flex-col gap-5">
        <ReorderableList
          onDelete={removeGroup}
          border={false}
          openButtonLocation="right"
          headerSubTitle={getHeaderSubtitle}
          items={groups}
          headerText={getHeaderText}
          setHeaderText={setGroupName}
          itemChild={getContent}
          inputStyle="font-h2"
        />
      </div>
      <div className="w-full my-5 h-8 text-center">
        <Button aria-label={formatMessage({ id: 'createGroup' })} layout="link" size="larger" onClick={addGroup} icon={GrAddCircle} className="mx-auto" />
      </div>
    </>
  );
};

export default AdminFormGroupList;
