import { FormattedMessage } from 'react-intl';
import { Button as WindmillButton } from '@windmill/react-ui';
import { ButtonProps } from '@windmill/react-ui/dist/Button';
import { ImSpinner } from 'react-icons/im';
import { ReactNode } from 'react';

type MyButtonProps = ButtonProps & {
  isSaving?: boolean,
  text?: string | ReactNode | ReactNode[],
  children?: ReactNode | ReactNode[],
  ariaLabel?: string,
};

const Button = ({ isSaving, text, children, ariaLabel, ...props }: MyButtonProps) => (
  <WindmillButton {...props} icon={props.icon || (isSaving && '') || undefined} disabled={isSaving || props.disabled} aria-label={ariaLabel} className={`${props.className} rounded-full`}>
    {isSaving
      ? <ImSpinner data-testid="spinner" className="spinner-animation" />
      : (text || children || (!props.icon && <FormattedMessage id="save" />))}
  </WindmillButton>
);

export default Button;
