import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { RoleTypes } from 'src/models/roles';
import { globalKeyDownHandler } from 'src/utils/keyDownhandler';
import FormBadges from '../../FormBadges/FormBadges';

interface FormQuestionNotRelevantProps {
  supervisorGrade?: number,
  studentGrade?: number,
  teacherGrade?: number,
  notRelevantExpected?: boolean,
  notRelevantSelected?: boolean,
  activeColor?: string,
  notRelevantValue?: number,
  setNotRelevant: () => void,
  activeRole?: RoleTypes,
  showMidTermNotRelevant?: boolean,
  answer?: string | number | string[]
}

const FormQuestionNotRelevant = ({
  notRelevantSelected,
  supervisorGrade,
  studentGrade,
  teacherGrade,
  notRelevantExpected,
  activeColor,
  notRelevantValue,
  setNotRelevant,
  activeRole,
  showMidTermNotRelevant,
  answer,
}: FormQuestionNotRelevantProps) => (
  <div className="flex flex-wrap justify-center">
    {(notRelevantSelected || notRelevantExpected || showMidTermNotRelevant || notRelevantValue === (supervisorGrade || teacherGrade || studentGrade))
      && (
        <div className={classNames({ 'text-secondary': notRelevantExpected && !notRelevantSelected }, (notRelevantSelected) ? `text-${activeColor}Text font-bold` : '', 'w-full text-center')}>
          {notRelevantSelected
            ? <FormattedMessage id="not-relevant" />
            : (
              <div
                role="button"
                tabIndex={0}
                onKeyDown={e => globalKeyDownHandler(e, setNotRelevant)}
                onClick={setNotRelevant}
              >
                <FormattedMessage id="not-relevant" />
              </div>
            )}
          {showMidTermNotRelevant && <div className="text-darkgray w-full">M</div>}
        </div>
      )}
    <FormBadges
      currentValue={notRelevantValue}
      supervisorGrade={supervisorGrade}
      studentGrade={studentGrade}
      teacherGrade={teacherGrade}
      activeRole={activeRole}
      answer={answer}
    />
  </div>
);
export default FormQuestionNotRelevant;
