import React, { FC, ReactNode } from 'react';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import ToastsContext from 'src/hooks/useToasts';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import FormAnswerHandlerService from './FormAnswerHandler/FormAnswerHandlerService';
import HttpClientService from './HttpClient/HttpClientService';
import SessionStorageService from './SessionStorage/SessionStorageService';
import AuthenticatorService from './Authenticator/AuthenticatorService';
import TokenProviderService from './TokenProvider/TokenProviderService';

export interface ContextProps {
  children: React.ReactChild | React.ReactChild[] | ReactNode;
}

const GlobalServices: FC<ContextProps> = ({ children }: ContextProps) => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <SessionStorageService>
        <ToastsContext.Provider>
          <TokenProviderService>
            <HttpClientService>
              <LoggedInUserContext.Provider>
                <AuthenticatorService>
                  <FormAnswerHandlerService>
                    {children}
                  </FormAnswerHandlerService>
                </AuthenticatorService>
              </LoggedInUserContext.Provider>
            </HttpClientService>
          </TokenProviderService>
        </ToastsContext.Provider>
      </SessionStorageService>
    </QueryClientProvider>
  );
};
export default GlobalServices;
