import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import Card from 'src/components/_common/Card/Card';
import CardList from 'src/components/_common/CardList/CardList';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Spinner from 'src/components/_common/Spinner/Spinner';
import ClassesContext from 'src/hooks/useClasses';
import { Class } from 'src/models/classes';
import { sortClassesBySubjectCodeComparer } from 'src/utils/classUtils';
import SemestersContext from '../../../../../hooks/useSemesters';
import AddClassToSemesterModal from './AddClassToSemesterModal/AddClassToSemesterModal';
import DownloadCsvButton from './DownloadCsvButton';

interface AdminSemesterParams {
  semesterId: string;
}

const AdminSemester = () => {
  const { classes, classesLoaded, findClassesInSemester, getClasses } = ClassesContext.useContainer();
  const { semestersLoaded, findSemester } = SemestersContext.useContainer();
  const { semesterId } = useParams<AdminSemesterParams>();
  const [open, setOpen] = useState<boolean>(false);

  const history = useHistory();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!classesLoaded) getClasses();
  }, []);

  const semester = findSemester(+semesterId);
  if (!semestersLoaded || !classesLoaded || !semester || !classes) return <Spinner />;

  const classesInSemester = findClassesInSemester(+semesterId);

  return (
    <>
      <PageHeader title={semester.name} subTitle={formatMessage({ id: 'list-of-semesters' }, { name: semester.name })} />
      <CardList header={formatMessage({ id: 'subjects' })} addButtonOnClick={() => setOpen(true)} addButtonAriaLabel={formatMessage({ id: 'add-subject-to-semester' })}>
        {classesInSemester?.sort(sortClassesBySubjectCodeComparer)
          .map((classInSemester: Class) => (
            <Card
              key={classInSemester.id}
              onClick={() => history.push(`/admin/class/${classInSemester.id}`)}
              header={classInSemester?.subject?.subjectCode}
              body={classInSemester?.subject?.name}
            />
          ))}
        {classesInSemester?.length === 0 && (
          <div className="text-customGray font-normal text-base text-center">
            {`${formatMessage({ id: 'table-none-added' }, { name: formatMessage({ id: 'subjects' }).toLowerCase() })}${formatMessage({ id: 'add-with-plus' })}`}
          </div>
        )}
      </CardList>
      {open
        && (
          <AddClassToSemesterModal
            isOpen={open}
            onClose={() => {
              setOpen(false);
              getClasses();
            }}
            semester={semester}
          />
        )}
      {semester && <DownloadCsvButton semester={semester} />}
    </>
  );
};

export default AdminSemester;
