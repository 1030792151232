import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SigningFormPage from 'src/components/SigningForm/SigningFormPage';
import PrivacyPolicyPage from '../Main/PrivacyPolicy/PrivacyPolicyPage';
import JoinClassPage from './JoinClass/JoinClassPage';
import RedirectToJoinClassPage from './RedirectToJoinClassPage';
import FormPage from './StudentForms/FormPage/FormPage';
import StudentForms from './StudentForms/FormListPage/FormListPage';
import StudentWarning from './StudentWarning/StudentWarning';
import InviteSupervisorPage from './InviteSupervisor/InviteSupervisorPage';
import StudentClasses from './StudentClasses/StudentClasses';

export const StudentRouter: FC = () => (
  <>
    <RedirectToJoinClassPage />
    <Switch>
      <Route path="/student/:studentUserId/form/:formId/class/:classId/assignment/:assignmentId">
        <FormPage />
        <StudentWarning />
      </Route>
      <Route exact path="/student">
        <StudentClasses />
      </Route>
      <Route exact path="/student/forms">
        <StudentForms />
        <StudentWarning />
      </Route>
      <Route path="/student/class/:classId/student/:studentUserId/signingform">
        <SigningFormPage />
      </Route>
      <Route path="/privacy-policy">
        <PrivacyPolicyPage />
      </Route>
      <Route path="/student/join-class">
        <JoinClassPage />
      </Route>
      <Route path="/student/supervisor/invite">
        <InviteSupervisorPage />
      </Route>
      <Redirect from="*" to="/student" />
    </Switch>
  </>
);

export default StudentRouter;
