import Contextualizer from 'src/context/Contextualizer/Contextualizer';
import ProvidedServices from 'src/context/Contextualizer/ProvidedServices';
import EditableForm from 'src/models/editableForm';
import { Form } from 'src/models/form';
import { HttpResponse } from 'src/context/HttpClientContext';

export interface IFormProvider {
  getAllForms: () => Promise<Form[]>;
  getForm: (id: number) => Promise<Form | undefined>;
  createForm: (form: EditableForm) => Promise<number | undefined>;
  updateForm: (id: number, form: EditableForm) => Promise<void | HttpResponse<any>>;
  deleteForm: (id: number) => Promise<void | HttpResponse<any>>
}

export const FormProviderContext = Contextualizer.createContext<IFormProvider>(ProvidedServices.FormProvider);
export const useFormProvider = () => Contextualizer.use<IFormProvider>(ProvidedServices.FormProvider);
