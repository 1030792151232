import ApiError from 'src/api/errorHandling/ApiError';
import { fetchWarningPdf } from 'src/api/pdfAPI';

export default async function ShowWarningPdf(classId: number, studentUserId: number) {
  const data = await fetchWarningPdf({ classId: +classId, studentUserId: +studentUserId })
    .catch(e => {
      const apiError = e as ApiError;
      if (apiError.statusCode === 404) return null;
      throw apiError;
    });

  if (data) {
    window.open(window.URL.createObjectURL(data as Blob));
  }
}
