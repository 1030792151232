import React from 'react';
import { useHttpClient, HttpResponse } from 'src/context/HttpClientContext';
import { IAuthenticator, AuthenticatorContext } from 'src/context/AuthenticatorContext';
import { LoginDetails, UserResponse } from 'src/models/user';
import { useTokenProvider } from 'src/context/TokenProviderContext';
import { AxiosError } from 'axios';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import { ContextProps } from '../GlobalServices';

const root = process.env.REACT_APP_API_ENDPOINT;
const environment = process.env.REACT_APP_ENVIRONMENT;

const AuthenticatorService: React.FC<ContextProps> = ({ children }: ContextProps) => {
  const url = 'api/auth/external';
  const feideUrl = 'api/auth/signed-in';
  const tokenProvider = useTokenProvider();
  const httpClient = useHttpClient();
  const { handleSetUser } = LoggedInUserContext.useContainer();

  const authenticator: IAuthenticator = {
    getOTP: async (email: string) => attemptGetOTP(email).catch(error => handleAuthenticateFail(error)),
    authenticate: async (details: LoginDetails) => attemptAuthenticate(details).catch(error => handleAuthenticateFail(error)),
    feideAuth: async (code: string) => attemptFeideAuth(code).catch(error => handleAuthenticateFail(error)),
    refreshToken: async () => attemptRefreshToken().catch(error => handleAuthenticateFail(error)),
  };

  const attemptGetOTP = async (email: string) => httpClient.authPost<UserResponse>({ url: `${root}/${url}/sign-in`, email });

  const attemptAuthenticate = async (details: LoginDetails) => {
    const result = await httpClient.authPost<UserResponse>({ url: `${root}/${url}/signed-in`, ...details });
    return handleAuthResponse(result);
  };

  const attemptFeideAuth = async (code: string) => {
    const result = await httpClient.authPost<UserResponse>({ url: `${root}/${feideUrl}`, code, redirectToLocal: environment === 'local' });
    return handleAuthResponse(result);
  };

  const handleAuthenticateFail = (error: any): HttpResponse <{error: AxiosError}> => {
    const httpResponse: HttpResponse<{error: AxiosError}> = {
      statusCode: error?.status,
      data: error?.data,
      errors: error?.data?.errors,
    };
    console.log('Auth refresh failed, logging out.');
    tokenProvider.cancelToken();
    return httpResponse;
  };

  const attemptRefreshToken = async () => {
    const token = tokenProvider.getToken();
    if (token) {
      const result = await httpClient.get<UserResponse>({ url: 'api/auth/refresh' });
      return handleAuthResponse(result);
    }
    return false;
  };

  const handleAuthResponse = (result: HttpResponse<any>) => {
    const receivedToken = result.data?.token;
    if (!receivedToken || !result.data) {
      return false;
    }
    tokenProvider.setToken(receivedToken);
    handleSetUser(result.data.user);
    return true;
  };

  return (
    <AuthenticatorContext.Provider value={authenticator}>
      {children}
    </AuthenticatorContext.Provider>
  );
};

export default AuthenticatorService;
