import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Select from 'src/components/_common/Select/Select';
import ClassesContext from 'src/hooks/useClasses';
import { SimpleUser } from 'src/models/classes';
import { User } from 'src/models/user';

interface SupervisorSelectProps {
  isStudent: boolean;
  selectedSupervisor: SimpleUser | undefined;
  setSelectedSupervisor: (newSupervisor: SimpleUser | undefined) => void;
  classId?: number;
  user: User;
}

interface SelectOption {
  label: string;
  value: string;
}

export default function SupervisorSelect({ isStudent, selectedSupervisor, setSelectedSupervisor, classId, user }: SupervisorSelectProps) {
  const { formatMessage } = useIntl();
  const { usersForClass, getUsersForClass } = ClassesContext.useContainer();

  useEffect(() => {
    if (classId) {
      getUsersForClass(classId);
    }
  }, [classId]);

  useEffect(() => {
    if (!usersForClass || !selectedSupervisor) return;

    const selectedSupervisorExistsInClass = usersForClass.advisors.some(s => s.id === selectedSupervisor.id);

    if (!selectedSupervisorExistsInClass) {
      setSelectedSupervisor(undefined);
    }
  }, [usersForClass, selectedSupervisor]);

  useEffect(() => {
    if (isStudent && user && user.studentReferences && user.studentReferences.advisorUserId && usersForClass) {
      const supervisorId = user.studentReferences.advisorUserId;
      const supervisor = usersForClass.advisors.find(t => t.id === supervisorId);
      if (!supervisor) return;
      setSelectedSupervisor(supervisor);
    }
  }, [user, isStudent, usersForClass]);

  const convertSupervisorToSelectOption = (_supervisor: SimpleUser): SelectOption => ({ value: _supervisor.id.toString(), label: _supervisor.displayName });

  const handleChange = (supervisorOption: SelectOption | undefined) => {
    if (supervisorOption === undefined) {
      setSelectedSupervisor(undefined);
      return;
    }

    const allSupervisors = usersForClass?.advisors;
    if (!allSupervisors) return;

    const supervisor = allSupervisors.find(s => s.id.toString() === supervisorOption.value);
    setSelectedSupervisor(supervisor);
  };

  if (!isStudent || !classId) return null;

  return (
    <Select
      name="supervisor"
      label={formatMessage({ id: 'supervisor' })}
      value={selectedSupervisor ? convertSupervisorToSelectOption(selectedSupervisor) : undefined}
      onChange={value => {
        if (!value) {
          handleChange(undefined);
          return;
        }
        const valueOption: SelectOption = value as any;
        handleChange(valueOption);
      }}
      options={usersForClass?.advisors.map(convertSupervisorToSelectOption)}
      isLoading={!usersForClass}
      className="w-full"
      isClearable
    />
  );
}
