import { Input, Label } from '@windmill/react-ui';
import React, { useState } from 'react';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import Button from 'src/components/_common/Button/Button';
import ToastsContext from 'src/hooks/useToasts';
import useSendSupervisorInvitation from './queries';

interface InvitationFormProps {
  supervisorExists: boolean;
}

export default function InvitationForm({ supervisorExists }: InvitationFormProps) {
  const { formatMessage } = useIntl();
  const [email, setEmail] = useState('');
  const sendInvitationMutation = useSendSupervisorInvitation(handleSendInvitationSuccess);
  const { showSuccessToastWithMessage } = ToastsContext.useContainer();
  const history = useHistory();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    sendInvitationMutation.mutate(email);
  }

  function handleSendInvitationSuccess() {
    const message = formatMessage({ id: 'supervisorInvitationSentToastMessage' });
    showSuccessToastWithMessage(message);
    navigateBack();
  }

  function getEmailLabelText() {
    return supervisorExists
      ? formatMessage({ id: 'supervisorInvitationFormEmailLabelSupervisorExists' })
      : formatMessage({ id: 'supervisorInvitationFormEmailLabelSupervisorDoesNotExists' });
  }

  function getEmailHelpText() {
    return supervisorExists
      ? formatMessage({ id: 'supervisorInvitationFormEmailHelpTextSupervisorExists' })
      : formatMessage({ id: 'supervisorInvitationFormEmailHelpTextSupervisorDoesNotExists' });
  }

  function navigateBack() {
    history.push('/student');
  }

  return (
    <div className="flex justify-center">
      <form onSubmit={handleSubmit} className="max-w-xl">
        <Label>
          <h3 className="mb-0">{getEmailLabelText()}</h3>
          <Input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            css=""
            placeholder={formatMessage({ id: 'email' })}
            required
            data-testid="invitation-form-email-input"
          />
          <div className="flex text-darkgray mt-2">
            <div>
              <HiOutlineInformationCircle className="mr-1 relative" style={{ top: 4 }} />
            </div>
            <div>
              {getEmailHelpText()}
            </div>
          </div>
        </Label>
        {sendInvitationMutation.isError && (
          <ApiErrorAlert
            error={sendInvitationMutation.error}
            message={formatMessage({ id: 'supervisorInvitationFormErrorMessage' })}
          />
        )}
        <div className="flex justify-end mt-4">
          <Button layout="outline" onClick={navigateBack}>{formatMessage({ id: 'cancel' })}</Button>
          <Button
            type="submit"
            className="ml-2"
            disabled={sendInvitationMutation.isLoading}
            isSaving={sendInvitationMutation.isLoading}
            data-testid="invitation-form-submit-button"
          >
            {formatMessage({ id: 'supervisorInvitationFormSubmitButtonText' })}
          </Button>
        </div>
      </form>
    </div>
  );
}
