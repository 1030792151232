import EditableInput from 'src/components/_common/EditableInput/EditableInput';
import { useState } from 'react';
import { MdEdit } from 'react-icons/md';
import { useIntl } from 'react-intl';

interface AdminFormEditorDetailsProps {
  setFormName: (name: string) => void;
  formName: string
}

const AdminFormEditorDetails = ({ setFormName, formName }: AdminFormEditorDetailsProps) => {
  const [active, setActive] = useState<boolean>(!formName);
  const { formatMessage } = useIntl();

  return (
    <div className="flex items-center w-full justify-between">
      <EditableInput
        text={formName}
        onChangeHandler={setFormName}
        inputStyle="font-h1 leading-none"
        notActiveComponent={value => <h1 className="">{value}</h1>}
        active={active}
        ariaLabel={formatMessage({ id: 'formName' })}
      />
      <button onClick={() => setActive(!active)} data-testid="edit-form-button" aria-label={formatMessage({ id: 'editFormName' })}>
        <MdEdit className="text-2xl" />
      </button>
    </div>
  );
};
export default AdminFormEditorDetails;
