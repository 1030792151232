import { useState } from 'react';
import { FormQuestion } from 'src/models/form';
import HelpButton from './HelpButton';
import HelpTextModal from './HelpTextModal';

interface FormQuestionHelpProps {
  question: FormQuestion;
}

export default function FormQuestionHelp({ question }: FormQuestionHelpProps) {
  const [helpModalIsOpen, setHelpModalIsOpen] = useState(false);

  const openModal = () => setHelpModalIsOpen(true);
  const closeModal = () => setHelpModalIsOpen(false);

  return (
    <>
      <HelpButton helpText={question.helpText} onClick={openModal} />
      <HelpTextModal isOpen={helpModalIsOpen} close={closeModal} question={question} />
    </>
  );
}
