import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import ListHeader from './ListHeader';

interface ListProps {
  title?: string;
  onTitleClick?: () => void;
  titleButtonAriaLabel?: string;
  children?: ReactNode | ReactNode[],
  emptyListMessage?: string,
  listIsEmpty?: boolean,
  className?: string,
}

export default function List({ title, onTitleClick, titleButtonAriaLabel, emptyListMessage, listIsEmpty, children, className }: ListProps) {
  const { formatMessage } = useIntl();

  return (
    <div className={className}>
      {title && (<ListHeader title={title} onTitleClick={onTitleClick} titleButtonAriaLabel={titleButtonAriaLabel} />)}
      <ul className="bg-white divide-y rounded-lg ring-background ring-opacity-0 shadow-tableShadow">
        {children}
      </ul>
      {listIsEmpty && (
        <div className="text-customGray font-normal text-base text-center">
          {emptyListMessage || formatMessage({ id: 'emptyListDefaultMessage' })}
        </div>
      )}
    </div>
  );
}
