import React, { useState, useEffect } from 'react';

// eslint-disable-next-line
export const useLanguage = () => {
  const [lang, setLang] = useState<any>('no');

  useEffect(() => {
    setLang(window.localStorage.getItem('language') || 'no');
  }, []);

  useEffect(() => {
    document.querySelector('html')?.setAttribute('lang', lang);
  }, [lang]);

  const changeLanguage = (language: string) => {
    setLang(language);
    window.localStorage.setItem('language', language);
  };
  return { lang, changeLanguage };
};
