import { Label } from '@windmill/react-ui';
import ReactSelect, { Props, OptionTypeBase, GroupTypeBase } from 'react-select';
import classnames from 'classnames';
import { useRef } from 'react';

interface SelectProps<
  OptionType extends OptionTypeBase,
  GroupType extends GroupTypeBase<OptionType>
> extends Props<OptionType, true, GroupType> {
  label: string;
  danger?: boolean;
  wrapperClassName?: string;
  required?: boolean;
}

export default function Select<
  OptionType extends OptionTypeBase,
  GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(props: SelectProps<OptionType, GroupType>) {
  const { label, isMulti = undefined, danger, wrapperClassName, value, required, ...rest } = props;
  const selectRef = useRef<ReactSelect<OptionType, true, GroupType>>(null);

  return (
    <Label className={classnames(wrapperClassName)}>
      <span className={classnames({ 'text-danger': danger })}>{label}</span>
      <ReactSelect<OptionType, true, GroupType>
        {...rest}
        label={label}
        isMulti={isMulti}
        value={value}
        styles={{
          control: (provided: any) => {
            const minHeight = '46px';
            return { ...provided, minHeight };
          },
          placeholder: () => ({}),
          singleValue: () => ({}),
          multiValue: (base, state) => (state.data.isFixed ? { ...base, backgroundColor: 'gray' } : base),
          multiValueLabel: (base, state) => (state.data.isFixed
            ? { ...base, fontWeight: 'bold', color: 'white', paddingRight: 6 }
            : base),
          multiValueRemove: (base, state) => (state.data.isFixed ? { ...base, display: 'none' } : base),
        }}
        ref={selectRef}
      />
      {required && (
        <input
          value={value ? value.toString() : ''}
          required
          tabIndex={-1}
          autoComplete="off"
          style={{
            opacity: 0,
            width: 1,
            height: 1,
            position: 'absolute',
          }}
          onChange={() => { }}
          onFocus={() => selectRef.current?.focus()}
        />
      )}
    </Label>
  );
}
