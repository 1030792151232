import { useRef, useEffect } from 'react';
import { Textarea as WindmillTextarea } from '@windmill/react-ui';
import { TextareaProps } from '@windmill/react-ui/dist/Textarea';

interface CustomTextareaProps extends TextareaProps {
  ariaLabel?: string;
}

const Textarea = (props: CustomTextareaProps) => {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { ariaLabel } = props;

  useEffect(() => {
    if (textareaRef && textareaRef.current) {
      textareaRef.current.style.height = '0px';
      const { scrollHeight } = textareaRef.current;
      textareaRef.current.style.height = `${scrollHeight}px`;
    }
  }, []);

  return (
    <>
      <WindmillTextarea
        css={undefined}
        ref={textareaRef}
        aria-label={ariaLabel}
        {...props}
      />
    </>
  );
};

export default Textarea;
