import { useMutation } from '@tanstack/react-query';
import ApiError from 'src/api/errorHandling/ApiError';
import { sendSupervisorInvitation } from 'src/api/invitationAPI';

export default function useSendSupervisorInvitation(onSuccess: () => void) {
  return useMutation<void, ApiError, string>({
    mutationFn: sendSupervisorInvitation,
    onSuccess,
  });
}
