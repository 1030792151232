import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Spinner from 'src/components/_common/Spinner/Spinner';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import { useIntl } from 'react-intl';
import ResponsAlert from 'src/components/_common/Alert/ResponsAlert';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import { fetchMyUser } from 'src/api/userAPI';
import useVippsSignIn from './queries';
import validateVippsResponse from './utils';

export default function VippsSuccessPage() {
  const { formatMessage } = useIntl();

  const location = useLocation();
  const history = useHistory();
  const { search } = location;
  const urlParams = new URLSearchParams(search);
  const { code } = Object.fromEntries(urlParams);
  const { handleSetUser } = LoggedInUserContext.useContainer();
  const verificationGuid = localStorage.getItem('verificationGuid');

  const mutation = useVippsSignIn(onSuccess);
  const { error: validationError, errorDetails: validationErrorDetails } = validateVippsResponse(search);

  useEffect(() => {
    if (!validationError) mutation.mutate({ code, redirectUri: process.env.REACT_APP_VIPPS_REDIRECT_URI ?? '' });
  }, [validationError]);

  async function onSuccess(token: string) {
    sessionStorage.setItem('token', JSON.stringify(token));
    handleSetUser(await fetchMyUser());

    if (verificationGuid) {
      history.push(`/supervisor/accept-invitation/?verificationGuid=${verificationGuid}`);
      localStorage.removeItem('verificationGuid');
    } else window.location.href = '/';
  }

  if (validationError) {
    return (
      <ResponsAlert
        type="danger"
        title={formatMessage({ id: 'apiErrorDefaultTitle' })}
        message={(
          <div>
            <p className="mb-2">{formatMessage({ id: 'authLoginErrorMessage' })}</p>
            {validationErrorDetails && (
              <p>{formatMessage({ id: 'apiErrorDetailsLabel' })}: {validationErrorDetails}</p>
            )}
          </div>
        )}
      />
    );
  }

  if (mutation.isError) {
    return (
      <ApiErrorAlert
        error={mutation.error}
        message={formatMessage({ id: 'authLoginErrorMessage' })}
      />
    );
  }

  if (mutation.isLoading) {
    return (
      <div data-testid="loading-container"><Spinner /></div>
    );
  }

  return (
    <div data-testid="redirect-loading-container"><Spinner /></div>
  );
}
