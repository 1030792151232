import { useIntl } from 'react-intl';
import Select from 'src/components/_common/Select/Select';
import RolesContext from 'src/hooks/useRoles';
import { Roles } from 'src/models/roles';

interface RoleSelectProps {
  selectedRoles: Roles[];
  setSelectedRoles: (roles: Roles[]) => void;
}

interface SelectOption {
  value: string;
  label: string;
}

export default function RoleSelect({ selectedRoles, setSelectedRoles }: RoleSelectProps) {
  const { formatMessage } = useIntl();

  const { roles: allRoles, rolesLoaded: allRolesLoaded } = RolesContext.useContainer();

  const convertRoleToSelectOption = (role: Roles): SelectOption => ({ value: role.id.toString(), label: role.name });

  const handleChange = (newRoleOptions: SelectOption[]) => {
    const newRoles = newRoleOptions
      .map(option => allRoles.find(r => r.id.toString() === option.value))
      .filter(option => option !== undefined) as Roles[];

    setSelectedRoles(newRoles);
  };

  return (
    <Select
      label={formatMessage({ id: 'role' })}
      isLoading={!allRolesLoaded}
      options={allRoles.map(convertRoleToSelectOption)}
      value={selectedRoles.map(convertRoleToSelectOption)}
      onChange={values => handleChange([...values])}
      className="w-full"
      isMulti
      required
      getOptionLabel={option => formatMessage({ id: option.label })}
    />
  );
}
