import { useMutation, useQueryClient } from '@tanstack/react-query';
import ApiError from 'src/api/errorHandling/ApiError';
import { createStudentPassWarning, CreateStudentPassWarningParameters, deleteStudentPassWarning, submitStudentPassWarning, SubmitStudentPassWarningParameters } from 'src/api/studentPassWarningAPI';

export function useCreateStudentPassWarning(onSuccess: () => void) {
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, CreateStudentPassWarningParameters>({
    mutationFn: createStudentPassWarning,
    onSuccess: () => {
      onSuccess();
    },
  });
}

export function useSubmitStudentPassWarning(onSuccess: () => void) {
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, SubmitStudentPassWarningParameters>({
    mutationFn: submitStudentPassWarning,
    onSuccess: () => {
      queryClient.invalidateQueries();
      onSuccess();
    },
  });
}

export function useDeleteStudentPassWarningMutation(onSuccess: () => void) {
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, { classId: number; studentUserId: number }>({
    mutationFn: ({ classId, studentUserId }) => deleteStudentPassWarning({ classId, studentUserId }),
    onSuccess: () => {
      queryClient.invalidateQueries();
      onSuccess();
    },
  });
}
