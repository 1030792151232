/* eslint-disable react/no-danger */
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTokenProvider } from 'src/context/TokenProviderContext';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';

const COMPONENT_ID = 'privacy-policy-modal';

export default function PrivacyPolicyModal() {
  const { acceptedPrivacyPolicy, handlePrivacyPolicyClick } = LoggedInUserContext.useContainer();
  const { formatMessage } = useIntl();
  const { getToken } = useTokenProvider();

  useEffect(() => {
    function onParentClickHandler(e: MouseEvent) {
      const element = e.target as Element;
      const clickedOnParent = element?.children[0]?.id === COMPONENT_ID;
      if (clickedOnParent) {
        e.stopPropagation();
      }
    }

    document.getElementById(COMPONENT_ID)?.parentElement?.addEventListener('click', onParentClickHandler);
    const header = document.getElementById(COMPONENT_ID)?.parentElement?.querySelector('header');
    if (header) header.style.display = 'none';

    return () => document.getElementById(COMPONENT_ID)?.parentElement?.removeEventListener('click', onParentClickHandler);
  }, [acceptedPrivacyPolicy]);

  function accept() {
    handlePrivacyPolicyClick(true);
  }

  function cancel() {
    handlePrivacyPolicyClick(false);
  }

  function getPrivacyPolicyHtml() {
    return formatMessage({ id: 'privacy-policy-body' });
  }

  const userIsLoggedIn = !!getToken();
  if (!userIsLoggedIn) return null;

  return (
    <Modal isOpen={!acceptedPrivacyPolicy} onClose={() => {}} style={{ margin: 'auto' }} id={COMPONENT_ID}>
      <ModalHeader>
        <FormattedMessage id="privacy-policy" />
      </ModalHeader>
      <ModalBody>
        <div className="flex-wrap whitespace-pre-wrap privacy-policy" dangerouslySetInnerHTML={{ __html: getPrivacyPolicyHtml() }} />
      </ModalBody>
      <ModalFooter>
        <Button className="w-full sm:w-auto" layout="outline" onClick={cancel}>
          <FormattedMessage id="cancel" />
        </Button>
        <Button type="button" className="w-full sm:w-auto" onClick={accept}>
          <FormattedMessage id="accept" />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
