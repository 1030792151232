import { useMutation } from '@tanstack/react-query';
import { vippsSignIn, VippsSignInParameters } from 'src/api/authAPI';
import ApiError from 'src/api/errorHandling/ApiError';

export default function useVippsSignIn(onSuccess: (data: string) => void) {
  return useMutation<string, ApiError, VippsSignInParameters>({
    mutationFn: vippsSignIn,
    onSuccess,
  });
}
