import { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import MainPage from './pages/Main/MainPage/MainPage';
import GlobalServices from './services/GlobalServices';
import './App.scss';

const App: FC = () => (
  <GlobalServices>
    <div data-testid="app" className="App bg-background">
      <BrowserRouter>
        <div className="m-auto w-8/12 md:w-3/4 page-padding pb-8 main-wrapper">
          <MainPage />
        </div>
      </BrowserRouter>
    </div>
  </GlobalServices>
);

export default App;
