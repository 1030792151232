interface DesktopTableProps {
  items: { header: string; values: string[] }[];
}

export default function DesktopTable({ items }: DesktopTableProps) {
  return (
    <table
      className="border border-lightGray absolute max-w-screen-lg"
      style={{ left: '50%', transform: 'translate(-50%, 0)' }}
    >
      <thead className="bg-lightGray sticky" style={{ top: -1 }}>
        <tr>
          {items.map(item => (
            <th className="p-4 text-left" key={item.header}>{item.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items[0].values.map((itemValue, index) => (
          <tr className={`${index % 2 === 0 ? 'bg-white' : 'bg-background'}`} key={itemValue}>
            {items.map(item => (
              <td className="p-4 text-sm" style={{ verticalAlign: 'top' }} key={item.header}>{item.values[index]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
