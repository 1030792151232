import { Class } from 'src/models/classes';
import SigningForm from 'src/models/SigningForm';

export function getMainFormsInClass(_class: Class, signingForm?: SigningForm) {
  return getUniqueClassForms(_class).filter(_form => _form.isMainForm && (midSemesterHasPassed(signingForm) ? !_form.isMidSemester : _form.isMidSemester));
}

export function getNonMainFormsInClass(_class: Class) {
  return getUniqueClassForms(_class).filter(form => !form.isMainForm);
}

function midSemesterHasPassed(signingForm?: SigningForm) {
  if (signingForm && signingForm.midSemesterCompletedDate) {
    const midSemesterDate = new Date(signingForm.midSemesterCompletedDate);
    const today = new Date();
    return today > midSemesterDate;
  }
  return false;
}

function getUniqueClassForms(_class: Class) {
  return _class.assignments?.map(assignment => assignment.forms.map(_form => ({ ..._form, assignmentId: assignment.id, isMidSemester: assignment.isMidSemester }))).flat() ?? [];
}
