import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import Select from 'src/components/_common/Select/Select';
import ClassesContext from 'src/hooks/useClasses';
import { SimpleUser } from 'src/models/classes';
import { User } from 'src/models/user';

interface TeacherSelectProps {
  isStudent: boolean;
  selectedTeacher: SimpleUser | undefined;
  setSelectedTeacher: (newTeacher: SimpleUser | undefined) => void;
  classId?: number;
  user: User;
}

interface SelectOption {
  label: string;
  value: string;
}

export default function TeacherSelect({ isStudent, selectedTeacher, setSelectedTeacher, classId, user }: TeacherSelectProps) {
  const { formatMessage } = useIntl();
  const { usersForClass, getUsersForClass } = ClassesContext.useContainer();

  useEffect(() => {
    if (classId) {
      getUsersForClass(classId);
    }
  }, [classId]);

  useEffect(() => {
    if (!usersForClass || !selectedTeacher) return;

    const selectedTeacherExistsInClass = usersForClass.teachers.some(s => s.id === selectedTeacher.id);

    if (!selectedTeacherExistsInClass) {
      setSelectedTeacher(undefined);
    }
  }, [usersForClass, selectedTeacher]);

  useEffect(() => {
    if (isStudent && user && user.studentReferences && user.studentReferences.teacherUserId && usersForClass) {
      const teacherId = user.studentReferences.teacherUserId;
      const teacher = usersForClass.teachers.find(t => t.id === teacherId);
      if (!teacher) return;
      setSelectedTeacher(teacher);
    }
  }, [user, isStudent, usersForClass]);

  const convertTeacherToSelectOption = (_teacher: SimpleUser): SelectOption => ({ value: _teacher.id.toString(), label: _teacher.displayName });

  const handleChange = (teacherOption: SelectOption | undefined) => {
    if (teacherOption === undefined) {
      setSelectedTeacher(undefined);
      return;
    }

    const allTeachers = usersForClass?.teachers;
    if (!allTeachers) return;

    const teacher = allTeachers.find(t => t.id.toString() === teacherOption.value);
    setSelectedTeacher(teacher);
  };

  if (!isStudent || !classId) return null;

  return (
    <Select
      name="teacher"
      label={formatMessage({ id: 'teacher' })}
      value={selectedTeacher ? convertTeacherToSelectOption(selectedTeacher) : undefined}
      onChange={value => {
        if (!value) {
          handleChange(undefined);
          return;
        }
        const valueOption: SelectOption = value as any;
        handleChange(valueOption);
      }}
      options={usersForClass?.teachers.map(convertTeacherToSelectOption)}
      isLoading={!usersForClass}
      className="w-full"
      isClearable

    />
  );
}
