import { Modal, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import { FormattedMessage } from 'react-intl';
import Button from '../_common/Button/Button';

interface ConfirmSignModalProps {
  isOpen: boolean;
  close: () => void;
  confirm: () => void;
  teacherWaitingForOtherSignatures: boolean;
}

export default function ConfirmSignModal({ isOpen, close, confirm, teacherWaitingForOtherSignatures }: ConfirmSignModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalHeader>
        <FormattedMessage id="send-signing-form" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="cant-change-signing-form" />
        {teacherWaitingForOtherSignatures && (
          <div className="font-bold">
            <br />
            <FormattedMessage id="signingFormOthersNotSigned" />
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={close} layout="outline">
          <FormattedMessage id="cancel" />
        </Button>
        <Button onClick={() => {
          confirm();
          close();
        }}
        >
          <FormattedMessage id="sign" />
        </Button>
      </ModalFooter>
    </Modal>
  );
}
