import { ReactNode, useEffect, useState } from 'react';
import { MdExpandLess, MdExpandMore, MdDelete } from 'react-icons/md';
import { useIntl } from 'react-intl';
import Accordion, { OpenButtonLocation } from 'src/components/_common/ReorderableList/Accordion/Accordion';

export interface Reorderable {
  order: number;
}

export interface ReorderableListProps {
  items: Reorderable[];
  inputStyle?: string;
  headerText: (order: number) => string;
  setHeaderText: (order: number, text: string, item?: any) => void;
  headerSubTitle: (order: number) => ReactNode;
  itemChild: (order: number) => ReactNode;
  onDelete: (order: number) => void;
  openButtonLocation?: OpenButtonLocation
  border?: boolean
}

const ReorderableList = ({ items, headerText, setHeaderText, itemChild, headerSubTitle, openButtonLocation, border, inputStyle, onDelete }: ReorderableListProps) => {
  const [localItems, setLocalItems] = useState<Reorderable[]>([]);
  const { formatMessage } = useIntl();

  useEffect(() => {
    setLocalItems(items?.sort((a, b) => ((a.order || 0) > (b.order || 0) ? 1 : -1)));
  }, [items, localItems]);

  function moveUp(item: Reorderable) {
    const currentQuestion = item;
    const currentOrder = item.order;
    const currentIndex = localItems.indexOf(item);

    if (currentIndex === 0) { return; }
    const previousQuestion = localItems[currentIndex - 1];
    currentQuestion.order = previousQuestion.order;
    previousQuestion.order = currentOrder;
    setLocalItems(Object.assign([], localItems));
  }

  function moveDown(item: Reorderable) {
    const currentQuestion = item;
    const currentOrder = item.order;
    const currentIndex = localItems.indexOf(item);

    if (currentIndex === localItems.length - 1) { return; }
    const nextQuestion = localItems[currentIndex + 1];
    currentQuestion.order = nextQuestion.order;
    nextQuestion.order = currentOrder;
    setLocalItems(Object.assign([], localItems));
  }

  function deleteItem(item: Reorderable) {
    onDelete(item.order);
  }
  return (
    <>
      {localItems.length > 0 && localItems.map((item: Reorderable, index) => (
        <Accordion
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.order}--${index}`}
          text={headerText(item.order)}
          headerSubTitle={headerSubTitle(item.order)}
          openButtonLocation={openButtonLocation}
          border={border}
          inputStyle={inputStyle}
          changeHandler={newText => setHeaderText(item.order, newText)}
          icons={
            [
              { iconColor: 'text-gray-400', icon: MdExpandLess, onClick: () => { moveUp(item); }, label: formatMessage({ id: 'moveItemUp' }) },
              { iconColor: 'text-gray-400', icon: MdExpandMore, onClick: () => { moveDown(item); }, label: formatMessage({ id: 'moveItemDown' }) },
              { iconColor: 'text-gray-400', icon: MdDelete, onClick: () => { deleteItem(item); }, label: formatMessage({ id: 'delete' }) },
            ]
          }
        >
          {itemChild(item.order)}
        </Accordion>
      ))}
    </>
  );
};

export default ReorderableList;
