import { Button } from '@windmill/react-ui';
import classnames from 'classnames';
import { ReactNode } from 'react';
import { MdAddCircle } from 'react-icons/md';

interface CardListProps {
  children: ReactNode | ReactNode[],
  header?: string,
  addButtonOnClick?: () => void,
  addButtonAriaLabel?: string,
  className?: string
}

const CardList = ({ children, header, addButtonOnClick, className, addButtonAriaLabel }: CardListProps) => (
  <div className={classnames('h-max pb-8', className)} data-testid="card-list">
    {header && (
      <div className="flex justify-between mb-8">
        <h2 className="my-0 self-center">{header}</h2>
        {addButtonOnClick && (
          <>
            <Button data-testid="card-list-header-button" className="self-center pl-2 pr-6" layout="link" onClick={addButtonOnClick} aria-label={addButtonAriaLabel}>
              <MdAddCircle
                className="rounded-full bg-white text-primary"
                size="3em"
              />
            </Button>
          </>
        )}
      </div>
    )}
    {children}
  </div>
);
export default CardList;
