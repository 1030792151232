import { useEffect, useRef } from 'react';
import { HttpResponse } from 'src/context/HttpClientContext';
import ResponsAlert from '../Alert/ResponsAlert';

interface ErrorMessageProps extends HttpResponse<any>{
  className?: string
}

/**
 * @deprecated Use ApiErrorAlert instead
 */
const ErrorMessage = ({ statusCode, errors, className }: ErrorMessageProps) => {
  const htmlElRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (htmlElRef?.current?.focus) htmlElRef.current.focus();
  }, [statusCode, errors]);

  if (!errors || errors.length === 0) {
    return (
      <div data-testid="http-error">
        <ResponsAlert title="Error" message="En uventet feil oppsto" type="danger" />
      </div>
    );
  }

  return (
    <div data-testid="http-error">
      {errors.map(error => {
        const title = `Error${statusCode ? ` (HTTP ${statusCode})` : ''}`;
        const message = error.description;
        return <ResponsAlert title={title} message={message} type="danger" key={error.reason + error.description} />;
      })}
    </div>
  );
};

export default ErrorMessage;
