import Contextualizer from './Contextualizer/Contextualizer';
import ProvidedServices from './Contextualizer/ProvidedServices';

export interface ITokenProvider {
  getToken(): string | undefined;
  setToken(token: string): void;
  cancelToken(): void;
}

export const TokenProviderContext = Contextualizer.createContext<ITokenProvider>(ProvidedServices.TokenProvider);
export const useTokenProvider = () => Contextualizer.use<ITokenProvider>(ProvidedServices.TokenProvider);
