import { Alert } from '@windmill/react-ui';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import Button from 'src/components/_common/Button/Button';
import { useStudentPassWarningQuery } from './queries';
import StudentWarningModal from './StudentWarningModal';

const StudentWarning = () => {
  const [showModal, setShowModal] = useState(false);

  const query = useStudentPassWarningQuery();

  if (query.isLoading || !query.data) return null;
  if (query.isError) return <ApiErrorAlert error={query.error} message="" />;

  const { warning, class: _class, supervisor, teacher, student } = query.data;
  if (!warning || warning.studentConfirmedWarningAt) return null;

  return (
    <>
      <Alert type="warning" className="sticky bottom-8 text-center shadow-tableShadow max-w-md m-auto" data-testid="alert">
        <div>
          <FormattedMessage id="student-received-warning" />
        </div>
        <Button onClick={() => setShowModal(true)} className="bg-secondary w-full mt-4 text-customBrown" data-testid="read-warning-button">
          <FormattedMessage id="studentWarningModalReadWarningLabel" />
        </Button>
      </Alert>
      <StudentWarningModal
        isOpen={showModal}
        close={() => setShowModal(false)}
        warning={warning}
        class={_class}
        student={student}
        supervisor={supervisor}
        teacher={teacher}
      />
    </>
  );
};

export default StudentWarning;
