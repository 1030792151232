import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Class } from 'src/models/classes';
import { useHistory } from 'react-router-dom';
import { sortFormsByNameComparer } from 'src/utils/formUtils';
import AddClassFormModal from './AddClassFormModal/AddClassFormModal';
import List from '../_common/List/List';
import ListItem from '../_common/List/ListItem';

const ClassFormsTable = ({ subjectCode, currentClass, canAddAssignment, baseRoute }: { subjectCode?: string, currentClass?: Class, canAddAssignment?: boolean, baseRoute: string }) => {
  const { formatMessage } = useIntl();
  const [showAddAssignmentModal, setShowAddAssignmentModal] = useState<boolean>(false);

  const history = useHistory();
  const { location } = history;

  const midSemesterHasPassed = () => {
    if (currentClass?.midSemesterDate) {
      const midSemesterDate = new Date(currentClass?.midSemesterDate);
      const today = new Date();
      return today > midSemesterDate;
    }
    return true;
  };

  const allForms = currentClass?.assignments?.map(assignment => assignment.forms.map(form => ({ ...form, isMidSemester: assignment.isMidSemester, assignmentId: assignment.id, classId: currentClass.id }))).flat();

  const filteredForms = allForms?.filter(form => {
    if (form.isMainForm) {
      return midSemesterHasPassed() ? !form.isMidSemester : form.isMidSemester;
    }
    return true;
  });
  filteredForms?.sort(sortFormsByNameComparer);

  function handleFormClicked(form: {
    isMidSemester: boolean;
    assignmentId: number;
    classId: number;
    id: number;
    isMainForm: boolean;
    name: string;
  }) {
    if (isAdmin) {
      history.push({ pathname: `/admin/class/${form.classId}/form/${form.id}/assignment/${form.assignmentId}`, state: { pPath: location.pathname } });
    }
  }

  const isAdmin = baseRoute === '/admin';
  return (
    <>
      <div className="pt-8" />
      <List
        title={formatMessage({ id: 'respons-schemes' })}
        onTitleClick={canAddAssignment ? () => setShowAddAssignmentModal(true) : undefined}
        titleButtonAriaLabel={formatMessage({ id: 'addResponsPart' })}
        listIsEmpty={filteredForms?.length === 0}
        emptyListMessage={canAddAssignment ? formatMessage({ id: 'no-respons-schemes-admin' }, { subjectCode }) : formatMessage({ id: 'no-respons-schemes' }, { subjectCode })}
      >
        {filteredForms?.map(form => (
          <ListItem onClick={isAdmin ? () => handleFormClicked(form) : undefined} key={form.id}>
            {form.name}
          </ListItem>
        ))}
      </List>
      {showAddAssignmentModal
        && (
          <AddClassFormModal
            isOpen={showAddAssignmentModal}
            onClose={() => setShowAddAssignmentModal(false)}
            subjectCode={subjectCode}
            classForms={filteredForms}
          />
        )}
    </>
  );
};

export default ClassFormsTable;
