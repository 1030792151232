import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Card from 'src/components/_common/Card/Card';
import { Class, Period } from 'src/models/classes';
import { sortClassesBySubjectCodeComparer } from 'src/utils/classUtils';
import { sortPeriodsByStartDateComparer } from 'src/utils/periodUtils';

interface OtherSubjectsSectionProps {
  classes?: Class[];
}

const OtherSubjectsSection = ({ classes }: OtherSubjectsSectionProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const getAllPeriods = (_classes: Class[]) => {
    const result: Period[] = [];

    _classes.forEach(c => {
      const periodAdded = result.some(r => r.id === c.periodId);
      if (!periodAdded) {
        result.push(c.period!);
      }
    });

    return result;
  };

  if (!classes) {
    return (
      <div className="text-customGray font-normal text-base text-center">
        {formatMessage({ id: 'no-subjects' })}
      </div>
    );
  }

  return (
    <>
      {getAllPeriods(classes)
        .sort(sortPeriodsByStartDateComparer)
        .map(period => (
          <React.Fragment key={period.id}>
            <h3>{period.name}</h3>
            {classes
              .filter(c => c.periodId === period.id)
              .sort(sortClassesBySubjectCodeComparer)
              .map(_class => (
                <Card
                  key={_class?.id}
                  header={`${_class?.subject?.subjectCode} (${_class?.period?.name})`}
                  body={_class?.subject?.name}
                  onClick={() => history.push(`/teacher/class/${_class?.id}`)}
                  primary={false}
                />
              ))}
          </React.Fragment>
        ))}
    </>
  );
};

export default OtherSubjectsSection;
