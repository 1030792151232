import React from 'react';

const PageSubTitle: React.FC = ({ children }) => (
  <div className="m-auto mb-8">
    <p className="text-center text-darkgray">
      {children}
    </p>
  </div>
);

export default PageSubTitle;
