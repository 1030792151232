export interface Roles {
    id: number;
    name: RoleTypes
    }

export enum RoleTypes {
    Admin = 'Admin',
    Student = 'Student',
    Teacher = 'Teacher',
    Advisor = 'Advisor'
  }
