import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { fetchClassById } from 'src/api/classAPI';
import ApiError from 'src/api/errorHandling/ApiError';
import { cancelSigningForm, createSigningForm, fetchSigningFormByClassAndStudent, signSigningForm, updateSigningForm } from 'src/api/signingFormAPI';
import { fetchMyUser, fetchUserById } from 'src/api/userAPI';
import { Class } from 'src/models/classes';
import SigningForm from 'src/models/SigningForm';
import { User } from 'src/models/user';

interface SaveMutationParameters extends SigningFormReferenceParameters {
  signingForm: SigningForm;
}

export function useSaveSigningFormMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, SaveMutationParameters>(async ({ signingForm, classId, studentUserId }) => {
    if (signingForm.id > 0) {
      await updateSigningForm(classId, studentUserId, signingForm);
    } else {
      await createSigningForm(classId, studentUserId, signingForm);
    }
    queryClient.invalidateQueries(['SigningFormPage']);
  });
}

interface SigningFormReferenceParameters {
  classId: number;
  studentUserId: number;
}

export function useSignSigningFormMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, SigningFormReferenceParameters>(async ({ classId, studentUserId }) => {
    await signSigningForm(classId, studentUserId);
    queryClient.invalidateQueries(['SigningFormPage']);
  });
}

interface QueryResult {
  loggedInUser: User;
  class: Class;
  signingForm: SigningForm | null;
  student: User;
  supervisor?: User;
  teacher?: User;
}

export function useSigningFormPageQuery(classId: number, studentUserId: number) {
  return useQuery<QueryResult, ApiError>(['SigningFormPage', classId, studentUserId], async () => {
    const [loggedInUser, classResult, student] = await Promise.all([
      fetchMyUser(),
      fetchClassById(classId),
      fetchUserById(studentUserId),
    ]);

    let signingForm: SigningForm | null = null;
    try {
      signingForm = await fetchSigningFormByClassAndStudent(classId, studentUserId);
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.statusCode !== 404) throw apiError;
    }

    const supervisorId = student.studentReferences?.advisorUserId;
    const teacherId = student.studentReferences?.teacherUserId;

    const [supervisor, teacher] = await Promise.all([
      supervisorId ? fetchUserById(supervisorId) : undefined,
      teacherId ? fetchUserById(teacherId) : undefined,
    ]);

    return {
      loggedInUser,
      class: classResult,
      signingForm,
      student,
      supervisor,
      teacher,
    };
  });
}

export function useCancelSigningFormMutation() {
  const queryClient = useQueryClient();

  return useMutation<void, ApiError, SigningFormReferenceParameters>(async ({ classId, studentUserId }) => {
    await cancelSigningForm(classId, studentUserId);
    queryClient.invalidateQueries(['SigningFormPage']);
  });
}
