import { Form } from 'src/models/form';
import React from 'react';
import EditableForm from 'src/models/editableForm';
import ToastsContext from 'src/hooks/useToasts';
import { ContextProps } from '../GlobalServices';
import { FormProviderContext, IFormProvider } from '../../context/FormProviderContext';
import { useHttpClient } from '../../context/HttpClientContext';

export const FormProviderService: React.FC<ContextProps> = ({ children }: ContextProps) => {
  const formUrl = 'api/forms';
  const httpClient = useHttpClient();
  const { setErrorToast, setShowSuccessToast } = ToastsContext.useContainer();

  const formProvider: IFormProvider = {
    getAllForms: async () => {
      const res = await httpClient.get<Form[]>({ url: formUrl });
      if (res.statusCode === 200) {
        return res.data as Form[];
      }
      return [];
    },
    getForm: async id => {
      const res = await httpClient.get<Form>({ url: `${formUrl}/${id}` });
      if (res.statusCode === 200) {
        return res.data;
      }
      return undefined;
    },
    createForm: async (form: EditableForm) => {
      try {
        const result = await httpClient.post<Form>({ url: formUrl, data: form });
        const formId = result.data as number;

        setShowSuccessToast(true);

        return formId;
      } catch (error: any) {
        setErrorToast(error);
        return undefined;
      }
    },
    updateForm: async (id: number, form: EditableForm) => httpClient.put<Form>({ url: formUrl, data: form }).then(() => setShowSuccessToast(true)).catch(err => setErrorToast(err)),
    deleteForm: async (id: number) => httpClient.delete<Form>({ url: `${formUrl}/${id}` }).then(() => setShowSuccessToast(true)).catch(err => setErrorToast(err)),
  };
  return (
    <FormProviderContext.Provider value={formProvider}>
      {children}
    </FormProviderContext.Provider>
  );
};

export default FormProviderService;
