import { useState } from 'react';
import EditableForm, { EditableFormQuestion, EditableFormQuestionGroup } from 'src/models/editableForm';
import { createContainer } from 'unstated-next';

function useFormContext() {
  const [form, setForm] = useState<EditableForm>(new EditableForm());
  const [formLoaded, setFormLoaded] = useState<boolean>(false);

  const setInitialForm = (newForm: EditableForm) => {
    const formWithSortedGroups = { ...newForm, groups: newForm.groups.map((group, index) => ({ ...group, order: index + 1 })) };
    const formWithSortedGroupsAndQuestions = { ...formWithSortedGroups, groups: formWithSortedGroups.groups.map(group => ({ ...group, questions: group.questions.map((question, index) => ({ ...question, order: index + 1 })) })) };
    setForm(formWithSortedGroupsAndQuestions);
    setFormLoaded(true);
  };

  const setFormName = (name:string) => setForm({ ...form, name });

  const getFormGroups = () => form.groups;

  const addGroup = () => {
    const { groups } = form;
    const maxOrder = groups?.length > 0 ? groups[groups.length - 1]?.order : 0;
    const newGroup = { ...new EditableFormQuestionGroup(maxOrder + 1) };
    setForm({ ...form, groups: [...form.groups, newGroup] });
  };

  const removeGroup = (groupOrder: number) => {
    setForm((prevForm: EditableForm) => {
      const groups = [...prevForm.groups];
      const filteredGroups = groups.filter(group => group.order !== groupOrder);
      return { ...prevForm, groups: filteredGroups };
    });
  };

  const setGroupName = (groupOrder: number, name: string) => {
    setForm((prevForm: EditableForm) => {
      const groups = [...prevForm.groups];
      const groupIndex = prevForm.groups.findIndex(formGroup => formGroup.order === groupOrder);
      groups[groupIndex].name = name;
      return { ...prevForm, groups };
    });
  };

  const addQuestion = (groupOrder: number) => {
    setForm((prevForm: EditableForm) => {
      const groups = [...prevForm?.groups];
      const groupIndex = groups?.findIndex(formGroup => formGroup?.order === groupOrder);
      const questionGroup = [...groups[groupIndex].questions];

      const maxOrder = questionGroup?.length > 0 ? questionGroup[questionGroup.length - 1]?.order : 0;
      const newQuestion = { ...new EditableFormQuestion(maxOrder + 1) };

      groups[groupIndex].questions.push(newQuestion);

      return { ...prevForm, groups };
    });
  };

  const removeQuestion = (groupOrder: number, questionOrder: number) => {
    setForm((prevForm: EditableForm) => {
      const groups = [...prevForm.groups];
      const groupIndex = groups.findIndex(formGroup => formGroup.order === groupOrder);

      const currentGroup = groups.find(group => group.order === groupOrder);
      if (currentGroup) {
        const filteredQuestions = currentGroup?.questions.filter(question => question.order !== questionOrder);
        currentGroup.questions = filteredQuestions;
        groups[groupIndex] = currentGroup;
      }
      return { ...prevForm, groups };
    });
  };

  const editQuestion = (groupOrder: number, questionText: string, questionOrder: number) => {
    setForm((prevForm: EditableForm) => {
      const groups = [...prevForm.groups];
      const groupIndex = groups.findIndex(formGroup => formGroup.order === groupOrder);
      const editableQuestion = groups[groupIndex].questions.find(q => q.order === questionOrder);
      if (editableQuestion) editableQuestion.text = questionText;
      return { ...prevForm, groups };
    });
  };
  const changeQuestionType = (groupOrder: number, newQuestionType: any) => {
    setForm((prevForm: EditableForm) => {
      const groups = [...prevForm.groups];
      const groupIndex = groups.findIndex(formGroup => formGroup.order === groupOrder);
      groups[groupIndex].type = newQuestionType;
      return { ...prevForm, groups };
    });
  };
  const setQuestionHelpText = (groupOrder: number, helpText: string, questionOrder: number) => {
    setForm((prevForm: EditableForm) => {
      const groups = [...prevForm.groups];
      const groupIndex = groups.findIndex(formGroup => formGroup.order === groupOrder);
      const editableQuestion = groups[groupIndex].questions.find(q => q.order === questionOrder);
      if (editableQuestion) editableQuestion.helpText = helpText;
      return { ...prevForm, groups };
    });
  };

  const toggleMainForm = (newValue: boolean) => setForm((prevForm: EditableForm) => ({ ...prevForm, isMainForm: newValue }));

  return {
    form,
    setInitialForm,
    setFormName,
    formLoaded,
    setFormLoaded,
    addGroup,
    getFormGroups,
    setGroupName,
    addQuestion,
    toggleMainForm,
    removeGroup,
    editQuestion,
    changeQuestionType,
    setQuestionHelpText,
    removeQuestion,
  };
}

const FormContext = createContainer(useFormContext);

export default FormContext;
