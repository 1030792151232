import { AxiosTransformer } from 'axios';
import DateOnly from 'src/models/common/DateOnly';

const customTransformer: AxiosTransformer = (data: any) => {
  if (data instanceof DateOnly) {
    return data.toShortISOString();
  }
  if (data instanceof Date) {
    return data.toISOString();
  }
  if (Array.isArray(data)) {
    return data.map(val => customTransformer(val));
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(Object.entries(data).map(([key, val]) => [key, customTransformer(val)]));
  }
  return data;
};

export default customTransformer;
