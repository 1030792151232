import { useRef, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import { FormattedMessage, useIntl } from 'react-intl';
import SettingsModal from './SettingsModal';
import LogoutButton from './LogoutButton';

interface SidebarProps {
  setIsOpen: (state: boolean) => void;
}

const Sidebar = ({ setIsOpen: toggleSidebarActive }: SidebarProps) => {
  const { user, handleSetActiveRole } = LoggedInUserContext.useContainer();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);
  const { push } = useHistory();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        ref.current
        && ref.current.contains
        && !ref.current.contains(e.target)
      ) {
        toggleSidebarActive(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, []);

  return (
    <>
      <SettingsModal isOpen={isOpen} close={() => setIsOpen(false)} />
      <div
        className={`h-screen bg-white z-50 absolute top-0 left-0 w-fit ${isOpen && 'hidden'}`}
        ref={ref}
      >
        <div className="">
          <ul className="p-4 h-screen justify-between flex flex-col">
            <div>
              {user?.roles && (
                <div className="mb-8">
                  <span className="font-bold">
                    {formatMessage({ id: 'your-routes' })}
                  </span>
                  {user?.roles?.map(role => {
                    const roleName = role.name.toLowerCase();
                    return (
                      <li key={roleName}>
                        <Link
                          to={`/${roleName}`}
                          onClick={() => handleSetActiveRole(role.name)}
                        >
                          {formatMessage({ id: `${roleName}-route` })}
                        </Link>
                      </li>
                    );
                  })}
                </div>
              )}
              <li>
                <button
                  className="flex items-center pb-12"
                  onClick={() => {
                    window.open('https://www.responssykepleie.no', '_blank');
                  }}
                >
                  <FormattedMessage id="about" />
                </button>
              </li>
              <li>
                <button
                  className="flex items-center pb-12"
                  onClick={() => {
                    push('/privacy-policy');
                  }}
                >
                  <FormattedMessage id="privacy-policy" />
                </button>
              </li>
            </div>
            <div>
              <li>
                <button
                  className="flex items-center pb-12"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                >
                  <FormattedMessage id="settings" />
                </button>
              </li>
              <li>
                <LogoutButton />
              </li>
            </div>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
