import { Button } from '@windmill/react-ui';
import { MdChevronRight } from 'react-icons/md';

interface ChevronButtonProps {
  ariaLabel?: string,
}

const ChevronButton = ({ ariaLabel }: ChevronButtonProps) => (
  <Button className="self-center pl-2 pr-6" layout="link" aria-label={ariaLabel}>
    <MdChevronRight
      className="border border-secondary bg-secondary text-black rounded-full"
      size="3em"
    />
  </Button>
);
export default ChevronButton;
