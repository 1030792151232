import { Card as WindmillCard, CardBody } from '@windmill/react-ui';
import classnames from 'classnames';
import { ReactNode } from 'react';
import ChevronButton from '../ChevronButton/ChevronButton';

interface CardProps {
  header?: string,
  body?: string | ReactNode | ReactNode[],
  primary?: boolean,
  onClick: () => void,
  className?: string,
}

const Card = ({ header, body, primary = true, onClick, className }: CardProps) => (
  <WindmillCard className={classnames({ 'bg-primary text-white': primary }, 'flex w-auto justify-between cursor-pointer mb-4', className)} onClick={onClick}>
    <CardBody className="flex-col w-auto text-left self-center">
      <div className="flex items-center">
        <h4 className="m-0">{header}</h4>
      </div>
      {body && <div className={classnames({ 'text-customGray': !primary }, 'mt-4')}>{body}</div>}
    </CardBody>
    <div className="px-4 py-3 self-center">
      <ChevronButton ariaLabel={header} />
    </div>
  </WindmillCard>
);

export default Card;
