import { useState, ReactElement } from 'react';
import Textarea from '../Textarea/Textarea';

interface EditableInputProps {
  text?: string | undefined,
  onChangeHandler: (updatedText: string) => void;
  inputStyle?: string;
  active?: boolean;
  notActiveComponent?: (value?: string) => ReactElement<any, any>;
  maxLength?: number;
  ariaLabel?: string;
}

const EditableInput = ({ text, onChangeHandler, inputStyle, active, notActiveComponent, maxLength, ariaLabel }: EditableInputProps) => {
  const [textValue, setTextValue] = useState<string>(text && text ? text : '');
  function onUnFocus() {
    onChangeHandler(textValue);
  }

  function onUpdate(updatedValue: string) {
    setTextValue(updatedValue);
    onChangeHandler(updatedValue);
  }

  return (
    (active || !notActiveComponent)
      ? (
        <Textarea
          className={`${inputStyle} border-lightGray leading-snug`}
          value={textValue}
          onBlur={onUnFocus}
          onChange={e => onUpdate(e.target.value)}
          maxLength={maxLength}
          ariaLabel={ariaLabel}
        />
      )
      : notActiveComponent(text)
  );
};

export default EditableInput;
