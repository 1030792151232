import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Spinner from 'src/components/_common/Spinner/Spinner';
import useFsDebugQuery from './queries';

export default function FSDebugPage() {
  const query = useFsDebugQuery();

  if (query.isLoading) { return <Spinner />; }

  function getDebugText() {
    if (query.isError) return JSON.stringify(query.error);
    if (query.data && Object.keys(query.data).length > 0) return JSON.stringify(query.data);
    return 'Ingen data';
  }

  return (
    <>
      <PageHeader title="FS Data" subTitle="Her vises data som kan brukes for å feilsøke problemer relatert til Feide/FS." />
      <code>
        {getDebugText()}
      </code>
    </>
  );
}
