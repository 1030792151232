import { ReactNode } from 'react';
import { MdChevronRight } from 'react-icons/md';

interface ListItemProps {
  onClick?: () => void;
  children?: ReactNode | ReactNode[],
}

export default function ListItem({ onClick, children }: ListItemProps) {
  if (!onClick) {
    return <li className="pl-4 py-3">{children}</li>;
  }

  return (
    <li>
      <button onClick={onClick} className="flex w-full items-center pl-4 py-5">
        <span className="text-left" style={{ flexGrow: 1 }}>{children}</span>
        <MdChevronRight
          className="border border-secondary bg-secondary text-black rounded-full mr-6 text-sm"
          size="3em"
        />
      </button>
    </li>
  );
}
