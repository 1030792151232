import { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Class } from 'src/models/classes';
import { MdOutlineInfo } from 'react-icons/md';
import Button from 'src/components/_common/Button/Button';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import { useIntl } from 'react-intl';
import { useJoinClass } from './queries';

interface JoinClassFormProps {
  classes: Class[]
}

export default function JoinClassForm({ classes }: JoinClassFormProps) {
  const [selectedClassId, setSelectedClassId] = useState<number | undefined>(undefined);
  const joinClass = useJoinClass(handleSuccessfulSubmit);
  const history = useHistory();
  const { formatMessage } = useIntl();

  function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (selectedClassId && !joinClass.isLoading) {
      joinClass.mutate(selectedClassId);
    }
  }

  function handleSuccessfulSubmit() {
    history.push('/student');
  }

  const isDisabled = joinClass.isLoading || !selectedClassId;

  return (
    <form onSubmit={handleSubmit}>
      <fieldset className="mb-4">
        {classes.map(c => (
          <div key={c.id} className="mb-2">
            <input
              id={`class-${c.id}`}
              data-testid={`class-${c.id}`}
              checked={c.id === selectedClassId}
              required
              onChange={() => setSelectedClassId(c.id)}
              type="radio"
              className="mr-2"
            />
            <label htmlFor={`class-${c.id}`}>{c.subject?.subjectCode} - ({c.period?.name})</label>
          </div>
        ))}
      </fieldset>

      {joinClass.isError && (
        <ApiErrorAlert
          error={joinClass.error}
          message={formatMessage({ id: 'studentJoinClassErrorMessage' })}
        />
      )}

      <div className="flex mb-4">
        <div className="mr-1 pt-1">
          <MdOutlineInfo />
        </div>
        <div className="flex-1">
          <p>{formatMessage({ id: 'studentJoinClassInfoLabel' })}</p>
        </div>
      </div>

      <Button
        disabled={isDisabled}
        isSaving={joinClass.isLoading}
        type="submit"
        data-testid="submit-button"
        className="w-full"
      >{formatMessage({ id: 'studentJoinClassSubmitButtonText' })}
      </Button>
    </form>
  );
}
