import Card from 'src/components/_common/Card/Card';
import Spinner from 'src/components/_common/Spinner/Spinner';
import SemestersContext from 'src/hooks/useSemesters';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import { sortPeriodsByStartDateComparer } from 'src/utils/periodUtils';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Button from 'src/components/_common/Button/Button';
import PageSubTitle from 'src/components/_common/PageHeader/PageSubTitle';
import { HiPlusSm } from 'react-icons/hi';
import List from 'src/components/_common/List/List';
import ListItem from 'src/components/_common/List/ListItem';
import { Period } from 'src/models/classes';
import CreateNewSemesterModal from './CreateNewSemesterModal/CreateNewSemesterModal';

const AdminSemesters = () => {
  const { semesters, semestersLoaded, createNewSemester, isSaving } = SemestersContext.useContainer();

  const [modalOpen, setModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  const history = useHistory();

  if (!semestersLoaded) return <Spinner />;

  const navigateToSemester = (semester: Period) => history.push(`/admin/semesters/${semester.id}`);

  const today = new Date();
  const completedSemesters = semesters?.filter(semester => semester.endDate && today > new Date(semester.endDate));
  const activeSemesters = semesters?.filter(semester => today > new Date(semester.startDate) && !(semester.endDate && today > new Date(semester.endDate)));
  const futureSemesters = semesters?.filter(semester => today < new Date(semester.startDate));
  const hasFutureSemesters = futureSemesters && futureSemesters?.length > 0;

  return (
    <div>
      <PageHeader title={formatMessage({ id: 'semesters' })}>
        {!hasFutureSemesters && (
          <div className="text-center mt-4 mb-4">
            <Button onClick={() => setModalOpen(true)} ariaLabel={formatMessage({ id: 'create-next-semester' })} iconLeft={HiPlusSm}>
              {formatMessage({ id: 'create-next-semester' })}
            </Button>
          </div>
        )}
        <PageSubTitle>{formatMessage({ id: 'list-of-all-periods' })}</PageSubTitle>
      </PageHeader>
      {hasFutureSemesters
        && (
          <>
            <h2 data-testid="future-semesters-header">{formatMessage({ id: 'next-semester' })}</h2>
            <div>
              {futureSemesters.map(semester => (
                <Card
                  primary={false}
                  key={semester.id}
                  header={semester.name}
                  onClick={() => history.push(`/admin/semesters/${semester.id}`)}
                />
              ))}
            </div>
          </>
        )}
      <h2>{formatMessage({ id: 'current-semester' })}</h2>
      {activeSemesters?.map(semester => (
        <Card
          key={semester.id}
          header={semester.name}
          onClick={() => history.push(`/admin/semesters/${semester.id}`)}
        />
      ))}
      {completedSemesters && completedSemesters?.length > 0
        && (
        <List title={formatMessage({ id: 'previous-semester' })}>
          {completedSemesters?.sort(sortPeriodsByStartDateComparer).map(semester => (
            <ListItem key={semester.id} onClick={() => navigateToSemester(semester)}>
              {semester.name}
            </ListItem>
          ))}
        </List>
        )}
      {modalOpen
        && (
          <CreateNewSemesterModal
            isOpen={modalOpen}
            close={() => setModalOpen(false)}
            isSaving={isSaving}
            createNewSemester={newSemester => {
              createNewSemester(newSemester, () => setModalOpen(false));
            }}
            activeSemesters={activeSemesters}
          />
        )}
    </div>
  );
};

export default AdminSemesters;
