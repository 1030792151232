import { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'src/models/form';
import { useFormProvider } from 'src/context/FormProviderContext';
import { useIntl } from 'react-intl';
import CardList from 'src/components/_common/CardList/CardList';
import Card from 'src/components/_common/Card/Card';
import { sortFormsByNameComparer } from 'src/utils/formUtils';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import List from 'src/components/_common/List/List';
import ListItem from 'src/components/_common/List/ListItem';

const AdminForms: FC = () => {
  const formProvider = useFormProvider();
  const [forms, setForms] = useState<Form[] | undefined>(undefined);
  const [formsLoaded, setFormsLoaded] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const getForms = async () => {
    const retrievedForms = (await formProvider.getAllForms()).sort((a: Form, b: Form) => (a.name > b.name ? 1 : -1));
    setForms(retrievedForms);
    setFormsLoaded(true);
  };
  const history = useHistory();

  useEffect(() => {
    getForms();
  }, []);

  const navigateToForm = (form: Form) => history.push(`/admin/forms/edit/${form.id}`);

  const mainForms = forms?.filter(form => form?.isMainForm).sort(sortFormsByNameComparer);
  const nonMainForms = forms?.filter(form => !form?.isMainForm).sort(sortFormsByNameComparer) ?? [];

  return (
    <>
      <div className="h-full pt-8">
        <PageHeader title={formatMessage({ id: 'respons' })} subTitle={formatMessage({ id: 'edit-respons-schemes' })} />

        {mainForms
          && (
            <CardList>
              {mainForms.map(form => <Card key={form.id} header={form.name} onClick={() => history.push(`/admin/forms/edit/${form.id}`)} />)}
            </CardList>
          )}
        {formsLoaded && (
          <List
            title={formatMessage({ id: 'specific-compentencies' })}
            titleButtonAriaLabel={formatMessage({ id: 'new-form' })}
            onTitleClick={() => history.push('/admin/forms/create')}
          >
            {nonMainForms.map(form => (
              <ListItem key={form.id} onClick={() => navigateToForm(form)}>
                {form.name}
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </>
  );
};

export default AdminForms;
