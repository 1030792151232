import React, { ReactNode } from 'react';
import PageSidebarWithToggle from '../PageSidebarWithToggle/PageSidebarWithToggle';
import PageSubTitle from './PageSubTitle';
import PageTitle from './PageTitle';

interface PageHeaderProps {
  title?: string;
  subTitle?: string;
  includeSidebarWithToggle?: boolean;
  children?: ReactNode | ReactNode[],
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subTitle, children, includeSidebarWithToggle = true }: PageHeaderProps) => (
  <header className="pt-4 pb-12 text-center">
    {includeSidebarWithToggle && <PageSidebarWithToggle />}
    {title && <PageTitle>{title}</PageTitle>}
    {subTitle && <PageSubTitle>{subTitle}</PageSubTitle>}
    {children}
  </header>
);

export default PageHeader;
