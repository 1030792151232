/* eslint-disable react/no-array-index-key */
import { ReactNode, useState } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { useIntl } from 'react-intl';
import AccordionIcon, { IAccordionIcon } from './AccordionIcon';
import EditableInput from '../../EditableInput/EditableInput';

export type OpenButtonLocation = 'right' | 'bottom';
export interface AccordionProps {
  openByDefault?: boolean;
  toggleIconHidden?: boolean;
  text?: string;
  icons?: IAccordionIcon[];
  children: ReactNode | ReactNode[],
  headerSubTitle: ReactNode
  openButtonLocation?: OpenButtonLocation
  border?: boolean;
  inputStyle?: string;
  changeHandler: (newText: string) => void
}

const Accordion = ({ openByDefault, text, children, icons, toggleIconHidden, headerSubTitle, openButtonLocation = 'bottom', border, inputStyle, changeHandler }: AccordionProps) => {
  const [open, setOpen] = useState<boolean>(openByDefault || false);
  const { formatMessage } = useIntl();

  function onClickHandler() {
    setOpen(!open);
  }

  const parseText = (value?: string) => {
    if (!value) return '';

    const separateLines = value.split(/\r?\n|\r|\n/g);
    return separateLines.map((t, index) => <p key={index}>{t}</p>);
  };

  return (
    <>
      <div className={`flex flex-col ${border ? 'shadow-tableShadow rounded-lg p-4 bg-white' : ''}`}>
        <div className="flex flex-row justify-between ">
          <div className="text-gray-400 self-center">{headerSubTitle}</div>
          <div>
            {icons && (icons.map((icon, index) => <AccordionIcon key={index} icon={icon} />))}
          </div>
        </div>
        <div className="flex flex-row justify-between ">
          <EditableInput
            text={text}
            inputStyle={inputStyle}
            active={open}
            onChangeHandler={newtext => changeHandler(newtext)}
            notActiveComponent={() => <div key={open ? 'true' : 'false'} data-testid="accordion-header" className={`${inputStyle} w-full text-left`}>{parseText(text)}</div>}
          />
          {openButtonLocation === 'right' && !toggleIconHidden && (
            <AccordionIcon
              key={icons?.length}
              icon={{
                icon: open ? MdExpandLess : MdExpandMore,
                onClick: onClickHandler,
                label: open ? formatMessage({ id: 'hide' }) : formatMessage({ id: 'showMore' }),
              }}
            />
          )}
        </div>
        {open && (<>{children}</>)}
        {openButtonLocation === 'bottom' && !toggleIconHidden && (
          <AccordionIcon
            key={icons?.length}
            icon={{
              icon: open ? MdExpandLess : MdExpandMore,
              onClick: onClickHandler,
              label: open ? formatMessage({ id: 'hide' }) : formatMessage({ id: 'showMore' }),
            }}
          />
        )}
      </div>
    </>
  );
};

export default Accordion;
