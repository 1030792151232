import { LoginDetails } from 'src/models/user';
import Contextualizer from './Contextualizer/Contextualizer';
import ProvidedServices from './Contextualizer/ProvidedServices';
import { HttpResponse } from './HttpClientContext';

export interface IAuthenticator {
  getOTP(email: string): Promise<boolean | HttpResponse<any>>;
  authenticate(details: LoginDetails): Promise<boolean | HttpResponse<any>>;
  feideAuth(code: string): Promise<boolean | HttpResponse<any>>;
  refreshToken(): Promise<boolean | HttpResponse<any>>;
}

export const AuthenticatorContext = Contextualizer.createContext<IAuthenticator>(ProvidedServices.Authenticator);
export const useAuthenticator = () => Contextualizer.use<IAuthenticator>(ProvidedServices.Authenticator);
