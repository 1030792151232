import { useState, FormEvent } from 'react';
import { useIntl } from 'react-intl';
import Button from 'src/components/_common/Button/Button';
import { useHistory, useLocation } from 'react-router-dom';
import CardWithTitle from 'src/components/_common/CardWithTitle/CardWithTitle';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import LabeledInput from '../../../components/_common/LabeledInput/LabeledInput';
import { useSendOneTimePassword } from './queries';

const EnterEmailPage = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { search } = useLocation();
  const verificationGuid = new URLSearchParams(search).get('verificationGuid') ?? null;
  if (verificationGuid) history.push(`/supervisor/accept-invitation?verificationGuid=${verificationGuid}&redirect_uri=/login`);

  const [email, setEmail] = useState('');
  const sendOneTimePasswordMutation = useSendOneTimePassword(onSuccess);

  function onSuccess() {
    history.push('/otp', { email });
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!email || !e.currentTarget.checkValidity() || sendOneTimePasswordMutation.isLoading) return;
    sendOneTimePasswordMutation.mutate(email);
  }

  function navigateBackToAuthenticationPage() {
    history.push('/');
  }

  return (
    <>
      <PageHeader
        title={formatMessage({ id: 'app-name' })}
        subTitle={formatMessage({ id: 'authenticatePageSubTitle' })}
      />
      <CardWithTitle title={formatMessage({ id: 'login' })} onBack={navigateBackToAuthenticationPage}>
        <p className="text-darkergray mb-8">{formatMessage({ id: 'external-login-info' })}</p>
        <form
          onSubmit={onSubmit}
          noValidate
          className="text-center m-auto max-w-sm"
        >
          <LabeledInput
            labelText={formatMessage({ id: 'email' })}
            required
            onValueChangeHandler={(e: string) => setEmail(e)}
            type="email"
            autoFocus
          />
          <Button isSaving={sendOneTimePasswordMutation.isLoading} type="submit" className="mt-8 w-full" data-testid="submit-button">
            Send
          </Button>
        </form>
        {sendOneTimePasswordMutation.isError && (
        <ApiErrorAlert error={sendOneTimePasswordMutation.error} message={formatMessage({ id: 'sendOneTimePasswordPageErrorMessage' })} />
        )}
      </CardWithTitle>
    </>
  );
};

export default EnterEmailPage;
