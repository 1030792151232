import { useParams } from 'react-router-dom';
import { BsDot } from 'react-icons/bs';
import { HiPrinter } from 'react-icons/hi';
import { RoleTypes } from 'src/models/roles';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/_common/Button/Button';
import ShowStudentPdf from 'src/pages/Student/StudentForms/ShowStudentPdf';
import ShowSigningFormPdf from 'src/pages/Student/StudentForms/ShowSigningFormPdf';
import ShowWarningPdf from 'src/pages/Student/StudentForms/ShowWarningPdf';
import Spinner from '../_common/Spinner/Spinner';
import PageHeader from '../_common/PageHeader/PageHeader';
import SupervisorSelect from './SupervisorSelect';
import { useStudentPageQuery } from './queries';
import ApiErrorAlert from '../_common/ApiErrorAlert/ApiErrorAlert';
import StudentWarningSection from './StudentWarningSection/StudentWarningSection';
import SigningFormSection from './SigningFormSection/SigningFormSection';
import FormsSection from './FormsSection';
import StudentWarningAlert from './StudentWarningSection/StudentWarningAlert';

const StudentPage = ({ activeRole }: { activeRole: RoleTypes }) => {
  const { classId, userId } = useParams<{ classId: string, userId: string }>();

  const query = useStudentPageQuery(+classId, +userId);

  const handlePdf = () => {
    ShowStudentPdf(+classId, +userId);
  };

  const handleSigningFormPdf = () => {
    ShowSigningFormPdf(+classId, +userId);
  };

  const handleWarningPdf = () => {
    ShowWarningPdf(+classId, +userId);
  };

  if (query.isLoading) return <Spinner />;
  if (query.isError) return <ApiErrorAlert error={query.error} message="" />;

  const { class: _class, student, warning, supervisor, teacher, signingForm } = query.data;

  return (
    <>
      <PageHeader title={query.data.student.displayName}>
        <div className="flex justify-center items-center text-darkerGray">{query.data.class.subject?.subjectCode}
          <BsDot />
          {query.data.class.period?.name}
        </div>
        <div className="mt-5">
          <Button onClick={handlePdf} iconLeft={HiPrinter}>
            <FormattedMessage id="forms" />
          </Button>
          {signingForm && (
            <Button onClick={handleSigningFormPdf} iconLeft={HiPrinter} className="ml-2" data-testid="signingform-download-button">
              <FormattedMessage id="signing-form-header" />
            </Button>
          )}
          {warning && (
            <Button onClick={handleWarningPdf} iconLeft={HiPrinter} className="ml-2" data-testid="warning-download-button">
              <FormattedMessage id="warning" />
            </Button>
          )}
        </div>
      </PageHeader>
      {activeRole === RoleTypes.Teacher && (
        <>
          <SupervisorSelect classId={+classId} student={query.data.student} />
          <StudentWarningSection
            class={_class}
            student={student}
            warning={warning}
            activeRole={activeRole}
            supervisor={supervisor}
            teacher={teacher}
          />
        </>
      )}
      {activeRole !== RoleTypes.Teacher && warning?.submittedAt && (
      <>
        <StudentWarningAlert warning={warning} student={student} class={_class} activeRole={activeRole} supervisor={supervisor} teacher={teacher} />
      </>
      )}
      <SigningFormSection signingForm={signingForm} showCreateButton={activeRole === RoleTypes.Teacher} />
      <FormsSection class={_class} signingForm={signingForm!} />
    </>
  );
};

export default StudentPage;
