import { Modal, ModalHeader, ModalBody, ModalFooter } from '@windmill/react-ui';
import { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'src/components/_common/Select/Select';
import ClassesContext from 'src/hooks/useClasses';
import { Period } from 'src/models/classes';
import SubjectsContext from 'src/hooks/useSubjects';
import Button from 'src/components/_common/Button/Button';

interface AddClassToSemesterModalProps {
  isOpen: boolean;
  onClose: () => void
  semester: Period
}

const AddClassToSemesterModal = ({ isOpen, onClose, semester }: AddClassToSemesterModalProps) => {
  const { classes, isSaving, addSubjectsToClass } = ClassesContext.useContainer();
  const { subjects } = SubjectsContext.useContainer();

  const [selectedSubjects, setSelectedSubjects] = useState<any>();

  useEffect(() => {
    setSelectedSubjects(classes?.filter(_class => _class.periodId === semester.id).map(_class => ({ label: _class?.subject?.subjectCode, value: _class?.subject?.id, isFixed: true })));
  }, [classes]);

  const handleAddSubjectToClass = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newSubjects = selectedSubjects.filter((selectedSubject: any) => !selectedSubject.isFixed).map((selectedSubject: any) => ({ subjectId: selectedSubject.value, periodId: semester.id }));
    await addSubjectsToClass(newSubjects);
    onClose();
  };

  const { formatMessage } = useIntl();
  const handleChange = (newSubjects: any, option: any) => {
    if (option.removedValue && option.removedValue.isFixed) return;
    setSelectedSubjects(newSubjects);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} style={{ margin: 'auto' }}>
      <form onSubmit={handleAddSubjectToClass}>
        <ModalHeader>
          <FormattedMessage id="add-subject-to-semester" />
        </ModalHeader>
        <ModalBody>
          <Select
            label={formatMessage({ id: 'subjects' })}
            value={selectedSubjects}
            options={subjects?.map(subject => ({ label: subject.subjectCode, value: subject.id }))}
            onChange={handleChange}
            isClearable={!selectedSubjects?.some((client: any) => client.isFixed)}
            isMulti
          />
        </ModalBody>
        <ModalFooter>
          <Button className="w-full sm:w-auto" layout="outline" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button data-testid="save-button" type="submit" className="w-full sm:w-auto" isSaving={isSaving} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddClassToSemesterModal;
