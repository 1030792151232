import { useHistory } from 'react-router-dom';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import { useIntl } from 'react-intl';
import Card from 'src/components/_common/Card/Card';
import CardList from 'src/components/_common/CardList/CardList';
import Spinner from 'src/components/_common/Spinner/Spinner';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';

const SelectActiveRole = () => {
  const { user, handleSetActiveRole } = LoggedInUserContext.useContainer();
  const history = useHistory();
  const { formatMessage } = useIntl();

  if (!user?.id) return <Spinner />;
  return (
    <>
      <PageHeader title={formatMessage({ id: 'hello-user' }, { name: user?.displayName })} />

      <CardList header={formatMessage({ id: 'your-routes' })}>
        {user?.roles?.map(role => {
          const roleName = role.name.toLowerCase();
          return (
            <Card
              key={role.id}
              header={formatMessage({ id: `${roleName}-route` })}
              body={formatMessage({ id: `route-description-${roleName}` })}
              onClick={async () => {
                await handleSetActiveRole(role.name);
                history.push(`/${roleName}`);
              }}
            />
          );
        })}
      </CardList>
    </>
  );
};

export default SelectActiveRole;
