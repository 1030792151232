import { HiInformationCircle } from 'react-icons/hi';
import { useIntl } from 'react-intl';

interface HelpButtonProps {
  helpText?: string;
  onClick: () => void;
}

export default function HelpButton({ helpText, onClick }: HelpButtonProps) {
  const { formatMessage } = useIntl();

  if (!helpText) return null;

  return (
    <div className="relative">
      <button onClick={onClick} aria-label={formatMessage({ id: 'helptext' })}>
        <HiInformationCircle className="text-2xl" />
      </button>
    </div>
  );
}
