import { HelperText, Input, Label } from '@windmill/react-ui';
import { InputProps } from '@windmill/react-ui/dist/Input';
import { ChangeEvent, FocusEvent, useState, ReactNode } from 'react';

interface LabeledInputProps extends InputProps {
  labelText: string,
  defaultValue?: string | undefined,
  onValueChangeHandler: (value: string) => void,
  className?: string;
  customErrorMessage?: string | ReactNode | ReactNode[] ;
  type?: string;
  required?: boolean
}

const LabeledInput = ({ labelText, defaultValue, onValueChangeHandler, className, type = 'text', required = false, customErrorMessage, ...props }: LabeledInputProps) => {
  const [valid, setValid] = useState<boolean>(true);
  const [validationMessage, setValidationMessage] = useState<string |ReactNode | ReactNode[]>('');
  function onValueChange(e: ChangeEvent<HTMLInputElement>) {
    handleValidity(e);
    onValueChangeHandler(e.target.value);
  }
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    handleValidity(e);
  };
  const handleValidity = (e: FocusEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {
    const isValid = e.target.checkValidity();
    setValid(isValid);
    if (!isValid) {
      if (customErrorMessage) {
        setValidationMessage(customErrorMessage);
      } else {
        setValidationMessage(e.target.validationMessage);
      }
    }
  };
  return (
    <>
      <Label className={className}>
        <span>{labelText}</span>
        <Input
          valid={valid ? undefined : false}
          css=""
          type={type}
          formNoValidate
          onBlur={handleBlur}
          className="invalid:border-danger"
          defaultValue={defaultValue}
          onChange={e => onValueChange(e)}
          required={required}
          {...props}
        />
        {!valid && validationMessage && <HelperText valid={false}>{validationMessage}</HelperText>}
      </Label>
    </>
  );
};

export default LabeledInput;
