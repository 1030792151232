import Spinner from 'src/components/_common/Spinner/Spinner';
import { useIntl } from 'react-intl';
import Card from 'src/components/_common/Card/Card';
import CardList from 'src/components/_common/CardList/CardList';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import ClassesContext from '../../../hooks/useClasses';
import LoggedInUserContext from '../../../hooks/useLoggedInUser';

const SupervisorClasses = () => {
  const {
    getSupervisorClasses,
    supervisorClasses,
    supervisorClassesLoaded,
    supervisorStudentsError,
  } = ClassesContext.useContainer();
  const { user } = LoggedInUserContext.useContainer();
  const { formatMessage } = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (user?.advisorClasses && !supervisorClassesLoaded) {
      getSupervisorClasses(user?.advisorClasses);
    }
  }, [user]);

  if (supervisorStudentsError) return <p>{supervisorStudentsError}</p>;

  if (!supervisorClassesLoaded || !user?.id || !supervisorClasses) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader
        title={formatMessage(
          { id: 'hello-user' },
          { name: user?.displayName },
        )}
      />
      <CardList header={formatMessage({ id: 'your-subjects' })}>
        {supervisorClasses.map(_class => (
          <Card
            key={_class.id}
            header={`${_class?.subject?.subjectCode} (${_class?.period?.name})`}
            body={_class.subject?.name}
            onClick={() => history.push(`/supervisor/class/${_class.id}`)}
          />
        ))}
      </CardList>
    </>
  );
};

export default SupervisorClasses;
