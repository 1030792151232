import { Modal, ModalBody, ModalHeader } from '@windmill/react-ui';
import { FormQuestion } from 'src/models/form';

interface HelpTextModalProps {
  question: FormQuestion;
  isOpen: boolean;
  close: () => void;
}

export default function HelpTextModal({ question, isOpen, close }: HelpTextModalProps) {
  return (
    (
      <Modal isOpen={isOpen} onClose={close} style={{ margin: 'auto' }}>
        <ModalHeader>
          {question.text}
        </ModalHeader>
        <ModalBody>
          <div className="flex-wrap whitespace-pre-wrap">
            {question.helpText}
          </div>
        </ModalBody>
      </Modal>
    )
  );
}
