import { HiOutlineClock } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { useSupervisorInvitationForLoggedInStudent } from './queries';

export default function InvitationStatusLabel() {
  const { formatMessage } = useIntl();
  const { isLoading, isError, data: invitation } = useSupervisorInvitationForLoggedInStudent();

  if (isLoading || isError || !invitation || invitation.accepted) return null;

  const isExpired = invitation.expiresAtUtc < new Date();

  function getLabelText() {
    if (isExpired) return formatMessage({ id: 'invitationStatusLabelExpired' });
    return formatMessage({ id: 'invitationStatusLabelSent' });
  }

  return (
    <div className="flex justify-center text-darkgray">
      <HiOutlineClock className="relative mr-1" style={{ top: 4 }} />
      {getLabelText()}
    </div>
  );
}
