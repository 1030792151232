import { HelperText, Label, Textarea } from '@windmill/react-ui';
import { TextareaProps } from '@windmill/react-ui/dist/Textarea';
import { ChangeEvent, FocusEvent, useState, ReactNode, FocusEventHandler } from 'react';

interface LabeledTextAreaProps extends TextareaProps {
  labelText: string,
  defaultValue?: string | undefined,
  onValueChangeHandler: (value: string) => void,
  className?: string;
  customErrorMessage?: string | ReactNode | ReactNode[];
  type?: string;
  required?: boolean;
  rows?: number;
}

const LabeledTextArea = ({ labelText, defaultValue, onValueChangeHandler, className, type = 'text', required = false, customErrorMessage, ...props }: LabeledTextAreaProps) => {
  const [valid, setValid] = useState<boolean>(true);
  const [validationMessage, setValidationMessage] = useState<string | ReactNode | ReactNode[]>('');
  function onValueChange(e: ChangeEvent<HTMLTextAreaElement>) {
    handleValidity(e);
    onValueChangeHandler(e.target.value);
  }
  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = (e: FocusEvent<HTMLTextAreaElement>) => {
    handleValidity(e);
  };
  const handleValidity = (e: FocusEvent<HTMLTextAreaElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const isValid = e.target.checkValidity();
    setValid(isValid);
    if (!isValid) {
      if (customErrorMessage) {
        setValidationMessage(customErrorMessage);
      } else {
        setValidationMessage(e.target.validationMessage);
      }
    }
  };
  return (
    <>
      <Label className={className}>
        <span>{labelText}</span>
        <Textarea
          valid={valid ? undefined : false}
          onBlur={handleBlur}
          className="invalid:border-danger"
          defaultValue={defaultValue}
          onChange={e => onValueChange(e)}
          required={required}
          css=""
          {...props}
        />
        {!valid && validationMessage && <HelperText valid={false}>{validationMessage}</HelperText>}
      </Label>
    </>
  );
};

export default LabeledTextArea;
