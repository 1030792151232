import { useIntl } from 'react-intl';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import Badge from 'src/components/_common/Badge/Badge';
import useUser from 'src/hooks/user/useUser';

interface SupervisorBadgeProps {
  id: number,
}

export default function SupervisorBadge({ id }: SupervisorBadgeProps) {
  const { formatMessage } = useIntl();
  const supervisorQuery = useUser(id);

  if (supervisorQuery.isLoading) return null;
  if (supervisorQuery.isError) {
    return <ApiErrorAlert error={supervisorQuery.error} message={formatMessage({ id: 'inviteSupervisorBadgeErrorMessage' })} />;
  }

  const supervisor = supervisorQuery.data;

  return (
    <>
      <h2 className="text-center w-full mb-2 mt-1">{formatMessage({ id: 'inviteSupervisorBadgeTitle' })}</h2>
      <div className="flex justify-center mb-6">
        <Badge type="supervisor">{supervisor.displayName}</Badge>
      </div>
    </>
  );
}
