import { Form } from 'src/models/form';

export interface FormAnswer {
  formQuestionId: number;
  value?: any;
  comment?: string;
  id?: number
  answerSetId?: number
}

export interface AnswerSet {
  formId: number;
  answers: FormAnswer[];
  comments: FormAnswerComment[];
  userId?: number;
  assignmentId?: string | number;
  studentUserId?: string | number;
  subjectId?: string | number;
  isMidSemester?: boolean;
  createdAt?: string;
  id?: number;
  updatedAt?: string
}

export interface FormAnswerComment{
  id?: number;
  formQuestionId: number;
  userId?: number;
  assignmentId?: number;
  studentReferencesId?: number;
  comment?: string;
  createdAt?: string;
  updatedAt?: string;
  commentedAt?: string;
}

export class FormAnswers {
  private answers: Map<number, FormAnswer>;
  private comments: Map<number, FormAnswerComment>;

  constructor(private readonly form: Form, fromAnswerSet?: AnswerSet) {
    this.answers = new Map<number, FormAnswer>();
    this.comments = new Map<number, FormAnswerComment>();

    if (!fromAnswerSet || form.id === fromAnswerSet?.formId) {
      form.groups?.forEach(group => {
        group.questions?.forEach(question => {
          this.answers.set(question.id, {
            formQuestionId: question.id,
          });
          this.comments.set(question.id, {
            formQuestionId: question.id,
            userId: fromAnswerSet?.userId,
          });
        });
      });

      fromAnswerSet?.answers?.forEach(answer => {
        if (answer?.value === '') {
          this.answer(answer?.formQuestionId, undefined, answer.comment);
        } else if (Number.isNaN(+answer?.value)) {
          this.answer(answer?.formQuestionId, answer?.value, answer.comment);
        } else {
          this.answer(answer?.formQuestionId, +answer.value, answer.comment);
        }
      });
      fromAnswerSet?.comments?.forEach(comment => {
        if (comment?.comment) {
          this.initComment(comment?.formQuestionId, comment.comment, comment.createdAt, comment.commentedAt, comment.updatedAt);
        }
      });
    }
  }

  getAnswer(id: number) {
    return this.answers.get(id)?.value;
  }

  getComment(id: number) {
    return this.comments.get(id);
  }

  answer(id: number, value: any, comment?: string) {
    const currentAnswer = this.answers.get(id);
    if (currentAnswer) {
      currentAnswer.value = value;
      currentAnswer.comment = comment;
    } else {
      throw new Error('Invalid id when registering answer');
    }
  }

  comment(id: number, comment?: string) {
    const currentComment = this.comments.get(id);
    if (currentComment) {
      const currentDate = new Date();
      currentComment.comment = comment;
      currentComment.commentedAt = currentDate.toISOString();
    } else {
      throw new Error('Invalid id when registering comment');
    }
  }

  initComment(id: number, comment?: string, createdAt?: string, commentedAt?: string, updatedAt?: string) {
    const currentComment = this.comments.get(id);
    if (currentComment) {
      currentComment.comment = comment;
      currentComment.createdAt = createdAt;
      currentComment.commentedAt = commentedAt;
      currentComment.updatedAt = updatedAt;
    } else {
      throw new Error('Invalid id when registering comment');
    }
  }

  toAnswerSet(userId?: number, assignmentId?: string, studentUserId?: string, subjectId?: string | number, isMidSemester?: boolean): AnswerSet {
    return {
      formId: this.form.id,
      answers: Array.from(this.answers.values()),
      comments: Array.from(this.comments.values()),
      userId,
      assignmentId: assignmentId ? +assignmentId : undefined,
      studentUserId: studentUserId ? +studentUserId : undefined,
      subjectId: subjectId ? +subjectId : undefined,
      isMidSemester: isMidSemester !== undefined ? isMidSemester : undefined,
    };
  }
}

export default FormAnswers;
