import { FC, useEffect, useState } from 'react';
import { Form } from 'src/models/form';
import { useFormAnswerHandler } from 'src/context/FormAnswerHandlerContext';
import AnswersContext from 'src/hooks/useAnswers';
import { useParams, useHistory } from 'react-router-dom';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import { FormattedMessage, useIntl } from 'react-intl';
import { RoleTypes } from 'src/models/roles';
import UsersContext from 'src/hooks/useUsers';
import FormAnswers from 'src/components/FormComponent/FormAnswers';
import FormQuestionGroupComponent from './FormQuestionGroupComponent/FormQuestionGroupComponent';
import ProgressBar from './ProgressBar/ProgressBar';
import FormButtons from './FormButtons/FormButtons';
import ClassesContext from '../../hooks/useClasses';
import Spinner from '../_common/Spinner/Spinner';
import FormAdminExpectationInfo from './FormAdminExpectationInfo/FormAdminExpectationInfo';
import GradingOptionLabel, { LabelType } from './FormQuestionComponent/FormQuestionTypePicker/FormQuestionGradingComponent/GradingOptionLabel';
import FormAnswersCollection from './FormAnswersCollection';

export interface FormProps {
  form: Form,
  canDeliver?: boolean,
}

interface FormPageParams {
  formId: string,
  assignmentId?: string,
  studentUserId?: string,
  classId?: string,
}

export const FormComponent: FC<FormProps> = ({ form, canDeliver = true }: FormProps) => {
  const { formId, assignmentId, studentUserId, classId } = useParams<FormPageParams>();
  const { formatMessage } = useIntl();
  const { getUser, user: student, userLoaded: studentLoaded, signingForm, getSigningForm } = UsersContext.useContainer();
  const history = useHistory();

  const [answers, setAnswers] = useState<FormAnswers>();
  const [adminMidTermExpectations, setAdminMidTermExpectations] = useState<FormAnswers>();
  const [adminEndTermExpectations, setAdminEndTermExpectations] = useState<FormAnswers>();
  const [teacherGrades, setTeacherGrades] = useState<FormAnswers>();
  const [teacherMidTermGrades, setTeacherMidTermGrades] = useState<FormAnswers>();
  const [supervisorGrades, setSupervisorGrades] = useState<FormAnswers>();
  const [studentGrades, setStudentGrades] = useState<FormAnswers>();
  const [pages, setPages] = useState<Array<number>>([]);
  const [activePage, setActivePage] = useState<number>(1);
  const [correctAssignmentId, setCorrectAssignmentId] = useState<string | undefined>(assignmentId);
  const { location } = history;

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const answerHandler = useFormAnswerHandler();

  const { user, activeRole } = LoggedInUserContext.useContainer();
  const { currentClass, getCurrentClass, currentClassLoaded } = ClassesContext.useContainer();
  const [midTermEvaluation, setMidTermEvaluation] = useState<boolean>();

  const [canAnswer, setCanAnswer] = useState<boolean>();

  const [othersAnswersCollection, setOthersAnswersCollection] = useState<FormAnswersCollection>();
  const [othersMidTermAnswersCollection, setOthersMidTermAnswersCollection] = useState<FormAnswersCollection>();

  const {
    getGradedAnswerSetIds,
    gradedAnswerSetIds,
    gradedAnswerSetIdsLoaded,
    getRelevantAnswers,
    relevantAnswersLoaded,
    studentAnswers,
    teacherAnswers,
    supervisorAnswers,
    adminAnswers,
    midTermExpectations,
    endTermExpectations,
    setRelevantAnswersLoaded,
    getAdminAnswers,
    setGradedAnswerSetIdsLoaded,
    teacherMidTermAnswers,
    studentMidTermAnswers,
    supervisorMidTermAnswers,
    resetAnswersContext,
    othersAnswers,
    othersMidTermAnswers,
  } = AnswersContext.useContainer();

  useEffect(() => {
    if (studentUserId && (student?.id !== +studentUserId || !studentLoaded)) getUser(+studentUserId);
  }, [studentUserId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activePage]);

  useEffect(() => {
    if (classId) {
      if (currentClass?.id !== +classId) getCurrentClass(+classId);
      if (studentUserId) getGradedAnswerSetIds(+classId, +studentUserId, +formId);
      if (activeRole === RoleTypes.Student && user && midTermEvaluation !== undefined) {
        resetAnswersContext();
        getRelevantAnswers(+classId, user.id, +formId, midTermEvaluation);
        getGradedAnswerSetIds(+classId, user.id, +formId);
      }
    }
    return function cleanup() {
      resetAllAnswers();
    };
  }, [midTermEvaluation]);

  const resetAllAnswers = () => {
    setAnswers(undefined);
    setGradedAnswerSetIdsLoaded(false);
    setRelevantAnswersLoaded(false);
    setSupervisorGrades(undefined);
    setStudentGrades(undefined);
    setTeacherMidTermGrades(undefined);
    setTeacherGrades(undefined);
    setAdminMidTermExpectations(undefined);
    setAdminEndTermExpectations(undefined);
    setOthersAnswersCollection(undefined);
    setOthersMidTermAnswersCollection(undefined);
  };

  useEffect(() => {
    if (classId && midTermEvaluation !== undefined) {
      if (activeRole === RoleTypes.Admin) getAdminAnswers(+classId, +formId, midTermEvaluation);
    }
  }, [midTermEvaluation]);

  useEffect(() => {
    if (relevantAnswersLoaded) {
      setCanAnswer(true);
      if (adminAnswers?.formId === +formId) {
        setAnswers(new FormAnswers(form, adminAnswers));
      } else {
        setAnswers(new FormAnswers(form));
      }
    }
  }, [adminAnswers, relevantAnswersLoaded]);

  useEffect(() => {
    if (activeRole && gradedAnswerSetIdsLoaded && gradedAnswerSetIds && midTermEvaluation !== undefined) {
      if (classId && studentUserId) {
        resetAnswersContext();
        getRelevantAnswers(+classId, +studentUserId, +formId, midTermEvaluation);
      }
    }
  }, [activeRole, gradedAnswerSetIdsLoaded, gradedAnswerSetIds, midTermEvaluation]);

  useEffect(() => {
    if (relevantAnswersLoaded) {
      if (endTermExpectations?.formId === form.id) {
        if (activeRole !== RoleTypes.Admin) {
          setAdminEndTermExpectations(new FormAnswers(form, endTermExpectations));
          setAdminMidTermExpectations(new FormAnswers(form, midTermExpectations));
        }
      }
    }
  }, [endTermExpectations, midTermExpectations, relevantAnswersLoaded]);

  const correctAssignment = currentClass?.assignments?.find(assignment => correctAssignmentId && assignment.id === +correctAssignmentId);

  useEffect(() => {
    if (currentClassLoaded) {
      if (correctAssignment) setMidTermEvaluation(correctAssignment?.isMidSemester);
    }
  }, [currentClassLoaded]);

  useEffect(() => {
    if (relevantAnswersLoaded) {
      setOthersAnswersCollection(new FormAnswersCollection(form, othersAnswers));
      setOthersMidTermAnswersCollection(new FormAnswersCollection(form, othersMidTermAnswers));
      if (activeRole === RoleTypes.Student) {
        setCanAnswer(true);
        setSupervisorGrades(new FormAnswers(form, supervisorAnswers));
        setTeacherGrades(new FormAnswers(form, teacherAnswers));
        if (studentAnswers?.formId === +formId) {
          setAnswers(new FormAnswers(form, studentAnswers));
        } else {
          setAnswers(new FormAnswers(form));
        }
      }
      if (activeRole === RoleTypes.Advisor) {
        setStudentGrades(new FormAnswers(form, studentAnswers));
        setTeacherGrades(new FormAnswers(form, teacherAnswers));
        if (supervisorAnswers?.userId === user?.id && supervisorAnswers?.formId === +formId) {
          setCanAnswer(true);
          setAnswers(new FormAnswers(form, supervisorAnswers));
        } else {
          const others = othersAnswersCollection?.getAnswers(+user?.id!);
          setSupervisorGrades(new FormAnswers(form, supervisorAnswers));
          if (others) {
            setCanAnswer(false);
            setAnswers(others);
          } else {
            setCanAnswer(user?.id === student?.studentReferences?.advisorUserId);
            setAnswers(new FormAnswers(form));
          }
        }
      }
      if (activeRole === RoleTypes.Teacher) {
        setCanAnswer(true);
        setSupervisorGrades(new FormAnswers(form, supervisorAnswers));
        setStudentGrades(new FormAnswers(form, studentAnswers));
        if (teacherAnswers?.formId === +formId) {
          setAnswers(new FormAnswers(form, teacherAnswers));
        } else {
          setAnswers(new FormAnswers(form));
        }
      }
      setTeacherMidTermGrades(new FormAnswers(form, teacherMidTermAnswers));
    }
  }, [relevantAnswersLoaded, gradedAnswerSetIdsLoaded, studentAnswers, teacherAnswers, supervisorAnswers, teacherMidTermAnswers, othersAnswers, othersMidTermAnswers]);

  useEffect(() => {
    setPages(Array.from(Array(form.groups.length), (_, index) => index + 1));
  }, [form]);

  const [saveRequested, setSaveRequested] = useState(false);
  const [save, setSave] = useState(false);

  useEffect(() => {
    if (!saveRequested || save) return undefined;
    setIsSaving(true);
    const timer = setTimeout(() => {
      setSave(true);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [saveRequested]);

  useEffect(() => {
    if (!save) return;
    deliverAnswer();
    setSave(false);
    setSaveRequested(false);
  }, [save]);

  const deliverAnswer = async () => {
    setIsSaving(true);
    let isSuccess = false;
    if (activeRole === RoleTypes.Admin && currentClass?.id) {
      isSuccess = await answerHandler.setExpectations(answers!.toAnswerSet(user?.id, undefined, undefined, currentClass?.subjectId, midTermEvaluation), currentClass?.id);
    } else {
      isSuccess = await answerHandler.deliver(answers!.toAnswerSet(user?.id, correctAssignmentId, studentUserId));
    }
    if (!isSuccess) setSaveRequested(true);
    setIsSaving(false);
  };

  const handleSetMidTermEvaluation = (value: boolean) => {
    const newAssignmentId = currentClass?.assignments?.find(assignment => {
      if (assignment.isMidSemester === value) {
        return { ...assignment, forms: assignment.forms.filter(_form => _form.id === +formId) };
      }
      return false;
    })?.id;
    if (newAssignmentId) {
      setCorrectAssignmentId(newAssignmentId.toString());
    }
    setMidTermEvaluation(value);
  };

  useEffect(() => {
    if (assignmentId && correctAssignmentId) {
      history.replace({ pathname: correctAssignmentId, state: location.state });
    }
  }, [correctAssignmentId]);

  useEffect(() => {
    if (relevantAnswersLoaded && studentUserId && classId) {
      getSigningForm(+classId, +studentUserId);
    }
  }, [relevantAnswersLoaded]);

  if (canDeliver && (answers === undefined || !relevantAnswersLoaded)) return <Spinner />;

  const questionSchemeOrder = (groupOrder: number, questionOrder: number) => {
    let counter = 0;
    form.groups.map(group => {
      if (groupOrder >= group.order) {
        group.questions.map(question => {
          if (groupOrder !== group.order) {
            counter += 1;
          } else if (questionOrder >= question.order) {
            counter += 1;
          }
          return counter;
        });
      }
      return counter;
    });
    return counter;
  };

  if (form?.groups.length === 0) return <div><FormattedMessage id="no-form-groups" /></div>;
  if (signingForm?.isLocked) return <div className="bg-lighterGray text-darkergray p-2 rounded-lg font-semibold mb-8"><FormattedMessage id="signing-signed-info" /></div>;

  return (
    <>
      {activeRole === RoleTypes.Admin && canDeliver && currentClass?.period
        && <FormAdminExpectationInfo formName={form.name} periodName={currentClass.period.name} isMainForm={form.isMainForm} midTermEvaluation={midTermEvaluation || false} setMidTermEvaluation={handleSetMidTermEvaluation} />}
      <div className="pb-4">
        <ProgressBar pages={pages} activePage={activePage} setActivePage={setActivePage} />
        <div className="flex pt-8 justify-center">
          <div>
            <p className="mr-4 text-xs xs:text-base">
              <FormattedMessage id="expected" />:
            </p>
          </div>
          {
            form.isMainForm
            && (
              <div className="mr-4">
                <GradingOptionLabel label={formatMessage({ id: 'mid-semester' })} type={LabelType.MidSemesterExpectation} />
              </div>
            )
          }
          <div>
            <GradingOptionLabel label={formatMessage({ id: 'end-semester' })} type={LabelType.EndSemesterExpectation} />
          </div>
        </div>
        <FormQuestionGroupComponent
          questionGroup={form?.groups[activePage - 1]}
          key={form?.groups[activePage - 1]?.id}
          answers={answers}
          midTermExpectations={adminMidTermExpectations}
          endTermExpectations={adminEndTermExpectations}
          studentGrades={studentGrades}
          studentMidTermGrades={studentMidTermAnswers !== undefined ? new FormAnswers(form, studentMidTermAnswers) : undefined}
          supervisorGrades={supervisorGrades}
          supervisorMidTermGrades={supervisorMidTermAnswers !== undefined ? new FormAnswers(form, supervisorMidTermAnswers) : undefined}
          teacherMidTermGrades={teacherMidTermGrades}
          teacherGrades={teacherGrades}
          isMidSemester={!!midTermEvaluation}
          questionSchemeOrder={questionSchemeOrder}
          answersChangedEvent={() => setSaveRequested(true)}
          canAnswer={!!canAnswer}
          others={othersAnswersCollection !== undefined ? othersAnswersCollection.getOthersExcept(user?.id!) : undefined}
          othersMidTerm={othersMidTermAnswersCollection !== undefined ? othersMidTermAnswersCollection.getOthersExcept(user?.id!) : undefined}
        />
      </div>
      <FormButtons setActivePage={setActivePage} deliverAnswer={deliverAnswer} activePage={activePage} pages={pages} canDeliver={canDeliver} isSaving={isSaving} />

    </>
  );
};

export default FormComponent;
