import { useEffect, useState } from 'react';
import { useHttpClient } from 'src/context/HttpClientContext';
import { useSessionStorage } from 'src/context/SessionStorageContext';
import { useTokenProvider } from 'src/context/TokenProviderContext';
import { RoleTypes } from 'src/models/roles';
import { User } from 'src/models/user';
import { createContainer } from 'unstated-next';

interface UserRoles {
  admin: boolean;
  student: boolean;
  teacher: boolean;
  supervisor: boolean;
}

const initialUserRoles = {
  admin: false,
  student: false,
  teacher: false,
  supervisor: false,
};

/**
 * @deprecated Use hooks/user/useLoggedInUser.ts instead
 */
function useLoggedInUser() {
  const [user, setUser] = useState<User>();
  const [userRoles, setUserRoles] = useState<UserRoles>(initialUserRoles);
  const [activeRole, setActiveRole] = useState<RoleTypes | undefined>();
  const [acceptedPrivacyPolicy, setAcceptedPrivacyPolicy] = useState<boolean>(true);
  const sessionStorage = useSessionStorage();
  const token = useTokenProvider();

  useEffect(() => {
    const storageUser = sessionStorage.retrieve('user');
    if (storageUser && typeof storageUser === 'string') {
      handleSetUser(JSON.parse(storageUser));
    }
    const storeActiveRole = localStorage.getItem('activeRole');
    if (storeActiveRole && typeof storeActiveRole === 'string') {
      handleSetActiveRole(storeActiveRole as RoleTypes);
    }
  }, []);

  useEffect(() => {
    if (user) {
      const acceptedPolicyPrevious = localStorage.getItem(user.displayName);
      setAcceptedPrivacyPolicy(!!acceptedPolicyPrevious);
    }
  }, [user]);

  const handleSetRoles = (newUser: User) => newUser?.roles?.forEach(role => {
    if (role.name === RoleTypes.Admin) { setUserRoles(prevRoles => ({ ...prevRoles, admin: true })); }
    if (role.name === RoleTypes.Advisor) { setUserRoles(prevRoles => ({ ...prevRoles, supervisor: true })); }
    if (role.name === RoleTypes.Student) { setUserRoles(prevRoles => ({ ...prevRoles, student: true })); }
    if (role.name === RoleTypes.Teacher) { setUserRoles(prevRoles => ({ ...prevRoles, teacher: true })); }
  });
  const { get } = useHttpClient();

  const handleSetUser = (_user: User) => {
    sessionStorage.store('user', JSON.stringify(_user));
    setUser(_user);
    handleSetRoles(_user);
    const activeRoleFromStore = localStorage.getItem('activeRole') as RoleTypes;
    const userHasPreviousActiveRole = !!_user.roles.find(
      role => role.name === activeRoleFromStore,
    );
    if (!userHasPreviousActiveRole) {
      localStorage.removeItem('activeRole');
    }
    if (_user.roles.length === 1) {
      handleSetActiveRole(_user.roles[0].name);
    }
  };
  const handleSetActiveRole = (role: RoleTypes) => {
    localStorage.setItem('activeRole', role);
    setActiveRole(role);
  };

  const handlePrivacyPolicyClick = (accepted: boolean) => {
    if (accepted && user) {
      setAcceptedPrivacyPolicy(true);
      localStorage.setItem(user?.displayName, 'true');
    }
    if (!accepted && user) {
      localStorage.removeItem(user?.displayName);
      sessionStorage.remove('user');
      token.cancelToken();
      window.location.href = '/';
    }
  };

  const getUser = () => get({ url: `api/users/${user?.id}` }).then(response => handleSetUser(response.data as User));

  return {
    handleSetUser,
    user,
    userRoles,
    activeRole,
    handleSetActiveRole,
    getUser,
    acceptedPrivacyPolicy,
    setAcceptedPrivacyPolicy,
    handlePrivacyPolicyClick,
  };
}
const LoggedInUserContext = createContainer(useLoggedInUser);

export default LoggedInUserContext;
