import { useState } from 'react';
import { HiPlusSm } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import Button from 'src/components/_common/Button/Button';
import ErrorMessage from 'src/components/_common/ErrorMessage/ErrorMessage';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import PageSubTitle from 'src/components/_common/PageHeader/PageSubTitle';
import Spinner from 'src/components/_common/Spinner/Spinner';
import { Subject } from 'src/models/classes';
import { sortSubjectsByCodeComparer } from 'src/utils/subjectUtils';
import SubjectsContext from '../../../hooks/useSubjects';
import SubjectModal from './SubjectModal/SubjectModal';
import SubjectsTable from './SubjectsTable/SubjectsTable';

const AdminSubjects = () => {
  const { subjects, subjectsLoaded, setSubject, subjectsError, getSubjects } = SubjectsContext.useContainer();
  const { formatMessage } = useIntl();
  const subjectsText = formatMessage({ id: 'subjects' });
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  if (subjectsError?.statusCode) return <ErrorMessage {...subjectsError} />;
  if (!subjectsLoaded || !subjects) return <Spinner />;

  const sortedSubjects = [...subjects].sort(sortSubjectsByCodeComparer);

  return (
    <div>
      <PageHeader title={subjectsText}>
        <div className="text-center mt-4 mb-4">
          <Button
            onClick={() => {
              setModalOpen(true);
              setSubject(undefined);
            }}
            iconLeft={HiPlusSm}
          >
            {formatMessage({ id: 'create-new-subject' })}
          </Button>
        </div>
        <PageSubTitle>{formatMessage({ id: 'list-of-all-subjects' })}</PageSubTitle>
      </PageHeader>
      <SubjectsTable
        subjects={sortedSubjects}
        onClickRow={(e: Subject) => {
          setModalOpen(true);
          setSubject(e);
        }}
      />
      {modalOpen
        && (
          <SubjectModal
            isOpen={modalOpen}
            close={() => setModalOpen(false)}
            onSubjectCreated={() => {
              setModalOpen(false);
              getSubjects();
            }}
            onSubjectUpdated={() => {
              setModalOpen(false);
              getSubjects();
            }}
          />
        )}
    </div>
  );
};

export default AdminSubjects;
