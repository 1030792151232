import classnames from 'classnames';
import { globalKeyDownHandler } from 'src/utils/keyDownhandler';

interface ProgressBarProps {
  pages: Array<number> | undefined;
  activePage: number;
  setActivePage: (newActivePage: number) => void;
}

const ProgressBar = ({ pages, activePage, setActivePage }: ProgressBarProps) => {
  const canNavigateWithProgressBar = (pageNumber: number) => pages && activePage !== 1 && pageNumber < activePage;
  if (pages && pages?.length === 1) return <></>;
  return (
    <div className="flex flex-row pt-4 justify-center">
      {pages?.map(pageNumber => (
        <div
          key={pageNumber}
          className={classnames(
            { 'w-full': pageNumber !== 1 },
            'flex max-w-xs flex-row ',
          )}
        >
          {pageNumber !== 1 && <div className="w-full max-w-xs border-b-2 border-lightGray h-1/2 mx-2" />}
          <div
            className={classnames(
              { 'bg-primary border-primary text-white': pageNumber === activePage },
              { 'cursor-pointer': canNavigateWithProgressBar(pageNumber) },
              { 'border-lightGray': pageNumber !== activePage },
              'border-2 rounded-full',
              'px-4 py-2',
            )}
            onClick={() => setActivePage(pageNumber)}
            tabIndex={0}
            onKeyDown={e => globalKeyDownHandler(e, () => setActivePage(pageNumber))}
            data-testid={`progressbar-button-${pageNumber}`}
            role="button"
          >
            {pageNumber}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
