import { useState, useEffect } from 'react';
import { useHttpClient, HttpResponse } from 'src/context/HttpClientContext';
import { Roles, RoleTypes } from 'src/models/roles';
import { createContainer } from 'unstated-next';

function useRoles() {
  const [roles, setRoles] = useState<Array<Roles>>([]);
  const [rolesLoaded, setRolesLoaded] = useState<boolean>(false);
  const [rolesError, setRolesError] = useState<HttpResponse<any> | undefined>();

  const { get } = useHttpClient();

  const getRoles = () => get({ url: 'api/roles' })
    .then(response => handleSetRoles(response.data as Array<Roles>))
    .catch(error => setRolesError(error));

  const handleSetRoles = (_roles: Array<Roles>) => {
    setRoles(_roles);
    setRolesLoaded(true);
  };

  const findRole = (roleId: number) => roles.find((role: Roles) => role.id === roleId);
  const userHasAnyOfRoles = (roleNames: Array<RoleTypes>, userRoles?: Array<RoleTypes>) => !!userRoles?.find((role: RoleTypes) => roleNames?.includes(role));

  useEffect(() => {
    getRoles();
  }, []);

  return { roles, rolesLoaded, rolesError, findRole, handleSetRoles, userHasAnyOfRoles };
}

const RolesContext = createContainer(useRoles);

export default RolesContext;
