import { Modal, ModalHeader, ModalBody, ModalFooter } from '@windmill/react-ui';
import { FormEvent, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'src/components/_common/Button/Button';
import { Period } from 'src/models/classes';

interface CreateNewSemesterModalProps {
  isOpen: boolean,
  close: () => void,
  isSaving: boolean,
  activeSemesters?: Array<Period>
  createNewSemester: (newSemester: Period) => void
}

const CreateNewSemesterModal = ({ isOpen, close, isSaving, activeSemesters, createNewSemester }: CreateNewSemesterModalProps) => {
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const nextSemester = findNextSemester();
    createNewSemester(nextSemester as Period);
  };
  const findNextSemester = () => {
    const nextSemester: Partial<Period> = {};
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();

    if (activeSemesters!?.length > 0) {
      if (activeSemesters?.[0].name.includes('Høst')) {
        nextSemester.name = `Vår ${currentYear + 1}`;
        nextSemester.startDate = `${currentYear + 1}-01-01`;
        nextSemester.endDate = `${currentYear + 1}-06-30`;
      } else {
        nextSemester.startDate = `${currentYear}-07-01`;
        nextSemester.endDate = `${currentYear}-12-31`;
        nextSemester.name = `Høst ${currentYear}`;
      }
    } else {
      const isAutumn = currentMonth >= 6;
      if (isAutumn) {
        nextSemester.startDate = `${currentYear}-07-01`;
        nextSemester.endDate = `${currentYear}-12-31`;
        nextSemester.name = `Høst ${currentYear}`;
      } else {
        nextSemester.name = `Vår ${currentYear}`;
        nextSemester.startDate = `${currentYear}-01-01`;
        nextSemester.endDate = `${currentYear}-06-30`;
      }
    }
    return nextSemester;
  };

  return (
    <Modal isOpen={isOpen} onClose={() => { }} style={{ margin: 'auto' }}>
      <form onSubmit={e => handleSubmit(e)}>
        <ModalHeader>
          <FormattedMessage id="create-next-semester" />
        </ModalHeader>
        <ModalBody data-testid={findNextSemester().name}>
          <FormattedMessage id="new-semester-confirmation" values={{ semesterName: findNextSemester().name }} />
        </ModalBody>
        <ModalFooter>
          <Button className="w-full sm:w-auto" layout="outline" onClick={close}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button type="submit" text={<FormattedMessage id="create" />} className="w-full sm:w-auto" isSaving={isSaving} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateNewSemesterModal;
