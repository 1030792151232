enum ProvidedServices {
  FormProvider,
  SessionStorage,
  HttpClient,
  Authenticator,
  TokenProvider,
  FormAnswerHandler,
}

export default ProvidedServices;
