import { useMutation } from '@tanstack/react-query';
import { simpleSignIn, verifyOneTimePassword, VerifyOneTimePasswordParameters } from 'src/api/authAPI';
import ApiError from 'src/api/errorHandling/ApiError';

export function useSimpleSignIn(onSuccess: () => void) {
  return useMutation<void, ApiError, string>({
    mutationFn: simpleSignIn,
    onSuccess,
  });
}

export function useVerifyOneTimePassword(onSuccess: (token: string) => void) {
  return useMutation<string, ApiError, VerifyOneTimePasswordParameters>({
    mutationFn: verifyOneTimePassword,
    onSuccess,
  });
}
