import { Subject } from 'src/models/classes';

// eslint-disable-next-line import/prefer-default-export
export function sortSubjectsByCodeComparer(a: Subject, b: Subject) {
  const codeA = a.subjectCode;
  const codeB = b.subjectCode;

  if (codeA === undefined || codeB === undefined) {
    throw new Error(`Unable to sort subjects by code. One or more of the subjects does not contain code. \nSubject A: ${a}\nSubject B: ${b}`);
  }

  return codeA.localeCompare(codeB, undefined, { numeric: true });
}
