import { EditableFormQuestionGroup } from 'src/models/editableForm';
import Select from 'src/components/_common/Select/Select';
import { useIntl } from 'react-intl';
import AdminFormQuestionList from './AdminFormQuestionList/AdminFormQuestionList';

interface AdminFormGroupProps {
  group: EditableFormQuestionGroup;
  changeQuestionType: (groupOrder: number, newQuestionType: any) => void
}

const AdminFormGroup = ({ group, changeQuestionType }: AdminFormGroupProps) => {
  const { formatMessage } = useIntl();

  const groupQuestionTypeOptions = [
    { value: 1, label: formatMessage({ id: 'question-type-1' }) },
    { value: 2, label: formatMessage({ id: 'question-type-2' }) },
  ];

  return (
    <>
      <div className="flex flex-col gap-">
        <Select
          label=""
          aria-label={formatMessage({ id: 'selectQuestionGroupType' })}
          className="pb-8"
          options={groupQuestionTypeOptions}
          value={{ value: group?.type, label: formatMessage({ id: `question-type-${group?.type}` }) }}
          onChange={(e: any) => changeQuestionType(group?.order, e.value)}
        />
        <div className="border-t border-lightGray pt-8">
          <AdminFormQuestionList groupOrder={group?.order as number} questions={group?.questions} />
        </div>
      </div>
    </>
  );
};

export default AdminFormGroup;
