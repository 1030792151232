import { Switch, Route } from 'react-router-dom';
import AuthenticatePage from './Authenticate/AuthenticatePage';
import FeideSuccessPage from './FeideSuccessPage/FeideSuccessPage';
import EnterEmailPage from './OneTimePassword/EnterEmailPage';
import EnterOneTimePasswordPage from './OneTimePassword/EnterPasswordPage';
import EnterEmailSimplePage from './SimpleSignIn/EnterEmailPage';
import SimpleSignInPage from './SimpleSignIn/EnterPasswordPage';
import VippsSuccessPage from './VippsSuccessPage/VippsSuccessPage';
import SupervisorInvitationAcceptPage from './SupervisorInvitationAccept/SupervisorInvitationAcceptPage';

const NotAuthenticatedRouter = () => (
  <Switch>
    <Route path="/feide-success">
      <FeideSuccessPage />
    </Route>
    <Route path="/vipps-success">
      <VippsSuccessPage />
    </Route>
    <Route exact path="/login">
      <EnterEmailPage />
    </Route>
    <Route exact path="/login-simple">
      <EnterEmailSimplePage />
    </Route>
    <Route exact path="/otp">
      <EnterOneTimePasswordPage />
    </Route>
    <Route exact path="/otp-simple">
      <SimpleSignInPage />
    </Route>
    <Route exact path="/supervisor/accept-invitation">
      <SupervisorInvitationAcceptPage />
    </Route>
    <Route>
      <AuthenticatePage data-testid="main-authenticate-page" />
    </Route>
  </Switch>
);
export default NotAuthenticatedRouter;
