import { Input, Label } from '@windmill/react-ui';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EditableFormQuestion } from 'src/models/editableForm';
import EditableInput from 'src/components/_common/EditableInput/EditableInput';

const AdminFormQuestionOptions = ({ question, handleSetQuestionHelpText }: {question: EditableFormQuestion, handleSetQuestionHelpText: (helpText: string, questionOrder: number) => void}) => {
  const [showCommentField, toggleShowCommentField] = useState<boolean>(!!question?.helpText);

  useEffect(() => {
    if (!showCommentField)handleSetQuestionHelpText('', question?.order);
  }, [showCommentField]);

  return (
    <div className="contents">
      <Label>
        <Input type="checkbox" css="" defaultChecked={!!question?.helpText} value="supervisor-text" onChange={() => toggleShowCommentField(!showCommentField)} />
        <span className="text-black"><FormattedMessage id="supervisor-text" /></span>
      </Label>
      {showCommentField
        && (
        <Label className="mt-4">
          <span><FormattedMessage id="edit-supervisor-text" /></span>
          <EditableInput
            text={question?.helpText}
            active
            onChangeHandler={value => handleSetQuestionHelpText(value, question?.order)}
          />
        </Label>
        )}
    </div>
  );
};

export default AdminFormQuestionOptions;
