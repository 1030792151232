import React from 'react';
import { FormAnswerHandlerContext, IFormAnswerHandler } from 'src/context/FormAnswerHandlerContext';
import { useHttpClient } from 'src/context/HttpClientContext';
import { AnswerSet } from 'src/components/FormComponent/FormAnswers';
import ToastsContext from 'src/hooks/useToasts';
import { ContextProps } from '../GlobalServices';

export const FormAnswerHandlerService: React.FC<ContextProps> = ({ children }: ContextProps) => {
  const formUrl = 'api/answers';
  const httpClient = useHttpClient();
  const { setErrorToast, setShowSuccessToast } = ToastsContext.useContainer();

  const answerHandler: IFormAnswerHandler = {
    async deliver(answers: AnswerSet) {
      return attemptDeliver(convertAnswerSetValuesToString(answers)).then(() => {
        setShowSuccessToast(true);
        return true;
      }).catch(error => {
        setErrorToast(error);
        return false;
      });
    },
    async setExpectations(answers: AnswerSet, classId: number) {
      return attemptSetExpectations(convertAnswerSetValuesToString(answers), classId).then(() => {
        setShowSuccessToast(true);
        return true;
      }).catch(error => {
        setErrorToast(error);
        return false;
      });
    },
  };

  const attemptSetExpectations = async (answers: AnswerSet, classId: number) => {
    await httpClient.post<void>({
      url: `api/classes/${classId}/expectations`,
      data: {
        ...answers,
        classId,
      },
    });
    return true;
  };

  const attemptDeliver = async (answers: AnswerSet) => {
    await httpClient.post<void>({
      url: formUrl,
      data: answers,
    });
    return true;
  };

  function convertAnswerSetValuesToString(answerSet: AnswerSet) : AnswerSet {
    const mappedAnswers = answerSet.answers.map(a => {
      if (typeof a.value === 'number') {
        return {
          ...a,
          value: a.value.toString(),
        };
      }
      return a;
    });
    return {
      ...answerSet,
      answers: mappedAnswers,
    };
  }

  return (
    <FormAnswerHandlerContext.Provider value={answerHandler}>
      { children }
    </FormAnswerHandlerContext.Provider>
  );
};

export default FormAnswerHandlerService;
