import { IconType } from 'react-icons';
import { FC } from 'react';
import { Button } from '@windmill/react-ui';

export interface IAccordionIcon {
  icon: IconType;
  onClick: () => void;
  label: string;
  iconColor?: string
}

export interface AccordionIconProps {
  icon: IAccordionIcon;
}

const AccordionIcon: FC<AccordionIconProps> = ({ icon }: AccordionIconProps) => (
  <Button
    className={`${icon.iconColor}`}
    icon={icon.icon}
    aria-label={icon.label}
    data-testid="accordion-icon"
    layout="link"
    size="larger"
    onClick={icon.onClick}
  />
);

export default AccordionIcon;
