/* eslint-disable react/no-danger */
import { useIntl } from 'react-intl';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';

export default function PrivacyPolicyPage() {
  const { formatMessage } = useIntl();

  function getPrivacyPolicyHeader() {
    return formatMessage({ id: 'privacy-policy' });
  }
  function getPrivacyPolicyHtml() {
    return formatMessage({ id: 'privacy-policy-body' });
  }

  return (
    <>
      <PageHeader title={getPrivacyPolicyHeader()} />
      <div className="flex-wrap whitespace-pre-wrap privacy-policy" dangerouslySetInnerHTML={{ __html: getPrivacyPolicyHtml() }} />
    </>
  );
}
