import { useState, useEffect } from 'react';
import { HttpResponse, useHttpClient } from 'src/context/HttpClientContext';
import { createContainer } from 'unstated-next';
import { Subject } from '../models/classes';
import ToastsContext from './useToasts';

function useSubjects() {
  const { setErrorToast, setShowSuccessToast } = ToastsContext.useContainer();

  const [subjects, setSubjects] = useState<Array<Subject> |undefined>();
  const [subject, setSubject] = useState<Subject |undefined>();
  const [subjectsLoaded, setSubjectsLoaded] = useState<boolean>(false);
  const [subjectsError, setSubjectsError] = useState<HttpResponse<any> | undefined>();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleSetSubjects = (_subjects: Array<Subject>) => {
    setSubjects(_subjects);
    setSubjectsLoaded(true);
  };
  const { get, post, put } = useHttpClient();

  const getSubjects = () => get({ url: 'api/subjects' })
    .then(response => {
      handleSetSubjects(response.data as Array<Subject>);
    }).catch(error => {
      setSubjectsError(error);
    });

  const createNewSubject = async () => {
    setSubjectsError(undefined);
    setIsSaving(true);
    await post({ url: 'api/subjects', data: subject }).then(() => {
      setShowSuccessToast(true);
      getSubjects();
    })
      .catch(e => setErrorToast(e))
      .finally(() => setIsSaving(false));
  };

  const updateSubject = async () => {
    setIsSaving(true);
    await put({ url: 'api/subjects', data: subject }).then(() => {
      setShowSuccessToast(true);
      getSubjects();
    })
      .catch(e => setErrorToast(e))
      .finally(() => setIsSaving(false));
  };

  useEffect(() => {
    getSubjects();
  }, []);

  return { handleSetSubjects, subjects, getSubjects, subjectsError, subjectsLoaded, createNewSubject, subject, setSubject, updateSubject, isSaving };
}
const SubjectsContext = createContainer(useSubjects);

export default SubjectsContext;
