import { useQuery } from '@tanstack/react-query';
import ApiError from 'src/api/errorHandling/ApiError';
import { fetchMyUser } from 'src/api/userAPI';
import { User } from 'src/models/user';

export default function useLoggedInUser() {
  return useQuery<User, ApiError>({
    queryKey: ['fetchMyUser'],
    queryFn: fetchMyUser,
  });
}
