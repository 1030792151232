import axios from 'axios';
import customTransformer from './transformers/customTransformer';
import defaultTransformers from './transformers/defaultAxiosTransformers';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const TOKEN_SESSION_STORAGE_KEY = 'token';

const instance = axios.create({
  baseURL: BASE_URL,
  transformRequest: [
    customTransformer,
    ...defaultTransformers(),
  ],
});

// Attach authorization token to request header
instance.interceptors.request.use(config => {
  const configCopy = { ...config };
  const token = sessionStorage.getItem(TOKEN_SESSION_STORAGE_KEY);
  const authorizationHeader = token ? `Bearer ${JSON.parse(token)}` : '';

  configCopy.headers.Authorization = authorizationHeader;

  return configCopy;
});

// Remove token from session storage and refresh page if request fails with 401
instance.interceptors.response.use(response => {
  if (response.status === 401) {
    sessionStorage.removeItem(TOKEN_SESSION_STORAGE_KEY);
    window.location.reload();
  }

  return response;
});

export default instance;
