import { useState, useEffect } from 'react';
import { HttpResponse } from 'src/context/HttpClientContext';
import { createContainer } from 'unstated-next';

function useToasts() {
  const [showToast, setShowToast] = useState<boolean>(false);

  const [showSuccessToast, setShowSuccessToast] = useState<boolean>(false);
  const [deleteToast, setDeleteToast] = useState<boolean>(false);
  const [errorToast, setErrorToast] = useState<HttpResponse<any> | undefined>();
  const [toastMessage, setToastMessage] = useState<string | undefined>();

  const handleShowMessage = (showFunc: (value: boolean) => void) => {
    showFunc(true);
    const timeout = setTimeout(() => showFunc(false), 10000);
    return timeout;
  };

  useEffect(() => {
    if (!showToast) {
      setShowSuccessToast(false);
      setDeleteToast(false);
      setErrorToast(undefined);
      setToastMessage(undefined);
    }
  }, [showToast]);

  useEffect(() => {
    if (showSuccessToast) {
      handleShowMessage(setShowToast);
    }
  }, [showSuccessToast]);

  useEffect(() => {
    if (errorToast) {
      setShowToast(true);
    }
  }, [errorToast]);

  function showSuccessToastWithMessage(message: string) {
    setShowSuccessToast(true);
    setToastMessage(message);
    setShowToast(true);
  }

  return { setShowSuccessToast, setErrorToast, showToast, errorToast, setDeleteToast, deleteToast, setShowToast, showSuccessToast, toastMessage, showSuccessToastWithMessage };
}

const ToastsContext = createContainer(useToasts);

export default ToastsContext;
