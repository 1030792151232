export default class DateOnly extends Date {
  toShortISOString() {
    const year = this.getFullYear();
    const monthNumber = this.getMonth() + 1;
    const month = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;

    const dayNumber = this.getDate();
    const day = dayNumber < 10 ? `0${dayNumber}` : `${dayNumber}`;

    return `${year}-${month}-${day}`;
  }
}
