import { Modal, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import StudentWarningModalContent from 'src/components/StudentWarningModalContent/StudentWarningModalContent';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import Button from 'src/components/_common/Button/Button';
import { Class } from 'src/models/classes';
import StudentPassWarning from 'src/models/StudentPassWarning';
import { User } from 'src/models/user';
import { convertStringToMultilineParagraphs } from 'src/utils/stringUtils';
import { MdOutlineInfo } from 'react-icons/md';
import { useConfirmStudentPassWarningMutation } from './queries';

interface StudentWarningModalProps {
  isOpen: boolean;
  close: () => void;
  class: Class;
  warning: StudentPassWarning;
  student: User;
  teacher?: User;
  supervisor?: User;
}

export default function StudentWarningModal({ isOpen, close, class: _class, warning, student, teacher, supervisor }: StudentWarningModalProps) {
  const { formatMessage } = useIntl();

  const mutation = useConfirmStudentPassWarningMutation(onSuccess);

  function onSuccess() {
    close();
  }

  function confirmWarning() {
    mutation.mutate();
  }

  return (
    <Modal isOpen={isOpen} onClose={close} style={{ margin: 'auto' }}>
      <ModalHeader data-testid="modal-header">
        <FormattedMessage id="student-about-to-fail-warning" />
      </ModalHeader>
      <ModalBody>
        <StudentWarningModalContent
          class={_class}
          student={student}
          supervisor={supervisor}
          teacher={teacher}
          warning={warning}
          commentSlot={(
            <div className="bg-lightGray p-4 rounded-md">
              <p>
                <strong>
                  <FormattedMessage id="studentWarningModalCommentLabel" />
                </strong>
              </p>
              <p>{convertStringToMultilineParagraphs(warning?.comment ?? '')}</p>
            </div>
          )}
          passRequirementSlot={(
            <div className="bg-lightGray p-4 rounded-md">
              <p>
                <strong>
                  <FormattedMessage id="studentWarningModalCommentLabel" />
                </strong>
              </p>
              <p>{convertStringToMultilineParagraphs(warning?.passRequirement ?? '')}</p>
            </div>
          )}
          passRequirementSummarySlot={(
            <div className="bg-lightGray p-4 rounded-md">
              <p>
                <strong>
                  <FormattedMessage id="studentWarningModalCommentLabel" />
                </strong>
              </p>
              <p>{convertStringToMultilineParagraphs(warning?.passRequirementSummary ?? '')}</p>
            </div>
          )}
        />
        {mutation.isError && (
          <ApiErrorAlert error={mutation.error} message="" />
        )}
        <div className="flex mt-8">
          <div className="text-xl mr-2">
            <MdOutlineInfo />
          </div>
          <div>
            <p className="text-sm">
              <FormattedMessage id="studentWarningModalHelpText" />
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={confirmWarning}
          className="text-white"
          isSaving={mutation.isLoading}
          data-testid="confirm-button"
        >
          {formatMessage({ id: 'confirm-warning' })}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
