import { Modal, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../_common/Button/Button';
import { useDeleteStudentPassWarningMutation } from './queries';

interface DeleteModalProps{
  isOpen: boolean;
  close: () => void;
  classId: number;
  studentUserId: number;
}

export default function DeleteModal({ isOpen, close, classId, studentUserId }: DeleteModalProps) {
  const { formatMessage } = useIntl();

  const mutation = useDeleteStudentPassWarningMutation(onSuccess);

  function handleDelete() {
    mutation.mutate({
      classId,
      studentUserId,
    });
  }

  function onSuccess() {
    close();
  }

  return (
    <Modal isOpen={isOpen} onClose={close}>
      <ModalHeader data-testid="modal-header">
        <FormattedMessage id="delete-warning" />
      </ModalHeader>
      <ModalBody>
        <FormattedMessage id="delete-confirmation" />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={close}
          text={formatMessage({ id: 'close' })}
          className="bg-darkgray text-white"
          data-testid="close-button"
        />
        <Button
          onClick={handleDelete}
          text={formatMessage({ id: 'delete-warning' })}
          className="text-white"
          isSaving={mutation.isLoading}
          data-testid="delete-button"
        />
      </ModalFooter>
    </Modal>
  );
}
