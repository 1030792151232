import { Label } from '@windmill/react-ui';
import { useState, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import ClassesContext from 'src/hooks/useClasses';
import { Class } from 'src/models/classes';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import { RoleTypes } from 'src/models/roles';
import Spinner from '../_common/Spinner/Spinner';

const ClassDates = () => {
  const { currentClass, currentClassLoaded, updateClass } = ClassesContext.useContainer();
  const { classId } = useParams<{classId: string}>();
  const { activeRole } = LoggedInUserContext.useContainer();

  const [isAdmin, setIsAdmin] = useState<boolean>(activeRole === RoleTypes.Admin);

  const [midSemesterDate, setMidSemesterDate] = useState<string>(currentClass?.midSemesterDate ?? '');
  const [endSemesterDate, setEndSemesterDate] = useState<string>(currentClass?.endSemesterDate ?? '');

  if (!currentClassLoaded || currentClass?.id !== +classId) return <Spinner />;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'midSemester' && currentClass) {
      setMidSemesterDate(e.target.value);
    }
    if (e.target.name === 'endSemester' && currentClass) {
      setEndSemesterDate(e.target.value);
    }
  };
  const handleBlur = () => {
    if (currentClass) {
      let newClass: Class = currentClass;
      if (endSemesterDate) {
        newClass = { ...currentClass, endSemesterDate };
      }
      if (midSemesterDate) {
        newClass = { ...currentClass, midSemesterDate };
      }
      if (midSemesterDate && endSemesterDate) {
        newClass = { ...currentClass, midSemesterDate, endSemesterDate };
      }
      updateClass(newClass);
    }
  };
  return (
    <div className="max-w-sm m-auto mb-6">
      <Label className="flex flex-col">
        <span className="text-customGray">
          <FormattedMessage id="end-semester-period" />
        </span>
        <input
          data-testid="end-semester-date"
          name="endSemester"
          className="border-0 shadow-tableShadow w-full"
          onBlur={handleBlur}
          onChange={handleChange}
          defaultValue={currentClass?.endSemesterDate}
          type="date"
          disabled={!isAdmin}
        />
      </Label>
    </div>
  );
};

export default ClassDates;
