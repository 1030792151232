import { Input, Label } from '@windmill/react-ui';
import { FormEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from 'src/components/_common/Spinner/Spinner';
import { useFormProvider } from 'src/context/FormProviderContext';
import useAbortableEffect from 'src/hooks/useAbortableEffect';
import FormContext from 'src/hooks/useFormContext';
import EditableForm from 'src/models/editableForm';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'src/components/_common/Button/Button';
import FormComponent from 'src/components/FormComponent/FormComponent';
import { Form } from 'src/models/form';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import AdminFormEditorDetails from './AdminFormEditorDetails/AdminFormEditorDetails';
import AdminFormGroupList from './AdminFormGroupList/AdminFormGroupList';

interface FormEditorParams {
  formId: string | undefined;
}

const AdminFormEditor = () => {
  const { formId } = useParams<FormEditorParams>();
  const history = useHistory();
  const formProvider = useFormProvider();
  const [previewActive, setPreviewActive] = useState<boolean>(false);

  const { form, setInitialForm, formLoaded, setFormName, setFormLoaded, toggleMainForm } = FormContext.useContainer();
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useAbortableEffect(async (status: { aborted: boolean }) => {
    setFormLoaded(false);
    const numberedParameter = formId ? Number.parseInt(formId, 10) : undefined;
    if (numberedParameter && !Number.isNaN(numberedParameter)) {
      const retrievedForm = await formProvider.getForm(numberedParameter);
      if (!status.aborted && retrievedForm) {
        setInitialForm(retrievedForm);
      }
    } else {
      setInitialForm(new EditableForm());
    }
  }, [formId]);

  useEffect(() => () => {
    setInitialForm(new EditableForm());
  }, []);

  const onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!form.id) {
      await createNewForm(form);
    } else {
      await updateForm(form.id, form);
    }
  };

  const createNewForm = async (_form: EditableForm) => {
    setIsSaving(true);
    const createdFormId = await formProvider.createForm(_form);
    setIsSaving(false);

    if (createdFormId === undefined) return;

    history.push(`/admin/forms/edit/${createdFormId}`);
  };

  const updateForm = async (id: number, _form: EditableForm) => {
    setIsSaving(true);
    await formProvider.updateForm(id, _form);
    setIsSaving(false);
  };

  if (!formLoaded) return <Spinner />;
  return (
    <>
      {form && formLoaded && (
        <div className="flex w-full">
          <form className="flex flex-col justify-center m-5 w-full" onSubmit={onSubmit}>
            <PageHeader>
              <div className="border-b border-lightGray">
                <AdminFormEditorDetails formName={form.name ?? ''} setFormName={setFormName} />
                <div className="text-center text-customGray">
                  <Button
                    className={`${!previewActive && 'text-primary font-bold'}`}
                    layout="link"
                    onClick={() => setPreviewActive(false)}
                  >
                    <FormattedMessage id="edit" />
                  </Button>
                  <Button
                    className={`${previewActive && 'text-primary font-bold'}`}
                    layout="link"
                    onClick={() => setPreviewActive(true)}
                  >
                    <FormattedMessage id="preview" />
                  </Button>
                </div>
              </div>
            </PageHeader>

            {previewActive
              ? (
                <FormComponent
                  form={form as Form}
                  canDeliver={false}
                />
              )
              : (
                <>
                  <div>
                    <Label className="block pb-8">
                      <Input type="checkbox" css="" data-testid="main-form-toggle" value="isMainForm" onChange={() => toggleMainForm(!form.isMainForm)} checked={form.isMainForm} />
                      <span><FormattedMessage id="main-form" /></span>
                    </Label>
                  </div>
                  <div className="mb-4">
                    <AdminFormGroupList />
                  </div>
                  <Button type="submit" isSaving={isSaving} />
                </>
              )}
          </form>
        </div>
      )}
    </>
  );
};

export default AdminFormEditor;
