import { AnswerSet } from 'src/components/FormComponent/FormAnswers';
import Contextualizer from 'src/context/Contextualizer/Contextualizer';
import ProvidedServices from 'src/context/Contextualizer/ProvidedServices';

export interface IFormAnswerHandler {
  deliver(answers: AnswerSet): Promise<boolean>;
  setExpectations(answers: AnswerSet, classId: number): Promise<boolean>;
}

export const FormAnswerHandlerContext = Contextualizer.createContext<IFormAnswerHandler>(ProvidedServices.FormAnswerHandler);
export const useFormAnswerHandler = () => Contextualizer.use<IFormAnswerHandler>(ProvidedServices.FormAnswerHandler);
