import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import CardWithTitle from 'src/components/_common/CardWithTitle/CardWithTitle';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import VippsLoginButton from './VippsLoginButton';
import FeideLoginButton from './FeideLoginButton';

const AuthenticatePage: FC = () => {
  const { formatMessage } = useIntl();
  const { search } = useLocation();
  const verificationGuid = new URLSearchParams(search).get('verificationGuid') ?? null;
  const query = verificationGuid ? `?verificationGuid=${verificationGuid}` : '';
  return (
    <>
      <PageHeader title={formatMessage({ id: 'app-name' })} subTitle={formatMessage({ id: 'authenticatePageSubTitle' })} />

      <CardWithTitle title={formatMessage({ id: 'login' })}>
        <p className="mb-2">{formatMessage({ id: 'authenticatePageAsStudentOrTeacherLabel' })}</p>
        <FeideLoginButton />

        <p className="mt-8 mb-2">{formatMessage({ id: 'authenticatePageAsSupervisorLabel' })}</p>

        <VippsLoginButton />

        <p className="mt-16 mb-6 text-center text-darkergray">
          {formatMessage({ id: 'authenticatePageDontHaveVippsLabel' })}
            &nbsp;
          <Link to={`/login${query}`} className="text-primary">{formatMessage({ id: 'authenticatePageLoginWithOneTimePasswordLabel' })}</Link>
        </p>
        <p className="text-center text-darkgray text-sm font-semibold">
          {formatMessage({ id: 'organizationNumberShortLabel' })}
            &nbsp;
          {formatMessage({ id: 'organizationNumber' })}
        </p>
      </CardWithTitle>
    </>
  );
};

export default AuthenticatePage;
