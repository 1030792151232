import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Label } from '@windmill/react-ui';
import { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'src/components/_common/Select/Select';
import { useFormProvider } from 'src/context/FormProviderContext';
import { Form } from 'src/models/form';
import { useParams } from 'react-router-dom';
import { AssignmentForm } from 'src/models/classes';
import ClassesContext from 'src/hooks/useClasses';
import Button from 'src/components/_common/Button/Button';

export interface AssignmentFormWithAssignmentId extends AssignmentForm{
  assignmentId: number
}

const AddClassFormModal = ({
  isOpen,
  onClose,
  subjectCode,
  classForms,
}: {isOpen: boolean, onClose: () => void, subjectCode?: string, classForms?: Array<AssignmentFormWithAssignmentId> }) => {
  const { getAllForms } = useFormProvider();
  const { formatMessage } = useIntl();
  const [forms, setForms] = useState<Form[] | undefined>(undefined);
  const [selectedForms, setSelectedForms] = useState<any>(undefined);
  const { addFormsToClass, isSaving, getCurrentClass } = ClassesContext.useContainer();

  const { classId } = useParams<{classId: string}>();

  const getForms = async () => {
    const retrievedForms = (await getAllForms()).sort((a: Form, b: Form) => (a.name > b.name ? 1 : -1));
    setForms(retrievedForms);
  };

  useEffect(() => {
    getForms();
  }, []);

  useEffect(() => {
    if (classForms) setSelectedForms(classForms.map(classForm => ({ value: classForm.id, label: classForm.name, isMainForm: classForm.isMainForm })));
  }, [classForms]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const selectedFormIds = selectedForms.map((selectedForm: any) => selectedForm.value);
    await addFormsToClass(selectedFormIds, +classId);
    getCurrentClass(+classId);
  };
  const selectedFormIds = selectedForms?.map((selectedForm: any) => selectedForm.value);

  const mainForms = forms?.filter(form => form.isMainForm);
  const otherForms = forms?.filter(form => !form.isMainForm);

  return (
    <Modal isOpen={isOpen} onClose={onClose} style={{ margin: 'auto' }}>
      <form onSubmit={handleSubmit}>
        <ModalHeader className="pb-4">
          <FormattedMessage id="select-schemes" values={{ name: subjectCode }} />
        </ModalHeader>
        <ModalBody>
          <div className="contents">
            {mainForms?.map(form => (
              <div key={form.id} className="mb-4">
                <Label>
                  <Input
                    type="checkbox"
                    css=""
                    defaultChecked={selectedFormIds.includes(form.id)}
                    value={form.id}
                    onChange={e => {
                      if (e.target.checked) {
                        setSelectedForms((prevForms: any) => (prevForms ? [...prevForms, { label: form.name, value: form.id, isMainForm: form.isMainForm }] : [{ label: form.name, value: form.id, isMainForm: form.isMainForm }]));
                      } else {
                        setSelectedForms((prevForms: any) => prevForms?.filter((prevForm: any) => prevForm.value !== form.id));
                      }
                    }}
                  />
                  <span className="text-black">{form.name}
                  </span>
                </Label>
              </div>
            ))}
            <Select
              label={formatMessage({ id: 'specific-compentencies' })}
              value={selectedForms?.filter((selectedForm: any) => !selectedForm.isMainForm)}
              options={otherForms?.map(form => ({ label: form.name, value: form.id, isMainForm: form.isMainForm }))}
              onChange={e => setSelectedForms((prevForms: any) => {
                const prevMainForms = prevForms.filter((prevForm: any) => prevForm.isMainForm);
                return [...prevMainForms, ...e];
              })}
              isMulti
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button data-testid="close-modal-button" className="w-full sm:w-auto" layout="outline" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button data-testid="save-button" type="submit" className="w-full sm:w-auto" isSaving={isSaving} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddClassFormModal;
