import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import ResponsAlert from 'src/components/_common/Alert/ResponsAlert';
import { useIntl } from 'react-intl';
import Spinner from 'src/components/_common/Spinner/Spinner';
import JoinClassForm from './JoinClassForm';
import { useClassesMatchingAffiliationsQuery } from './queries';

export default function JoinClassPage() {
  const matchingClasses = useClassesMatchingAffiliationsQuery();
  const { formatMessage } = useIntl();

  if (matchingClasses.isLoading) {
    return <div data-testid="loading-container"><Spinner /></div>;
  }

  if (matchingClasses.isError) {
    return (
      <ApiErrorAlert
        error={matchingClasses.error}
        message={formatMessage({ id: 'studentJoinClassFetchClassErrorMessage' })}
      />
    );
  }

  if (matchingClasses.data.length === 0) {
    return (
      <div data-testid="no-classes-container">
        <ResponsAlert
          type="warning"
          title={formatMessage({ id: 'studentJoinClassNoClassesTitle' })}
          message={formatMessage({ id: 'studentJoinClassNoClassesMessage' })}
        />
      </div>
    );
  }

  return (
    <>
      <h1>
        {formatMessage({ id: 'studentJoinClassPageTitle' })}
      </h1>
      <JoinClassForm classes={matchingClasses.data} />
    </>
  );
}
