import { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { fetchSigningFormByClassAndStudent } from 'src/api/signingFormAPI';
import ToastsContext from 'src/hooks/useToasts';
import { Class } from 'src/models/classes';
import SigningForm from 'src/models/SigningForm';
import { User } from 'src/models/user';
import DateOnly from 'src/models/common/DateOnly';
import ResponsAlert from '../_common/Alert/ResponsAlert';
import ApiErrorAlert from '../_common/ApiErrorAlert/ApiErrorAlert';
import Button from '../_common/Button/Button';
import ConfirmSignModal from './ConfirmSignModal';
import MidSemesterFormFields from './MidSemesterFormFields';
import EndSemesterFormFields from './EndSemesterFormFields';
import { useCancelSigningFormMutation, useSaveSigningFormMutation, useSignSigningFormMutation } from './hooks';
import { midsemesterFormIsDisabled, endsemesterFormIsDisabled, getDefaultSigningForm, isTeacher, loggedInUserCanSign, showSaveButton, showSignButton, signedByAll, signedByLoggedInUser, teacherWaitingForOtherSignatures, signingIsUnavailable, endsemesterFormsActive } from './utils';
import ConfirmCancelModal from './ConfirmCancelModal';
import EndSemesterAdditionalFormFields from './EndSemesterAdditionalFormFields';
import EndSemesterTeacherWhoHasSignedCard from './EndSemesterStudentAdvisorSignButton';

interface SigningFormFormProps {
  existingSigningForm: SigningForm | null;
  loggedInUser: User;
  class: Class;
  student: User;
}

export default function SigningFormForm({ existingSigningForm, loggedInUser, class: _class, student }: SigningFormFormProps) {
  const [form, setForm] = useState(existingSigningForm !== null ? existingSigningForm : getDefaultSigningForm(student));
  const [confirmSignModalOpen, setConfirmSignModalOpen] = useState(false);
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { showSuccessToastWithMessage } = ToastsContext.useContainer();
  const [midSemesterFormDisabled, setMidSemesterFormDisabledVariable] = useState(midsemesterFormIsDisabled(form, loggedInUser));
  const [endSemesterFormDisabled, setEndSemesterFormDisabledVariable] = useState(endsemesterFormIsDisabled(form, loggedInUser));
  const [endSemesterSignButtonDisabled, setEndSemesterSignButtonDisabled] = useState(signingIsUnavailable(form, loggedInUser));
  const [endSemesterFormsActive, setEndSemesterFormsActive] = useState(endsemesterFormsActive(form));
  const [endSemesterToSignButtonDisabled, setEndSemesterToSignButtonDisabled] = useState(disableSignButton());

  const saveMutation = useSaveSigningFormMutation();
  const signMutation = useSignSigningFormMutation();
  const cancelFormMutation = useCancelSigningFormMutation();

  useEffect(() => {
    setEndSemesterToSignButtonDisabled(disableSignButton());
  }, [existingSigningForm]);

  function disableSignButton() {
    return existingSigningForm! && (existingSigningForm.endSemesterCompletedDate !== null && existingSigningForm.generalCompetenceAreasPassed !== null && existingSigningForm.specificCompetenceAreasPassed !== null);
  }

  async function refetchForm() {
    const updatedForm = await fetchSigningFormByClassAndStudent(_class.id, student.id);
    setForm(updatedForm);
    updateFormDisabledStatus(updatedForm);
  }

  function updateFormDisabledStatus(updatedForm: SigningForm) {
    setMidSemesterFormDisabledVariable(midsemesterFormIsDisabled(updatedForm, loggedInUser));
    setEndSemesterFormDisabledVariable(endsemesterFormIsDisabled(updatedForm, loggedInUser));
    setEndSemesterSignButtonDisabled(signingIsUnavailable(updatedForm, loggedInUser));
    setEndSemesterFormsActive(endsemesterFormsActive(updatedForm));
  }

  async function save() {
    await saveMutation.mutateAsync({ signingForm: form, classId: _class.id, studentUserId: student.id });
    await refetchForm();
    showSuccessToastWithMessage(formatMessage({ id: 'signingFormSaved' }));
  }

  function cancelSigningForm() {
    setConfirmCancelModalOpen(true);
  }
  async function confirmCancelSigningForm() {
    await cancelFormMutation.mutateAsync({ classId: _class.id, studentUserId: student.id });
    const newForm = getDefaultSigningForm(student);
    setForm(newForm);
    updateFormDisabledStatus(newForm);
    showSuccessToastWithMessage(formatMessage({ id: 'signingFormCancelled' }));
  }

  function validateFormToSign(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (isTeacher(loggedInUser)) {
      // Trigger form validation
      if (!e.currentTarget.checkValidity()) return;
    }
    setConfirmSignModalOpen(true);
  }

  async function confirmSign() {
    if (isTeacher(loggedInUser)) {
      await saveMutation.mutateAsync({ signingForm: form, classId: _class.id, studentUserId: student.id });
    }
    await signMutation.mutateAsync({ classId: _class.id, studentUserId: student.id });
    await refetchForm();
  }

  function signButton(formattedMessageId: string) {
    return showSignButton(form, loggedInUser) && (
      <div style={{ flexGrow: 1 }}>
        <Button
          className="w-full"
          type="submit"
          isSaving={signMutation.isLoading}
          disabled={signMutation.isLoading || !loggedInUserCanSign(form, loggedInUser) || endSemesterSignButtonDisabled || !endSemesterToSignButtonDisabled}
        >
          <FormattedMessage id={formattedMessageId} />
        </Button>
        <hr className="mt-4 mb-4" />
      </div>
    );
  }

  async function validateFormToPrepareSign(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (isTeacher(loggedInUser)) {
      if (e.currentTarget.checkValidity()) {
        if (form.endSemesterCompletedDate === null) {
          form.endSemesterCompletedDate = new DateOnly();
        }
        await save();
      }
    }
  }

  async function validateFormToSave(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (isTeacher(loggedInUser)) {
      // Trigger form validation
      if (e.currentTarget.checkValidity()) {
        await save();
      }
    }
  }

  return (
    <>
      <form onSubmit={e => validateFormToSave(e)}>
        <MidSemesterFormFields disabled={midSemesterFormDisabled} form={form} setForm={setForm} />
        <div className="flex w-full mt-12">
          {showSaveButton(form, loggedInUser) && (
            <>
              <div style={{ flexGrow: 1 }}>
                <Button
                  className="w-full"
                  type="submit"
                  isSaving={saveMutation.isLoading}
                  disabled={saveMutation.isLoading || midSemesterFormDisabled}
                >
                  {!midSemesterFormDisabled ? (
                    <FormattedMessage id="signingFormMidSemesterConfirm" />
                  ) : (
                    <>
                      {endSemesterFormsActive ? (
                        <FormattedMessage id="signingFormMidSemesterConfirmedEndSemesterActive" />
                      ) : (
                        <FormattedMessage id="signingFormMidSemesterConfirmedMidSemesterActive" />
                      )}
                    </>
                  )}
                </Button>
              </div>
              <div className="w-4" />
            </>
          )}
        </div>
      </form>
      <form onSubmit={e => validateFormToPrepareSign(e)}>
        <EndSemesterFormFields disabled={endSemesterFormDisabled} form={form} setForm={setForm} />
        <hr className="mt-4 mb-4" />
        {isTeacher(loggedInUser) && !endSemesterToSignButtonDisabled ? (
          <div style={{ flexGrow: 1 }}>
            <Button
              className="w-full"
              type="submit"
              isSaving={saveMutation.isLoading}
              disabled={saveMutation.isLoading || endSemesterFormDisabled}
            >
              <FormattedMessage id="signingFormToSign" />
            </Button>
            <hr className="mt-4 mb-4" />
          </div>
        ) : (
          <EndSemesterTeacherWhoHasSignedCard form={form} loggedInUser={loggedInUser} endSemesterSignButtonDisabled={endSemesterSignButtonDisabled} signMutation={signMutation} />
        )}
      </form>
      <form onSubmit={e => validateFormToSign(e)}>
        {!isTeacher(loggedInUser) && signButton('signingFormEndSemesterSignConfirm')}
        <EndSemesterAdditionalFormFields disabled={endSemesterFormDisabled} form={form} setForm={setForm} />
        {saveMutation.isError && <ApiErrorAlert error={saveMutation.error} message="Klarte ikke lagre." />}
        {signMutation.isError && <ApiErrorAlert error={signMutation.error} message="Klarte ikke signere." />}
        <div className="flex w-full mt-12">
          {showSaveButton(form, loggedInUser) && (
            <>
              <div style={{ flexGrow: 1 }}>
                <Button
                  className="w-full"
                  onClick={save}
                  isSaving={saveMutation.isLoading}
                  disabled={saveMutation.isLoading}
                >
                  <FormattedMessage id="save" />
                </Button>
              </div>
              <div className="w-4" />
            </>
          )}
          <div style={{ flexGrow: 1 }}>
            {isTeacher(loggedInUser) && (
              <Button
                className="w-full"
                onClick={cancelSigningForm}
                isSaving={cancelFormMutation.isLoading}
                disabled={cancelFormMutation.isLoading}
              >
                <FormattedMessage id="signingFormCancel" />
              </Button>
            )}
          </div>
          <div className="w-4" />
          {isTeacher(loggedInUser) && signButton('sign')}
        </div>
        {signedByLoggedInUser(form, loggedInUser) && !signedByAll(form) && (
          <ResponsAlert type="success" title={formatMessage({ id: 'signingFormTitleYouHaveSigned' })} message={formatMessage({ id: 'signingFormYouHaveSigned' })} />
        )}
        {signedByAll(form) && (
          <ResponsAlert type="success" title={formatMessage({ id: 'signingFormTitleSignedByAll' })} message={formatMessage({ id: 'signingFormSignedByAll' })} />
        )}
      </form>
      <ConfirmSignModal isOpen={confirmSignModalOpen} close={() => setConfirmSignModalOpen(false)} confirm={confirmSign} teacherWaitingForOtherSignatures={teacherWaitingForOtherSignatures(form, loggedInUser)} />
      <ConfirmCancelModal isOpen={confirmCancelModalOpen} close={() => setConfirmCancelModalOpen(false)} confirm={confirmCancelSigningForm} />
    </>
  );
}
