export enum QuestionType { Unused, TextInput, Grading }

export interface Form {
  id: number;
  name: string;
  isMainForm: boolean;
  groups: FormQuestionGroup[];
}

export interface FormQuestionGroup {
  id: number;
  formId: number;
  name: string;
  questions: FormQuestion[];
  type: QuestionType;
  options?: any;
  order: number;
}

export interface FormQuestion {
  id: number;
  formQuestionGroupId: number;
  text: string;
  order: number;
  helpText?: string;
}
