import { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import Spinner from 'src/components/_common/Spinner/Spinner';
import UsersContext from 'src/hooks/useUsers';
import { Roles, RoleTypes } from 'src/models/roles';
import Button from 'src/components/_common/Button/Button';
import { Alert, HelperText, Input, Label } from '@windmill/react-ui';
import { Class, SimpleUser } from 'src/models/classes';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import { UserReferences } from '../../../../../models/user';
import RoleSelect from './RoleSelect';
import ClassSelect from './ClassSelect';
import TeacherSelect from './TeacherSelect';
import SupervisorSelect from './SupervisorSelect';

const User = () => {
  const { userLoaded, updateUser, user, getUser, isSaving } = UsersContext.useContainer();

  const [roles, setRoles] = useState<Roles[]>([]);
  const [classes, setClasses] = useState<Class[]>([]);
  const [teacher, setTeacher] = useState<SimpleUser>();
  const [supervisor, setSupervisor] = useState<SimpleUser>();

  const { userId } = useParams<{ userId: string }>();

  const [isTeacher, setIsTeacher] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [isActive, setIsActive] = useState<boolean>();

  useEffect(() => {
    getUser(+userId);
  }, []);

  useEffect(() => {
    if (!user) return;
    setRoles(user.roles);
    setIsActive(user.isActive);
  }, [user]);

  useEffect(() => {
    setIsTeacher(roles.some(r => r.name === RoleTypes.Teacher));
    setIsStudent(roles.some(r => r.name === RoleTypes.Student));
    setIsSupervisor(roles.some(r => r.name === RoleTypes.Advisor));
  }, [roles]);

  if (!userLoaded || !user) return <Spinner />;

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let submitData: UserReferences = {
      roles: roles.map(r => r.id) as any[],
    };
    const classIds = classes.map(c => c.id);
    if (isTeacher) {
      submitData = { ...submitData, teacherClasses: classIds };
    }
    if (isStudent) {
      submitData = { ...submitData, studentReferences: { classId: classIds[0], userId: +userId, advisorUserId: supervisor?.id, teacherUserId: teacher?.id } };
    }
    if (isSupervisor) {
      submitData = { ...submitData, advisorClasses: classIds };
    }
    updateUser(+userId, submitData, isActive, () => {
      getUser(+userId);
    });
  };

  return (
    <>
      <PageHeader title={user?.displayName} />
      {user?.isActive === false && (
        <Alert type="warning">
          <FormattedMessage id="userDeactivatedAlert" />
        </Alert>
      )}
      <form onSubmit={e => handleSubmit(e)}>
        {user?.email && (
          <Label>
            <span><FormattedMessage id="email" /></span>
            <Input
              type="text"
              css=""
              disabled
              value={user.email}
            />
          </Label>
        )}
        <RoleSelect selectedRoles={roles} setSelectedRoles={setRoles} />
        <ClassSelect isStudent={isStudent} isSupervisor={isSupervisor} isTeacher={isTeacher} selectedClasses={classes} setSelectedClasses={setClasses} user={user} />
        {classes && <TeacherSelect classId={classes[0]?.id} isStudent={isStudent} selectedTeacher={teacher} setSelectedTeacher={setTeacher} user={user} />}
        {classes && <SupervisorSelect classId={classes[0]?.id} isStudent={isStudent} selectedSupervisor={supervisor} setSelectedSupervisor={setSupervisor} user={user} />}

        <Label check className="mt-4">
          <Input
            css=""
            type="checkbox"
            onChange={e => setIsActive(e.target.checked)}
            checked={isActive}
          />
          <span>
            <FormattedMessage id="activated" />
          </span>
        </Label>
        <br />
        <HelperText>
          <FormattedMessage id="userIsActiveHelpText" />
        </HelperText>
        <div className="flex flex-col pt-16">
          <Button type="submit" isSaving={isSaving} />
        </div>
      </form>
    </>
  );
};

export default User;
