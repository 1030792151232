import { TableContainer, TableHeader, TableRow, TableCell, TableBody, Table as WindmillTable } from '@windmill/react-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import { Subject } from 'src/models/classes';
import { MdEdit } from 'react-icons/md';

const SubjectsTable = ({ subjects, onClickRow }: { subjects: Array<Subject> | undefined, onClickRow: (e: Subject) => void }) => {
  const { formatMessage } = useIntl();

  return (
    <TableContainer data-testid="subjects-table">
      <WindmillTable>
        <TableHeader className="bg-lighterGray text-gray font-bold text-sm">
          <TableRow>
            <TableCell><FormattedMessage id="subject-code" /></TableCell>
            <TableCell><FormattedMessage id="name" /></TableCell>
            <TableCell />
          </TableRow>
        </TableHeader>
        <TableBody>
          {subjects?.map(subject => (
            <TableRow key={subject.id ?? Math.random()}>
              <TableCell>
                {subject.subjectCode}
              </TableCell>
              <TableCell>
                {subject.name}
              </TableCell>
              <TableCell className="pr-4">
                <button onClick={() => onClickRow(subject)} aria-label={formatMessage({ id: 'editSubject' })} data-testid="edit-subject-button">
                  <MdEdit />
                </button>
              </TableCell>
            </TableRow>
          ))
            ?? (
              <TableRow>
                <TableCell>
                  <FormattedMessage id="no-subjects" />
                </TableCell>
              </TableRow>
            )}
        </TableBody>
      </WindmillTable>
    </TableContainer>
  );
};

export default SubjectsTable;
