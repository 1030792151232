import { Form } from 'src/models/form';
import FormAnswers, { AnswerSet } from './FormAnswers';

export class FormAnswersCollection {
  private answers: Map<Number, AnswerSet>;
  private currentForm: Form;

  constructor(private readonly form: Form, fromAnswerSets?: AnswerSet[]) {
    this.answers = new Map<Number, AnswerSet>();
    this.currentForm = form;
    fromAnswerSets?.forEach(answerSet => {
      this.answers.set(answerSet.userId!, answerSet);
    });
  }

  getAnswers = (userId: Number) => {
    const usersAnswers = this.answers.get(userId);
    if (usersAnswers) return new FormAnswers(this.currentForm, usersAnswers);
    return undefined;
  };

  getOthersExcept = (userId: Number) => Array.from(this.answers).filter(([key]) => key !== userId).map(([, value]) => new FormAnswers(this.currentForm, value));
}

export default FormAnswersCollection;
