import { useQuery } from '@tanstack/react-query';
import { fetchFeideGroupMemberships, fetchFeideUserInfo } from 'src/api/authAPI';
import fetchMemberships from 'src/api/membershipsAPI';
import ApiError from 'src/api/errorHandling/ApiError';
import FeideGroupMembership from 'src/models/FeideGroupMembership';
import FeideUserInfo from 'src/models/FeideUserInfo';
import Membership from 'src/models/Membership';

interface FsDebugQuery {
  userInfo: FeideUserInfo;
  memberships: FeideGroupMembership[];
  fsRoles: Membership[];
}

export default function useFsDebugQuery() {
  return useQuery<FsDebugQuery, ApiError>({
    queryKey: ['fsDebug'],
    queryFn: async () => {
      const [userInfo, memberships, fsRoles] = await Promise.all([
        fetchFeideUserInfo(),
        fetchFeideGroupMemberships(),
        fetchMemberships(),
      ]);
      return { userInfo, memberships, fsRoles };
    },
  });
}
