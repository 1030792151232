import { FC, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useTokenProvider } from 'src/context/TokenProviderContext';
import LoggedInUserContext from 'src/hooks/useLoggedInUser';
import ClassesContext from 'src/hooks/useClasses';
import { RoleTypes } from 'src/models/roles';
import UsersContext from 'src/hooks/useUsers';
import AnswersContext from 'src/hooks/useAnswers';
import { useAuthenticator } from 'src/context/AuthenticatorContext';
import AdminRouter from '../Admin/AdminRouter/AdminRouter';
import StudentRouter from '../Student/StudentRouter';
import TeacherRouter from '../Teacher/TeacherRouter';

import NotAuthenticatedRouter from '../NotAuthenticated/NotAuthenticatedRouter';
import SupervisorRouter from '../Supervisor/SupervisorRouter';
import SelectActiveRole from './SelectActiveRole/SelectActiveRole';
import FSDebugPage from './FSDebug/FSDebugPage';
import IndependenceScalePage from './IndependenceScalePage/IndependenceScalePage';

export const MainRouter: FC = () => {
  const token = useTokenProvider().getToken();

  const { activeRole } = LoggedInUserContext.useContainer();
  const { refreshToken } = useAuthenticator();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    refreshToken();
  }, [pathname]);

  return (
    <>
      <Switch>
        {!token ? (
          <NotAuthenticatedRouter />
        ) : (
          <>
            <ClassesContext.Provider>
              <AnswersContext.Provider>
                {!activeRole ? (
                  <SelectActiveRole />
                ) : (
                  <>
                    <UsersContext.Provider>
                      <Switch>
                        <Route exact path="/debug/fs">
                          <FSDebugPage />
                        </Route>
                        <Route exact path="/independence-scale">
                          <IndependenceScalePage />
                        </Route>
                        {activeRole === RoleTypes.Student && <StudentRouter />}
                        {activeRole === RoleTypes.Teacher && <TeacherRouter />}
                        {activeRole === RoleTypes.Advisor && <SupervisorRouter />}
                        {activeRole === RoleTypes.Admin && <AdminRouter />}
                      </Switch>
                    </UsersContext.Provider>
                  </>
                )}
              </AnswersContext.Provider>
            </ClassesContext.Provider>
          </>
        )}
      </Switch>
    </>
  );
};

export default MainRouter;
