import { Dropdown, DropdownItem } from '@windmill/react-ui';
import { useState } from 'react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';

export interface FormQuestionDropdownProps {
  setNotRelevant: () => void,
  hideNotRelevant: boolean,
  questionTypeIsGrading: boolean,
}

const FormQuestionDropdown = ({ setNotRelevant, hideNotRelevant, questionTypeIsGrading }: FormQuestionDropdownProps) => {
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl();

  const toggle = () => {
    setOpen(previousValue => !previousValue);
  };

  const close = () => setOpen(false);

  if (!questionTypeIsGrading || hideNotRelevant) return null;
  return (
    <div className="relative ml-1">
      <button data-testid="form-question-ellipsis-button" onClick={toggle} aria-label={formatMessage({ id: 'showMore' })}>
        <HiDotsHorizontal
          className="text-2xl"
        />
      </button>
      <Dropdown isOpen={open} onClose={close} align="right">
        <DropdownItem onClick={() => {
          setNotRelevant();
          close();
        }}
        >
          <FormattedMessage id="not-relevant" />
        </DropdownItem>
      </Dropdown>
    </div>
  );
};

export default FormQuestionDropdown;
