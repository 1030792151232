import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router-dom';

function redirectToVipps() {
  const authorizeEndpoint = process.env.REACT_APP_VIPPS_AUTHORIZE_ENDPOINT;
  const clientId = process.env.REACT_APP_VIPPS_CLIENT_ID;
  const redirectUri = process.env.REACT_APP_VIPPS_REDIRECT_URI;
  const scope = 'email+phoneNumber+name';
  const state = uuidv4();
  localStorage.setItem('vippsLoginState', state);

  window.location.href = `${authorizeEndpoint}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`;
}

export default function VippsLoginButton() {
  const { search } = useLocation();
  const verificationGuid = new URLSearchParams(search).get('verificationGuid') ?? null;
  if (verificationGuid) localStorage.setItem('verificationGuid', verificationGuid);

  return (
    <button
      onClick={redirectToVipps}
      className="w-full flex justify-center p-4 text-white rounded-full"
      style={{ backgroundColor: '#EC6933' }}
    >
      Fortsett med Vipps
    </button>
  );
}
