import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from '@windmill/react-ui';
import { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Select from 'src/components/_common/Select/Select';
import ClassesContext from 'src/hooks/useClasses';

interface AddUsersToClassModalModalProps {
    isOpen: boolean;
    onClose: () => void,
    classId: number
}

const AddUsersToClassModal = ({ isOpen, onClose, classId }: AddUsersToClassModalModalProps) => {
  const { getUsersForClass, usersForClass, usersForClassLoaded, myStudents, myStudentsLoaded, assignStudentsToMe } = ClassesContext.useContainer();

  useEffect(() => {
    if (!usersForClassLoaded) getUsersForClass(classId);
  }, []);
  const { formatMessage } = useIntl();

  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  useEffect(() => {
    setSelectedUsers(myStudents?.map(user => ({ value: user?.id, label: user?.displayName })));
  }, [myStudents]);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedUsers.length > 0) {
      const mappedUsers = selectedUsers.map((selectedUser: any) => selectedUser.value);
      assignStudentsToMe(classId, mappedUsers);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} style={{ margin: 'auto' }}>
      <form onSubmit={handleSubmit}>
        <ModalHeader>
          {formatMessage({ id: 'assign-students' })}
        </ModalHeader>
        <ModalBody>
          <Select
            label={formatMessage({ id: 'students' })}
            onChange={setSelectedUsers}
            value={selectedUsers}
            options={usersForClass?.students.map(user => ({ value: user?.id, label: user?.displayName }))}
            isLoading={!usersForClassLoaded || !myStudentsLoaded}
            isMulti
          />
        </ModalBody>
        <ModalFooter>
          <Button className="w-full sm:w-auto" layout="outline" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button type="submit" className="w-full sm:w-auto">
            <FormattedMessage id="save" />
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddUsersToClassModal;
