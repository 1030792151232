import { Redirect, Route, Switch } from 'react-router-dom';
import FormContext from 'src/hooks/useFormContext';
import RolesContext from 'src/hooks/useRoles';
import SemestersContext from 'src/hooks/useSemesters';
import FormPage from 'src/pages/Student/StudentForms/FormPage/FormPage';
import SubjectsContext from 'src/hooks/useSubjects';
import SupervisorPage from 'src/pages/Teacher/TeacherClass/SupervisorPage/SupervisorPage';
import PrivacyPolicyPage from 'src/pages/Main/PrivacyPolicy/PrivacyPolicyPage';
import AdminFormEditor from '../pages/AdminForms/AdminFormEditor/AdminFormEditor';
import AdminForms from '../pages/AdminForms/AdminForms';
import UsersContext from '../../../hooks/useUsers';
import AdminSubjects from '../AdminSubjects/AdminSubjects';
import AdminPage from '../AdminPage/AdminPage';
import User from '../pages/AdminUsers/User/User';
import Users from '../pages/AdminUsers/Users';
import AdminSemesters from '../pages/AdminSemesters/AdminSemesters';
import AdminSemester from '../pages/AdminSemesters/AdminSemester/AdminSemester';
import AdminClass from '../pages/AdminClass/AdminClass';

const AdminRouter = () => (
  <FormContext.Provider>
    <SubjectsContext.Provider>
      <UsersContext.Provider>
        <RolesContext.Provider>
          <SemestersContext.Provider>
            <Switch>
              <Route exact path="/admin">
                <AdminPage />
              </Route>
              <Route path="/admin/forms/create">
                <AdminFormEditor />
              </Route>
              <Route path="/admin/forms/edit/:formId">
                <AdminFormEditor />
              </Route>
              <Route path="/admin/forms">
                <AdminForms />
              </Route>
              <Route exact path="/admin/users">
                <Users />
              </Route>
              <Route exact path="/admin/users/:userId">
                <User />
              </Route>
              <Route exact path="/admin/subjects">
                <AdminSubjects />
              </Route>
              <Route exact path="/admin/semesters/:semesterId">
                <AdminSemester />
              </Route>
              <Route exact path="/admin/semesters">
                <AdminSemesters />
              </Route>
              <Route exact path="/admin/class/:classId">
                <AdminClass />
              </Route>
              <Route exact path="/admin/class/:classId/advisor/:userId">
                <SupervisorPage />
              </Route>
              <Route exact path="/admin/class/:classId/form/:formId/assignment/:assignmentId">
                <FormPage />
              </Route>
              <Route path="/privacy-policy">
                <PrivacyPolicyPage />
              </Route>
              <Redirect from="*" to="/admin" />
            </Switch>
          </SemestersContext.Provider>
        </RolesContext.Provider>
      </UsersContext.Provider>
    </SubjectsContext.Provider>
  </FormContext.Provider>
);

export default AdminRouter;
