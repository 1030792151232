import axios from 'axios';
import ApiError from './ApiError';
import ApiErrorResponse from './ApiErrorResponse';

export default function defaultErrorConverter(error: unknown) : ApiError {
  if (!axios.isAxiosError(error)) {
    return {
      description: 'Unexpected error',
      statusCode: -1,
      details: {
        innerError: error,
        debugMessage: 'Thrown error is not of type AxiosError',
      },
    };
  }

  const description = parseErrorDescriptionFromResponseData(error.response);
  const debugMessage = description === undefined ? 'Unable to parse error description from response data' : undefined;

  return {
    description: description ?? 'Unexpected error',
    statusCode: error.response?.status ?? -1,
    details: {
      innerError: error,
      debugMessage,
    },
  };
}

function parseErrorDescriptionFromResponseData(response: any) {
  try {
    const apiError = response.data as ApiErrorResponse;
    const { errors } = apiError;
    const firstError = errors[0];
    return firstError.description;
  } catch (error) {
    return undefined;
  }
}
