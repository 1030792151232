import { HiArrowLeft } from 'react-icons/hi';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from 'src/components/_common/Button/Button';

interface FormButtonsProps {
  setActivePage: (newActivePage: number) => void;
  deliverAnswer: () => void;
  // exitTo: string;
  activePage: number;
  pages: Array<number> | undefined;
  canDeliver: boolean;
  isSaving: boolean;
}

const FormButtons = ({ setActivePage, deliverAnswer, activePage, pages, canDeliver, isSaving }: FormButtonsProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const onExit = () => {
    history.goBack();
  };
  return (
    <div className="flex">
      {activePage !== 1 && (
        <Button
          data-testid="form-prev-page"
          layout="outline"
          icon={HiArrowLeft}
          className="rounded-full bg-white text-primary border-lightGray border-4 p-2"
          onClick={() => setActivePage(activePage - 1)}
          ariaLabel={formatMessage({ id: 'backToPreviousPage' })}
        />
      )}
      <Button
        data-testid="form-exit-page"
        layout="outline"
        className="rounded-full bg-white text-primary border-lightGray border-4 p-2"
        onClick={() => onExit()}
      >
        <FormattedMessage id="close" />
      </Button>
      {activePage !== pages?.length ? (
        <Button
          data-testid="form-next-page"
          className="w-full bg-primary rounded-full"
          onClick={() => setActivePage(activePage + 1)}
        >
          <FormattedMessage id={(isSaving ? 'answers-saving' : 'answers-next')} />
        </Button>
      ) : (
        <Button
          data-testid="form-send"
          className=" w-full bg-primary rounded-full"
          onClick={() => deliverAnswer()}
          disabled={!canDeliver}
          isSaving={isSaving}
        />
      )}
    </div>
  );
};
export default FormButtons;
