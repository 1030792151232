import { FC, ReactElement } from 'react';
import { QuestionType } from 'src/models/form';
import { RoleTypes } from 'src/models/roles';
import FormQuestionGradingComponent from './FormQuestionGradingComponent/FormQuestionGradingComponent';
import FormQuestionTextInputComponent from './FormQuestionTextInputComponent/FormQuestionTextInputComponent';

export interface FormQuestionTypePickerProps {
  type: QuestionType,
  labels: string[];
  activeColor?: string;
  supervisorGrade?: number;
  studentGrade?: number;
  teacherMidTermGrade?: number;
  teacherGrade?: number;
  currentSemesterExpectation?: number;
  otherSemesterExpectation?: number;
  answer?: string | number | string[] | undefined;
  setAnswer: (value: any) => void,
  activeRole?: RoleTypes;
  isMidSemester?: boolean;
  canAnswer?: boolean;
}

export const FormQuestionTypePicker: FC<FormQuestionTypePickerProps> = ({
  type,
  labels,
  activeColor,
  supervisorGrade,
  studentGrade,
  teacherMidTermGrade,
  otherSemesterExpectation,
  currentSemesterExpectation,
  teacherGrade,
  setAnswer,
  answer,
  activeRole,
  isMidSemester,
  canAnswer,
}: FormQuestionTypePickerProps) => {
  let questionType: ReactElement;
  switch (type) {
    case QuestionType.Grading:
      questionType = (
        <FormQuestionGradingComponent
          labels={labels}
          activeColor={activeColor}
          supervisorGrade={supervisorGrade}
          studentGrade={studentGrade}
          teacherMidTermGrade={teacherMidTermGrade}
          otherSemesterExpectation={otherSemesterExpectation}
          currentSemesterExpectation={currentSemesterExpectation}
          teacherGrade={teacherGrade}
          answer={answer}
          setAnswer={setAnswer}
          activeRole={activeRole}
          isMidSemester={isMidSemester}
          canAnswer={canAnswer}
        />
      );
      break;
    case QuestionType.TextInput:
      questionType = <></>;
      break;
    default:
      throw new Error(`A definition for question type ${type} has not yet been made`);
  }
  return questionType;
};

export default FormQuestionTypePicker;
