import { MdAddCircle } from 'react-icons/md';
import Button from '../Button/Button';

interface ListHeaderProps {
  title?: string;
  onTitleClick?: () => void;
  titleButtonAriaLabel?: string;
}

export default function ListHeader({ title, onTitleClick, titleButtonAriaLabel }: ListHeaderProps) {
  return (
    <div className="flex justify-between pb-5">
      <h2 className="my-0 self-center">{title}</h2>
      {onTitleClick && (
        <Button
          icon={MdAddCircle}
          data-testid="table-add-button"
          className="self-center mr-3 py-0 text-primary table-add-button"
          layout="link"
          onClick={onTitleClick}
          ariaLabel={titleButtonAriaLabel ?? title}
        />
      )}
    </div>
  );
}
