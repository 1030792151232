import { useIntl } from 'react-intl';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import Spinner from 'src/components/_common/Spinner/Spinner';
import useLoggedInUser from 'src/hooks/user/useLoggedInUser';
import InvitationForm from './InvitationForm';
import SupervisorBadge from './SupervisorBadge';

export default function InviteSupervisorPage() {
  const { formatMessage } = useIntl();
  const loggedInUserQuery = useLoggedInUser();

  if (loggedInUserQuery.isLoading) return <Spinner />;
  if (loggedInUserQuery.isError) return <ApiErrorAlert error={loggedInUserQuery.error} message={formatMessage({ id: 'inviteSupervisorPageErrorMessage' })} />;

  const supervisorId = loggedInUserQuery.data.studentReferences?.advisorUserId;

  return (
    <>
      <PageHeader
        title={formatMessage({ id: 'inviteSupervisorPageTitle' })}
        subTitle={formatMessage({ id: 'inviteSupervisorPageSubTitle' })}
      />

      {supervisorId && <SupervisorBadge id={supervisorId} />}

      <InvitationForm supervisorExists={!!supervisorId} />
    </>
  );
}
