import { useEffect, useState } from 'react';
import { HttpResponse, useHttpClient } from 'src/context/HttpClientContext';
import { Period } from 'src/models/classes';

import { createContainer } from 'unstated-next';
import ToastsContext from './useToasts';

function useSemesters() {
  const { setErrorToast, setShowSuccessToast } = ToastsContext.useContainer();

  const [semesters, setSemesters] = useState<Array<Period>>();
  const [semestersLoaded, setSemestersLoaded] = useState<boolean>(false);
  const [semestersError, setSemestersError] = useState<HttpResponse<any> | undefined>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const findSemester = (semesterId: number) => semesters?.find(semester => semester.id === +semesterId);

  const { get, post } = useHttpClient();

  const handleSetSemesters = (_semesters: Array<Period>) => {
    setSemesters(_semesters);
    setSemestersLoaded(true);
  };

  const getSemesters = () => get({ url: 'api/periods' })
    .then(response => {
      handleSetSemesters(response.data as Array<Period>);
    }).catch(error => {
      setSemestersError(error);
    });

  const createNewSemester = (newSemester: Period, successCallback?: () => void) => {
    setIsSaving(true);
    post({ url: 'api/periods', data: newSemester })
      .then(() => {
        setShowSuccessToast(true);
        getSemesters();
        successCallback?.();
      }).catch(error => {
        setErrorToast(error);
      }).finally(() => setIsSaving(false));
  };

  useEffect(() => {
    getSemesters();
  }, []);

  return { handleSetSemesters, semesters, semestersLoaded, semestersError, findSemester, createNewSemester, isSaving };
}
const SemestersContext = createContainer(useSemesters);

export default SemestersContext;
