// import axios from 'axios';
import React from 'react';
import axios, { AxiosError } from 'axios';
import { useTokenProvider } from 'src/context/TokenProviderContext';
import { ContextProps } from '../GlobalServices';
import { HttpClientContext, HttpResponse, IHttpClient, RequestPayload, AuthPayload } from '../../context/HttpClientContext';

const HttpClientService: React.FC<ContextProps> = ({ children }: ContextProps) => {
  const root = process.env.REACT_APP_API_ENDPOINT;
  const tokenProvider = useTokenProvider();

  const authHeader = (userToken?: string) => userToken && { headers: { Authorization: `Bearer ${userToken}` } };

  const axiosClient: IHttpClient = {
    get: async <R, >(payload: RequestPayload) => performGet<R>(payload).catch(e => handleError(e)),
    post: async <R, >(payload: RequestPayload) => performPost<R>(payload).catch(e => handleError(e)),
    authPost: async <R, >(payload: AuthPayload) => performAuthPost<R>(payload).catch(e => handleError(e)),
    put: async <R, >(payload: RequestPayload) => performPut<R>(payload).catch(e => handleError(e)),
    delete: async <R, >(payload: RequestPayload) => performDelete<R>(payload).catch(e => handleError(e)),
    patch: async <R, >(payload: RequestPayload) => performPatch<R>(payload).catch(e => handleError(e)),
  };

  async function performGet<R>(payload: RequestPayload): Promise<HttpResponse<R>> {
    const userToken = tokenProvider.getToken();
    const tokenizedParams = Object.assign(payload.params || {});
    try {
      const response = await axios.get(`${root}/${payload.url}`, { params: tokenizedParams, ...authHeader(userToken) });
      return response.data;
    } catch (e: any) {
      if (e.response && e.response?.data?.errors?.length > 0) {
        e?.response?.data?.errors?.map((error: any) => {
          if (error.statusCode === 401 && error.description.includes('Token has expired')) {
            tokenProvider.cancelToken();
            window.location.reload();
          }
          throw e.response;
        });
      }
      throw e.response;
    }
  }

  async function performPost<R>(payload: RequestPayload): Promise<HttpResponse<R>> {
    const userToken = tokenProvider.getToken();
    const tokenizedParams = Object.assign(payload.params || {});
    const response = await axios.post(`${root}/${payload.url}`, { data: payload.data }, { params: tokenizedParams, ...authHeader(userToken) });
    return response.data;
  }
  async function performAuthPost<R>(payload: AuthPayload): Promise<HttpResponse<R>> {
    const { url, ...restPayload } = payload;
    const response = await axios.post(`${url}`, { ...restPayload });
    return response.data;
  }
  async function performPut<R>(payload: RequestPayload): Promise<HttpResponse<R>> {
    const userToken = tokenProvider.getToken();
    const tokenizedParams = Object.assign(payload.params || {});
    const response = await axios.put(`${root}/${payload.url}`, { data: { ...payload.data } }, { params: tokenizedParams, ...authHeader(userToken) });
    return response.data;
  }
  async function performDelete<R>(payload: RequestPayload): Promise<HttpResponse<R>> {
    const userToken = tokenProvider.getToken();
    const tokenizedParams = Object.assign(payload.params || {});
    const response = await axios.delete(`${root}/${payload.url}`, { params: tokenizedParams, ...authHeader(userToken) });
    return response.data;
  }

  async function performPatch<R>(payload: RequestPayload): Promise<HttpResponse<R>> {
    const userToken = tokenProvider.getToken();
    const tokenizedParams = Object.assign(payload.params || {});
    const response = await axios.patch(`${root}/${payload.url}`, { data: payload.data }, { params: tokenizedParams, ...authHeader(userToken) });
    return response.data;
  }

  const handleError = (error: any): HttpResponse <{error: AxiosError}> => {
    const status = error?.status || error?.response?.status;
    const internalError = status === 500;
    const httpResponse: HttpResponse<{error: AxiosError}> = {
      statusCode: status,
      errors: internalError ? [{ description: 'Noe gikk galt, vennligst prøv på nytt.\nDersom feilen vedvarer, kontakt en administrator.' }] : error?.data?.errors || error?.response?.data?.errors,
      data: error?.data || error?.response?.data,
    };
    throw httpResponse;
  };

  return (
    <HttpClientContext.Provider value={axiosClient}>
      {children}
    </HttpClientContext.Provider>
  );
};

export default HttpClientService;
