export default function validateVippsResponse(queryString: string): { error: boolean; errorDetails?: string } {
  const params = new URLSearchParams(queryString);

  const { code,
    state,
    error,
    error_description: errorDescription } = Object.fromEntries(params);

  if (!code) {
    return {
      error: true,
      errorDetails: 'No code in response',
    };
  }

  if (!state) {
    return {
      error: true,
      errorDetails: 'No state in response',
    };
  }

  if (error) {
    return {
      error: true,
      errorDetails: `Error: ${error}. Description: ${errorDescription}`,
    };
  }

  if (state !== localStorage.getItem('vippsLoginState')) {
    return {
      error: true,
      errorDetails: 'State used to initiate request does not match state from response',
    };
  }

  return {
    error: false,
    errorDetails: undefined,
  };
}
