import { useHistory } from 'react-router-dom';
import Card from 'src/components/_common/Card/Card';
import { FormattedMessage, useIntl } from 'react-intl';
import PageHeader from 'src/components/_common/PageHeader/PageHeader';
import List from 'src/components/_common/List/List';
import ListItem from 'src/components/_common/List/ListItem';

const AdminPage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();

  const navigateTo = (url: string) => history.push(url);

  return (
    <>
      <PageHeader title="Adminpanel" />
      <Card header={formatMessage({ id: 'semesters' })} onClick={() => history.push('/admin/semesters')} />
      <List title="Oppsett">
        <ListItem onClick={() => navigateTo('/admin/forms')}>
          <FormattedMessage id="respons" />
        </ListItem>
        <ListItem onClick={() => navigateTo('/admin/subjects')}>
          <FormattedMessage id="subjects" />
        </ListItem>
        <ListItem onClick={() => navigateTo('/admin/users')}>
          <FormattedMessage id="users" />
        </ListItem>
      </List>
    </>
  );
};

export default AdminPage;
