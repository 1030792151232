import { Modal, ModalHeader, ModalBody, ModalFooter } from '@windmill/react-ui';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'src/components/_common/Button/Button';
import LabeledInput from 'src/components/_common/LabeledInput/LabeledInput';
import { AdvisorDetails, Class } from 'src/models/classes';

interface SupervisorModalProps {
    currentClass: Class
    supervisorModalOpen: boolean
    isSaving: boolean
    onClose: () => void
    addSupervisor: (classId: number, advisorDetails: AdvisorDetails) => void
}

const SupervisorModal = ({ currentClass, supervisorModalOpen, onClose, addSupervisor, isSaving }: SupervisorModalProps) => {
  const [supervisorName, setSupervisorName] = useState('');
  const [supervisorEmail, setSupervisorEmail] = useState('');

  const { formatMessage } = useIntl();

  return (
    <Modal isOpen={supervisorModalOpen} onClose={onClose} style={{ margin: 'auto' }}>
      <form
        noValidate
        onSubmit={async e => {
          const isValid = e.currentTarget.checkValidity();
          e.preventDefault();
          if (isValid && currentClass.id && supervisorEmail) {
            addSupervisor(currentClass.id, { name: supervisorName, email: supervisorEmail });
          }
        }}
      >
        <ModalHeader>
          <FormattedMessage
            id="select-supervisor"
            values={{ subjectCode: currentClass?.subject?.subjectCode }}
          />
        </ModalHeader>
        <ModalBody>
          <LabeledInput
            labelText={formatMessage({ id: 'name' })}
            className="block my-4"
            onValueChangeHandler={e => setSupervisorName(e)}
            required
          />
          <LabeledInput
            labelText={formatMessage({ id: 'email' })}
            required
            onValueChangeHandler={e => {
              setSupervisorEmail(e);
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button className="w-full sm:w-auto" layout="outline" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </Button>
          <Button type="submit" className="w-full sm:w-auto" isSaving={isSaving} />
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SupervisorModal;
