import { Modal, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import { FormattedMessage, useIntl } from 'react-intl';
import StudentWarningModalContent from 'src/components/StudentWarningModalContent/StudentWarningModalContent';
import Button from 'src/components/_common/Button/Button';
import { Class } from 'src/models/classes';
import StudentPassWarning from 'src/models/StudentPassWarning';
import { User } from 'src/models/user';
import { convertStringToMultilineParagraphs } from 'src/utils/stringUtils';

interface ShowModalProps {
  isOpen: boolean;
  close: () => void;
  class: Class;
  student: User;
  warning: StudentPassWarning;
  supervisor?: User;
  teacher?: User;
}

export default function ShowModal({ isOpen, close, class: _class, student, warning, supervisor, teacher }: ShowModalProps) {
  const { formatMessage } = useIntl();

  return (
    <Modal isOpen={isOpen} onClose={close} style={{ margin: 'auto' }}>
      <ModalHeader data-testid="modal-header">
        <FormattedMessage id="student-about-to-fail-warning" />
      </ModalHeader>
      <ModalBody>
        <StudentWarningModalContent
          student={student}
          class={_class}
          supervisor={supervisor}
          teacher={teacher}
          commentSlot={(
            <div className="bg-lightGray p-4 rounded-md">
              <p>
                <strong>
                  <FormattedMessage id="studentWarningModalCommentLabel" />
                </strong>
              </p>
              <p>{convertStringToMultilineParagraphs(warning?.comment ?? '')}</p>
            </div>
          )}
          passRequirementSlot={(
            <div className="bg-lightGray p-4 rounded-md">
              <p>
                <strong>
                  <FormattedMessage id="studentWarningModalCommentLabel" />
                </strong>
              </p>
              <p>{convertStringToMultilineParagraphs(warning?.passRequirement ?? '')}</p>
            </div>
          )}
          passRequirementSummarySlot={(
            <div className="bg-lightGray p-4 rounded-md">
              <p>
                <strong>
                  <FormattedMessage id="studentWarningModalCommentLabel" />
                </strong>
              </p>
              <p>{convertStringToMultilineParagraphs(warning?.passRequirementSummary ?? '')}</p>
            </div>
          )}
          warning={warning}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={close}
          text={formatMessage({ id: 'close' })}
          className="text-white"
        />
      </ModalFooter>
    </Modal>
  );
}
