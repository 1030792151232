import { useIntl } from 'react-intl';
import ApiError from 'src/api/errorHandling/ApiError';
import ResponsAlert from '../Alert/ResponsAlert';

interface ApiErrorAlertProps {
  title?: string;
  message: string;
  error: ApiError;
}

export default function ApiErrorAlert({ title, message, error }: ApiErrorAlertProps) {
  const { formatMessage } = useIntl();

  return (
    <div data-testid="error-container">
      <ResponsAlert
        type="danger"
        title={title ?? formatMessage({ id: 'apiErrorDefaultTitle' })}
        message={(
          <div>
            <p className="mb-2">{message}</p>
            {error.description && (
              <p>{formatMessage({ id: 'apiErrorDetailsLabel' })}: {error.description}</p>
            )}
          </div>
        )}
      />
    </div>
  );
}
