import React, { useState } from 'react';
import { useSessionStorage } from 'src/context/SessionStorageContext';
import { ITokenProvider, TokenProviderContext } from 'src/context/TokenProviderContext';
import { ContextProps } from '../GlobalServices';

const TokenProviderService: React.FC<ContextProps> = ({ children }: ContextProps) => {
  const tokenStorageKey = 'token';
  const sessionStorage = useSessionStorage();
  const [token, setToken] = useState<string | undefined>(sessionStorage.retrieve(tokenStorageKey));

  const getToken = () => token;
  const setTokenImplementation = (newToken: string | undefined) => {
    sessionStorage.store(tokenStorageKey, newToken);
    setToken(newToken);
  };
  const cancelToken = () => {
    sessionStorage.clear();
    setToken(undefined);
  };

  const tokenProvider: ITokenProvider = {
    getToken,
    setToken: setTokenImplementation,
    cancelToken,
  };

  return (
    <TokenProviderContext.Provider value={tokenProvider}>
      {children}
    </TokenProviderContext.Provider>
  );
};

export default TokenProviderService;
