import { Input } from '@windmill/react-ui';
import DateOnly from 'src/models/common/DateOnly';

interface DateOnlyInputProps {
  disabled?: boolean;
  required?: boolean;
  value?: DateOnly | null;
  setValue: (value: DateOnly) => void;
}

export default function DateOnlyInput({ disabled, required, value, setValue }: DateOnlyInputProps) {
  return (
    <Input
      css=""
      type="date"
      disabled={disabled}
      required={required}
      value={value ? value.toShortISOString() : ''}
      onChange={e => setValue(new DateOnly(e.target.value))}
    />
  );
}
