import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLanguage } from 'src/hooks/useLanguage';

/**
 * Set the title of the document which will be shown in the browser.
 * Will use translated name for the title.
 */
const DocumentTitle = () => {
  const { formatMessage } = useIntl();
  const { lang } = useLanguage();

  const [title, setTitle] = useState('Respons');

  useEffect(() => {
    setTitle(formatMessage({ id: 'app-name' }));
  }, [lang]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
};

export default DocumentTitle;
