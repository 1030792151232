import { Badge } from '@windmill/react-ui';
import { RoleTypes } from 'src/models/roles';

interface FormBadgesProps {
supervisorGrade?: number
studentGrade?: number
teacherGrade?: number
currentValue?: number
answer?: string | number | string[] | undefined
activeRole?: RoleTypes
}

const FormBadges = ({ supervisorGrade, studentGrade, teacherGrade, currentValue, answer, activeRole }: FormBadgesProps) => (
  (supervisorGrade === currentValue || teacherGrade === currentValue || studentGrade === currentValue || answer === currentValue
    ? (
      <div className="flex justify-center flex-wrap text-black ">
        {(supervisorGrade === currentValue || (answer === currentValue && activeRole === RoleTypes.Advisor))
    && (
      <Badge type="neutral" className="bg-supervisor mt-2 text-supervisorText text-xs font-bold">
        PV
      </Badge>
    )}
        {(teacherGrade === currentValue || (answer === currentValue && activeRole === RoleTypes.Teacher))
      && (
        <Badge type="neutral" className="bg-teacher mt-2 text-teacherText text-xs font-bold">
          PL
        </Badge>
      )}
        {(studentGrade === currentValue || (answer === currentValue && activeRole === RoleTypes.Student))
    && (
    <Badge type="neutral" className="bg-student mt-2 text-studentText text-xs font-bold">
      S
    </Badge>
    )}
      </div>
    )
    : <></>
  ));

export default FormBadges;
