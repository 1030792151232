import Contextualizer from 'src/context/Contextualizer/Contextualizer';
import ProvidedServices from 'src/context/Contextualizer/ProvidedServices';
import { LoginDetails } from 'src/models/user';

export interface HttpResponse<T> {
  statusCode?: number;
  data?: T;
  errors?: HttpResponseError[]
}

export interface HttpResponseError {
  description: string;
  reason: string;
}

export interface UserToken {
  token: string;
}

export interface RequestPayload {
  url: string |URL;
  params?: any;
  data?: any;
}

export interface ExternalLoginPayload extends LoginDetails{
  url: string |URL;
}
export interface AuthPayload {
  email?: string;
  phoneNumber?: number;
  password?: string;
  code?: string;
  redirectToLocal?: boolean
  url: string |URL;
}

export interface IHttpClient {
  post: <R>(payload: RequestPayload) => Promise<HttpResponse<R | any>>;
  authPost: <R>(payload: AuthPayload) => Promise<HttpResponse<R | any>>;
  get: <R>(payload: RequestPayload) => Promise<HttpResponse<R | any>>;
  put: <R>(payload: RequestPayload) => Promise<HttpResponse<R | any>>;
  delete: <R>(payload: RequestPayload) => Promise<HttpResponse<R | any>>;
  patch: <R>(payload: RequestPayload) => Promise<HttpResponse<R | any>>;
}

export const HttpClientContext = Contextualizer.createContext<IHttpClient>(ProvidedServices.HttpClient);
export const useHttpClient = () => Contextualizer.use<IHttpClient>(ProvidedServices.HttpClient);
