import Badge from 'src/components/_common/Badge/Badge';
import useUser from 'src/hooks/user/useUser';

interface UserBadgeProps {
  id: number,
  type?: 'supervisor' | 'teacher' | 'neutral' | 'student';
}

export default function UserBadge({ id, type }: UserBadgeProps) {
  const userQuery = useUser(id);

  if (userQuery.isLoading) return null;
  if (userQuery.isError) return null;

  const user = userQuery.data;

  return (
    <Badge type={type}>
      {user.displayName}
    </Badge>
  );
}
