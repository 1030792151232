import { Modal, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import { FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import StudentWarningModalContent from 'src/components/StudentWarningModalContent/StudentWarningModalContent';
import ApiErrorAlert from 'src/components/_common/ApiErrorAlert/ApiErrorAlert';
import { Class } from 'src/models/classes';
import { User } from 'src/models/user';
import { clearTimeout, setTimeout } from 'timers';
import Button from '../../_common/Button/Button';
import LabeledTextArea from '../../_common/LabeledTextArea/LabeledTextArea';
import { useCreateStudentPassWarning, useSubmitStudentPassWarning } from './queries';

interface CreateModalProps {
  isOpen: boolean;
  close: () => void;
  class: Class;
  student: User;
  teacher?: User;
  supervisor?: User;
  comment: string;
  passRequirement: string;
  passRequirementSummary: string;
}

const COMPONENT_ID = 'send-student-warning-modal';

export default function CreateModal({ isOpen, close, class: _class, student, teacher, supervisor, comment, passRequirement, passRequirementSummary }: CreateModalProps) {
  const [stateComment, setStateComment] = useState(comment);
  const [statePassRequirement, setStatePassRequirement] = useState(passRequirement);
  const [statePassRequirementSummary, setStatePassRequirementSummary] = useState(passRequirementSummary);

  const { formatMessage } = useIntl();
  const saveMutation = useCreateStudentPassWarning(() => { });
  const submitMutation = useSubmitStudentPassWarning(() => { onSuccess(); });

  const [saveRequested, setSaveRequested] = useState(false);
  const [promiseSave, setPromiseSave] = useState(false);

  useEffect(() => {
    if (!saveRequested || promiseSave) return undefined;
    const timer = setTimeout(() => {
      setPromiseSave(true);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [saveRequested]);

  useEffect(() => {
    if (!promiseSave) return;
    save();
    setPromiseSave(false);
    setSaveRequested(false);
  }, [promiseSave]);

  useEffect(() => {
    function onParentClickHandler(e: MouseEvent) {
      const element = e.target as Element;
      const clickedOnParent = element?.children[0]?.id === COMPONENT_ID;
      if (clickedOnParent) {
        e.stopPropagation();
      }
    }

    document.getElementById(COMPONENT_ID)?.parentElement?.addEventListener('click', onParentClickHandler);

    return () => document.getElementById(COMPONENT_ID)?.parentElement?.removeEventListener('click', onParentClickHandler);
  }, [isOpen]);

  function onSuccess() {
    close();
  }

  function save() {
    saveMutation.mutate({
      classId: _class.id,
      studentUserId: student.id,
      comment: stateComment,
      passRequirement: statePassRequirement,
      passRequirementSummary: statePassRequirementSummary,
    });
    if (!saveMutation.isSuccess) setSaveRequested(true);
  }

  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!e.currentTarget.checkValidity()) return;

    save();
    submitMutation.mutate({
      classId: _class.id,
      studentUserId: student.id,
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      id={COMPONENT_ID}
      style={{ margin: 'auto' }}
    >
      <ModalHeader className="mb-4" data-testid="modal-header">
        <FormattedMessage id="student-about-to-fail-warning" />
      </ModalHeader>
      <form onSubmit={onSubmit} noValidate>
        <ModalBody>
          <StudentWarningModalContent
            student={student}
            class={_class}
            supervisor={supervisor}
            teacher={teacher}
            commentSlot={(
              <LabeledTextArea
                required
                defaultValue={stateComment}
                onValueChangeHandler={e => { setStateComment(e); setSaveRequested(true); }}
                maxLength={10000}
                rows={5}
                labelText=""
                data-testid="comment"
              />
            )}
            passRequirementSlot={(
              <LabeledTextArea
                required
                defaultValue={statePassRequirement}
                onValueChangeHandler={e => { setStatePassRequirement(e); setSaveRequested(true); }}
                maxLength={10000}
                rows={5}
                labelText=""
                data-testid="pass-requirement"
              />
            )}
            passRequirementSummarySlot={(
              <LabeledTextArea
                required
                defaultValue={statePassRequirementSummary}
                onValueChangeHandler={e => { setStatePassRequirementSummary(e); setSaveRequested(true); }}
                maxLength={10000}
                rows={5}
                labelText=""
                data-testid="pass-requirement-summary"
              />
            )}
          />
          {saveMutation.isError && <ApiErrorAlert error={saveMutation.error} message={formatMessage({ id: 'sendStudentWarningModalCreateWarningErrorMessage' })} />}
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={!stateComment?.length || !statePassRequirement?.length || !statePassRequirementSummary?.length}
            text={formatMessage({ id: 'create' })}
            isSaving={saveMutation.isLoading || submitMutation.isLoading}
            type="submit"
            data-testid="submit-button"
          />
        </ModalFooter>
      </form>
    </Modal>
  );
}
