import { useIntl } from 'react-intl';
import SigningForm from 'src/models/SigningForm';
import { UseMutationResult } from '@tanstack/react-query';
import { User } from 'src/models/user';
import RadioButtonRow from './RadioButtonRow';
import LabeledTextArea from '../_common/LabeledTextArea/LabeledTextArea';

interface FormFieldsProps {
  form: SigningForm;
  setForm: (form: SigningForm) => void;
  disabled: boolean;
  onChange?: () => void;
}

export default function EndSemesterAdditionalFormFields({ form, setForm, disabled, onChange }: FormFieldsProps) {
  const { formatMessage } = useIntl();

  function handleChange(fieldName: string, value: boolean | Date | string) {
    setForm({ ...form, [fieldName]: value });
    if (onChange) onChange();
  }

  return (
    <>
      <RadioButtonRow
        checked={form.workRequirementsPassed}
        label={formatMessage({ id: 'signing-form-approved-work-requirements' })}
        onChange={checked => handleChange('workRequirementsPassed', checked)}
        disabled={disabled}
        checkedOptionLabel={formatMessage({ id: 'yes' })}
        uncheckedOptionLabel={formatMessage({ id: 'noText' })}
      />
      <hr className="mt-4 mb-4" />
      <RadioButtonRow
        checked={form.attendancePassed}
        label={formatMessage({ id: 'signing-form-approved-attendance' })}
        onChange={checked => handleChange('attendancePassed', checked)}
        disabled={disabled}
        checkedOptionLabel={formatMessage({ id: 'yes' })}
        uncheckedOptionLabel={formatMessage({ id: 'noText' })}
      />
      <hr className="mt-4 mb-4" />
      <RadioButtonRow
        checked={form.overallPassed}
        label={formatMessage({ id: 'signing-form-period-graded' })}
        onChange={checked => handleChange('overallPassed', checked)}
        disabled={disabled}
        checkedOptionLabel={formatMessage({ id: 'signing-form-passed' })}
        uncheckedOptionLabel={formatMessage({ id: 'signing-form-failed' })}
      />
      <hr className="mt-4 mb-4" />
      <LabeledTextArea
        value={form.teacherComment || ''}
        onValueChangeHandler={value => handleChange('teacherComment', value)}
        className="w-full"
        labelText={formatMessage({ id: 'comment' })}
        disabled={disabled}
      />
    </>
  );
}
