import SupervisorInvitation from 'src/models/supervisorInvitation';
import api from './api';
import defaultErrorConverter from './errorHandling/defaultErrorConverter';

export async function sendSupervisorInvitation(email: string) {
  try {
    const requestData = {
      data: {
        email,
      },
    };
    await api.post('api/invitations/advisor', requestData);
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}

export async function getNewestSupervisorInvitationForLoggedInStudent() {
  try {
    const res = await api.get('api/invitations/advisor/me');
    const data = res.data.data as SupervisorInvitation;
    data.expiresAtUtc = new Date(data.expiresAtUtc);
    return data;
  } catch (error) {
    const apiError = defaultErrorConverter(error);
    if (apiError.statusCode === 404) {
      return null;
    }
    throw apiError;
  }
}

export async function acceptSupervisorInvitation(verificationGuid: string, name?: string, email?: string, phoneNumber?: string) {
  try {
    const requestData = {
      data: {
        verificationGuid,
        name,
        email,
        phoneNumber,
      },
    };
    await api.post('api/invitations/advisor/accept', requestData);
  } catch (error) {
    throw defaultErrorConverter(error);
  }
}
