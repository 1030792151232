import React from 'react';
import { HiOutlineArrowLeft } from 'react-icons/hi';

interface CardWithTitleProps {
  title: string;
  children?: React.ReactNode | React.ReactNode[];
  onBack?: () => void;
}

export default function CardWithTitle({ children, title, onBack }: CardWithTitleProps) {
  return (
    <div>
      <div className="border-lightGray border rounded-full bg-white w-32 md:w-64 m-auto relative top-6 md:top-7" style={{ zIndex: 10 }}>
        <h2 className="text-center m-2 font-normal">{title}</h2>
      </div>
      <div className={`border-lightGray border rounded bg-white relative max-w-xl m-auto p-8 ${onBack ? 'pt-20' : 'pt-16'}`}>
        {onBack && (
          <div className="absolute p-8 top-0 left-0">
            <button onClick={onBack}>
              <HiOutlineArrowLeft className="text-3xl" />
            </button>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
