import { Modal, ModalBody, ModalFooter, ModalHeader } from '@windmill/react-ui';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from 'src/components/_common/Button/Button';
import { Subject } from 'src/models/classes';

interface InviteSupervisorModalProps {
  isOpen: boolean;
  close: () => void;
  subject: Subject;
  navigateToAssignmentForm: () => void;
}

export default function InviteSupervisorModal({ isOpen, close, subject, navigateToAssignmentForm }: InviteSupervisorModalProps) {
  const { formatMessage } = useIntl();
  const history = useHistory();

  function navigateToEditSupervisor() {
    history.push('/student/supervisor/invite');
  }

  return (
    <Modal isOpen={isOpen} onClose={close} style={{ margin: 'auto' }}>
      <ModalHeader>
        {formatMessage({ id: 'inviteSupervisorModalTitle' }, { subjectCode: subject.subjectCode })}
      </ModalHeader>
      <ModalBody>
        <p>
          {formatMessage({ id: 'inviteSupervisorModalBody' })}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button layout="outline" onClick={navigateToEditSupervisor}>{formatMessage({ id: 'inviteSupervisorModalToInviteButton' })}</Button>
        <Button onClick={navigateToAssignmentForm}>{formatMessage({ id: 'inviteSupervisorModalToFormButton' })}</Button>
      </ModalFooter>
    </Modal>
  );
}
