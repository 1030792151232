import axios, { AxiosTransformer } from 'axios';

const defaultTransformers = (): AxiosTransformer[] => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) {
    return [];
  } if (transformRequest instanceof Array) {
    return transformRequest;
  }
  return [transformRequest];
};

export default defaultTransformers;
