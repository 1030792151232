import { MdClear } from 'react-icons/md';
import { HiMenuAlt1 } from 'react-icons/hi';
import { useIntl } from 'react-intl';
import { useState } from 'react';
import Sidebar from './Sidebar';

export default function PageSidebarWithToggle() {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const ariaLabel = isOpen ? formatMessage({ id: 'closeSideMenu' }) : formatMessage({ id: 'openSideMenu' });

  function toggle() {
    setIsOpen(prev => !prev);
  }

  return (
    <div className="w-full pt-4 text-left">
      <button onClick={toggle} aria-label={ariaLabel} data-testid="sidebar-toggle-button">
        {isOpen ? (
          <MdClear size="2em" />
        ) : (
          <HiMenuAlt1 size="2em" />
        )}
      </button>
      {isOpen && <Sidebar setIsOpen={setIsOpen} />}
    </div>
  );
}
